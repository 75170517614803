/* [Object] Modal
 * =============================== */
.modal {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: left;
    background: rgba(51, 100, 126, 0.75);
    transition: opacity 0.25s ease;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
}

.modal-state {
    display: none;
}

.modal.open {
    opacity: 1;
    visibility: visible;
}

.modal-state:checked+.modal .modal_inner {
    top: 0;
}

.modal_inner {
    transition: top 0.25s ease;
    top: 45px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    /* max-width: 660px; */
    max-width: 400px;
    margin: auto;
    position: relative;
}

.modal_inner.Three_secure_element {
    top:20px !important;
}

.popupRoomDetail {
    max-width: 90%;
}

.modal_content {
    width: 100%;
    background: #fff;
    overflow: auto;
    position: relative;
    border-bottom: 6px solid var(--btn-background);
}

.modal_close:after,
.modal_close:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 11px;
    background: var(--btn-background);
    display: block;
    transform: rotate(45deg);
    left: 50%;
    margin: -3px 0 0 -1px;
    top: 6px;
}

.modal_close {
    position: absolute;
    right: -18px;
    top: -21px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 2px solid var(--btn-background);
    border-radius: 50%;
}

.modal_close:hover {
    border-color: #ffffff;
}

.modal_close:hover:after,
.modal_close:hover:before {
    background: #ffffff !important;
}

.modal_close:before {
    transform: rotate(-45deg);
}