.textError {
  font-size: 12px;
  color: #ff0000 !important;
  margin: 5px 0;
}

.textError {
  font-size: 12px;
  color: #ff0000 !important;
  margin: 5px 0;
}

.textHint {
  font-size: 12px;
  margin: 5px 0;
}

.loaderL {
  background-image: url(/static/media/loading.d02c8f0b.svg) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  text-indent: 999%;
  overflow: hidden;
}

.loaderL:hover {
  cursor: default;
  background-color: var(--btn-background) !important;
}

.loaderLOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5) !important;
  background-size: 60px;
  cursor: default;
}
.loaderLOverlay:hover {
  cursor: default;
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #09f;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }
  
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.pdf-content {
    /* overflow: hidden; */
    /* Ajoutez d'autres styles selon vos besoins */
    font-size: 12px;
}
@media (min-width:1200px) {

    @charset "UTF-8";

    .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .animated.infinite {
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    @-webkit-keyframes bounce {

        from,
        20%,
        53%,
        80%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        40%,
        43% {
            -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
        }

        70% {
            -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            -webkit-transform: translate3d(0, -15px, 0);
            transform: translate3d(0, -15px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
        }
    }

    @keyframes bounce {

        from,
        20%,
        53%,
        80%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        40%,
        43% {
            -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
        }

        70% {
            -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            -webkit-transform: translate3d(0, -15px, 0);
            transform: translate3d(0, -15px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
        }
    }

    .bounce {
        -webkit-animation-name: bounce;
        animation-name: bounce;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
    }

    @-webkit-keyframes flash {

        from,
        50%,
        to {
            opacity: 1;
        }

        25%,
        75% {
            opacity: 0;
        }
    }

    @keyframes flash {

        from,
        50%,
        to {
            opacity: 1;
        }

        25%,
        75% {
            opacity: 0;
        }
    }

    .flash {
        -webkit-animation-name: flash;
        animation-name: flash;
    }

    @-webkit-keyframes pulse {
        from {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }

        50% {
            -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
        }

        to {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    @keyframes pulse {
        from {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }

        50% {
            -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
        }

        to {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    .pulse {
        -webkit-animation-name: pulse;
        animation-name: pulse;
    }

    @-webkit-keyframes rubberBand {
        from {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }

        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }

        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }

        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }

        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }

        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }

        to {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    @keyframes rubberBand {
        from {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }

        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }

        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }

        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }

        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }

        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }

        to {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    .rubberBand {
        -webkit-animation-name: rubberBand;
        animation-name: rubberBand;
    }

    @-webkit-keyframes shake {

        from,
        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        10%,
        30%,
        50%,
        70%,
        90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        20%,
        40%,
        60%,
        80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    }

    @keyframes shake {

        from,
        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        10%,
        30%,
        50%,
        70%,
        90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        20%,
        40%,
        60%,
        80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    }

    .shake {
        -webkit-animation-name: shake;
        animation-name: shake;
    }

    @-webkit-keyframes headShake {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        6.5% {
            -webkit-transform: translateX(-6px) rotateY(-9deg);
            transform: translateX(-6px) rotateY(-9deg);
        }

        18.5% {
            -webkit-transform: translateX(5px) rotateY(7deg);
            transform: translateX(5px) rotateY(7deg);
        }

        31.5% {
            -webkit-transform: translateX(-3px) rotateY(-5deg);
            transform: translateX(-3px) rotateY(-5deg);
        }

        43.5% {
            -webkit-transform: translateX(2px) rotateY(3deg);
            transform: translateX(2px) rotateY(3deg);
        }

        50% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    @keyframes headShake {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        6.5% {
            -webkit-transform: translateX(-6px) rotateY(-9deg);
            transform: translateX(-6px) rotateY(-9deg);
        }

        18.5% {
            -webkit-transform: translateX(5px) rotateY(7deg);
            transform: translateX(5px) rotateY(7deg);
        }

        31.5% {
            -webkit-transform: translateX(-3px) rotateY(-5deg);
            transform: translateX(-3px) rotateY(-5deg);
        }

        43.5% {
            -webkit-transform: translateX(2px) rotateY(3deg);
            transform: translateX(2px) rotateY(3deg);
        }

        50% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    .headShake {
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-name: headShake;
        animation-name: headShake;
    }

    @-webkit-keyframes swing {
        20% {
            -webkit-transform: rotate3d(0, 0, 1, 15deg);
            transform: rotate3d(0, 0, 1, 15deg);
        }

        40% {
            -webkit-transform: rotate3d(0, 0, 1, -10deg);
            transform: rotate3d(0, 0, 1, -10deg);
        }

        60% {
            -webkit-transform: rotate3d(0, 0, 1, 5deg);
            transform: rotate3d(0, 0, 1, 5deg);
        }

        80% {
            -webkit-transform: rotate3d(0, 0, 1, -5deg);
            transform: rotate3d(0, 0, 1, -5deg);
        }

        to {
            -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg);
        }
    }

    @keyframes swing {
        20% {
            -webkit-transform: rotate3d(0, 0, 1, 15deg);
            transform: rotate3d(0, 0, 1, 15deg);
        }

        40% {
            -webkit-transform: rotate3d(0, 0, 1, -10deg);
            transform: rotate3d(0, 0, 1, -10deg);
        }

        60% {
            -webkit-transform: rotate3d(0, 0, 1, 5deg);
            transform: rotate3d(0, 0, 1, 5deg);
        }

        80% {
            -webkit-transform: rotate3d(0, 0, 1, -5deg);
            transform: rotate3d(0, 0, 1, -5deg);
        }

        to {
            -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg);
        }
    }

    .swing {
        -webkit-transform-origin: top center;
        transform-origin: top center;
        -webkit-animation-name: swing;
        animation-name: swing;
    }

    @-webkit-keyframes tada {
        from {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }

        10%,
        20% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        }

        30%,
        50%,
        70%,
        90% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        }

        40%,
        60%,
        80% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        }

        to {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    @keyframes tada {
        from {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }

        10%,
        20% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        }

        30%,
        50%,
        70%,
        90% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        }

        40%,
        60%,
        80% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        }

        to {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    .tada {
        -webkit-animation-name: tada;
        animation-name: tada;
    }

    @-webkit-keyframes wobble {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        15% {
            -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        }

        30% {
            -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        }

        45% {
            -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        }

        60% {
            -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        }

        75% {
            -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes wobble {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        15% {
            -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        }

        30% {
            -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        }

        45% {
            -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        }

        60% {
            -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        }

        75% {
            -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .wobble {
        -webkit-animation-name: wobble;
        animation-name: wobble;
    }

    @-webkit-keyframes jello {

        from,
        11.1%,
        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        22.2% {
            -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
            transform: skewX(-12.5deg) skewY(-12.5deg);
        }

        33.3% {
            -webkit-transform: skewX(6.25deg) skewY(6.25deg);
            transform: skewX(6.25deg) skewY(6.25deg);
        }

        44.4% {
            -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
            transform: skewX(-3.125deg) skewY(-3.125deg);
        }

        55.5% {
            -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
            transform: skewX(1.5625deg) skewY(1.5625deg);
        }

        66.6% {
            -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
            transform: skewX(-0.78125deg) skewY(-0.78125deg);
        }

        77.7% {
            -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
            transform: skewX(0.390625deg) skewY(0.390625deg);
        }

        88.8% {
            -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
            transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        }
    }

    @keyframes jello {

        from,
        11.1%,
        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        22.2% {
            -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
            transform: skewX(-12.5deg) skewY(-12.5deg);
        }

        33.3% {
            -webkit-transform: skewX(6.25deg) skewY(6.25deg);
            transform: skewX(6.25deg) skewY(6.25deg);
        }

        44.4% {
            -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
            transform: skewX(-3.125deg) skewY(-3.125deg);
        }

        55.5% {
            -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
            transform: skewX(1.5625deg) skewY(1.5625deg);
        }

        66.6% {
            -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
            transform: skewX(-0.78125deg) skewY(-0.78125deg);
        }

        77.7% {
            -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
            transform: skewX(0.390625deg) skewY(0.390625deg);
        }

        88.8% {
            -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
            transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        }
    }

    .jello {
        -webkit-animation-name: jello;
        animation-name: jello;
        -webkit-transform-origin: center;
        transform-origin: center;
    }

    @-webkit-keyframes bounceIn {

        from,
        20%,
        40%,
        60%,
        80%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        20% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
        }

        40% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
        }

        80% {
            -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
        }

        to {
            opacity: 1;
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    @keyframes bounceIn {

        from,
        20%,
        40%,
        60%,
        80%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        20% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
        }

        40% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
        }

        80% {
            -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
        }

        to {
            opacity: 1;
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }

    .bounceIn {
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-name: bounceIn;
        animation-name: bounceIn;
    }

    @-webkit-keyframes bounceInDown {

        from,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes bounceInDown {

        from,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .bounceInDown {
        -webkit-animation-name: bounceInDown;
        animation-name: bounceInDown;
    }

    @-webkit-keyframes bounceInLeft {

        from,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes bounceInLeft {

        from,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .bounceInLeft {
        -webkit-animation-name: bounceInLeft;
        animation-name: bounceInLeft;
    }

    @-webkit-keyframes bounceInRight {

        from,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        from {
            opacity: 0;
            -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes bounceInRight {

        from,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        from {
            opacity: 0;
            -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .bounceInRight {
        -webkit-animation-name: bounceInRight;
        animation-name: bounceInRight;
    }

    @-webkit-keyframes bounceInUp {

        from,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes bounceInUp {

        from,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .bounceInUp {
        -webkit-animation-name: bounceInUp;
        animation-name: bounceInUp;
    }

    @-webkit-keyframes bounceOut {
        20% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
        }

        50%,
        55% {
            opacity: 1;
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
        }

        to {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }
    }

    @keyframes bounceOut {
        20% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
        }

        50%,
        55% {
            opacity: 1;
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
        }

        to {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }
    }

    .bounceOut {
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-name: bounceOut;
        animation-name: bounceOut;
    }

    @-webkit-keyframes bounceOutDown {
        20% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
        }

        40%,
        45% {
            opacity: 1;
            -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
        }
    }

    @keyframes bounceOutDown {
        20% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
        }

        40%,
        45% {
            opacity: 1;
            -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
        }
    }

    .bounceOutDown {
        -webkit-animation-name: bounceOutDown;
        animation-name: bounceOutDown;
    }

    @-webkit-keyframes bounceOutLeft {
        20% {
            opacity: 1;
            -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
        }
    }

    @keyframes bounceOutLeft {
        20% {
            opacity: 1;
            -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
        }
    }

    .bounceOutLeft {
        -webkit-animation-name: bounceOutLeft;
        animation-name: bounceOutLeft;
    }

    @-webkit-keyframes bounceOutRight {
        20% {
            opacity: 1;
            -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
        }
    }

    @keyframes bounceOutRight {
        20% {
            opacity: 1;
            -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
        }
    }

    .bounceOutRight {
        -webkit-animation-name: bounceOutRight;
        animation-name: bounceOutRight;
    }

    @-webkit-keyframes bounceOutUp {
        20% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        40%,
        45% {
            opacity: 1;
            -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
        }
    }

    @keyframes bounceOutUp {
        20% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        40%,
        45% {
            opacity: 1;
            -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
        }
    }

    .bounceOutUp {
        -webkit-animation-name: bounceOutUp;
        animation-name: bounceOutUp;
    }

    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
    }

    @-webkit-keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInDown {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
    }

    @-webkit-keyframes fadeInDownBig {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInDownBig {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInDownBig {
        -webkit-animation-name: fadeInDownBig;
        animation-name: fadeInDownBig;
    }

    @-webkit-keyframes fadeInLeft {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInLeft {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInLeft {
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
    }

    @-webkit-keyframes fadeInLeftBig {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInLeftBig {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInLeftBig {
        -webkit-animation-name: fadeInLeftBig;
        animation-name: fadeInLeftBig;
    }

    @-webkit-keyframes fadeInRight {
        from {
            opacity: 0;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInRight {
        from {
            opacity: 0;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInRight {
        -webkit-animation-name: fadeInRight;
        animation-name: fadeInRight;
    }

    @-webkit-keyframes fadeInRightBig {
        from {
            opacity: 0;
            -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInRightBig {
        from {
            opacity: 0;
            -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInRightBig {
        -webkit-animation-name: fadeInRightBig;
        animation-name: fadeInRightBig;
    }

    @-webkit-keyframes fadeInUp {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInUp {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
    }

    @-webkit-keyframes fadeInUpBig {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInUpBig {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInUpBig {
        -webkit-animation-name: fadeInUpBig;
        animation-name: fadeInUpBig;
    }

    @-webkit-keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    .fadeOut {
        -webkit-animation-name: fadeOut;
        animation-name: fadeOut;
    }

    @-webkit-keyframes fadeOutDown {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
    }

    @keyframes fadeOutDown {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
    }

    .fadeOutDown {
        -webkit-animation-name: fadeOutDown;
        animation-name: fadeOutDown;
    }

    @-webkit-keyframes fadeOutDownBig {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
        }
    }

    @keyframes fadeOutDownBig {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
        }
    }

    .fadeOutDownBig {
        -webkit-animation-name: fadeOutDownBig;
        animation-name: fadeOutDownBig;
    }

    @-webkit-keyframes fadeOutLeft {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }

    @keyframes fadeOutLeft {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }

    .fadeOutLeft {
        -webkit-animation-name: fadeOutLeft;
        animation-name: fadeOutLeft;
    }

    @-webkit-keyframes fadeOutLeftBig {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
        }
    }

    @keyframes fadeOutLeftBig {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
        }
    }

    .fadeOutLeftBig {
        -webkit-animation-name: fadeOutLeftBig;
        animation-name: fadeOutLeftBig;
    }

    @-webkit-keyframes fadeOutRight {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }
    }

    @keyframes fadeOutRight {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }
    }

    .fadeOutRight {
        -webkit-animation-name: fadeOutRight;
        animation-name: fadeOutRight;
    }

    @-webkit-keyframes fadeOutRightBig {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
        }
    }

    @keyframes fadeOutRightBig {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
        }
    }

    .fadeOutRightBig {
        -webkit-animation-name: fadeOutRightBig;
        animation-name: fadeOutRightBig;
    }

    @-webkit-keyframes fadeOutUp {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }
    }

    @keyframes fadeOutUp {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }
    }

    .fadeOutUp {
        -webkit-animation-name: fadeOutUp;
        animation-name: fadeOutUp;
    }

    @-webkit-keyframes fadeOutUpBig {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
        }
    }

    @keyframes fadeOutUpBig {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
        }
    }

    .fadeOutUpBig {
        -webkit-animation-name: fadeOutUpBig;
        animation-name: fadeOutUpBig;
    }

    @-webkit-keyframes flip {
        from {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }

        40% {
            -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }

        50% {
            -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        80% {
            -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
    }

    @keyframes flip {
        from {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }

        40% {
            -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }

        50% {
            -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        80% {
            -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
    }

    .animated.flip {
        -webkit-backface-visibility: visible;
        backface-visibility: visible;
        -webkit-animation-name: flip;
        animation-name: flip;
    }

    @-webkit-keyframes flipInX {
        from {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            opacity: 0;
        }

        40% {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        60% {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            opacity: 1;
        }

        80% {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }
    }

    @keyframes flipInX {
        from {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            opacity: 0;
        }

        40% {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        60% {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            opacity: 1;
        }

        80% {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }
    }

    .flipInX {
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
        -webkit-animation-name: flipInX;
        animation-name: flipInX;
    }

    @-webkit-keyframes flipInY {
        from {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            opacity: 0;
        }

        40% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        60% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            opacity: 1;
        }

        80% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }
    }

    @keyframes flipInY {
        from {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            opacity: 0;
        }

        40% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        60% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            opacity: 1;
        }

        80% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }
    }

    .flipInY {
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
        -webkit-animation-name: flipInY;
        animation-name: flipInY;
    }

    @-webkit-keyframes flipOutX {
        from {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }

        30% {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            opacity: 1;
        }

        to {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            opacity: 0;
        }
    }

    @keyframes flipOutX {
        from {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }

        30% {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            opacity: 1;
        }

        to {
            -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            opacity: 0;
        }
    }

    .flipOutX {
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-name: flipOutX;
        animation-name: flipOutX;
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
    }

    @-webkit-keyframes flipOutY {
        from {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }

        30% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
            opacity: 1;
        }

        to {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            opacity: 0;
        }
    }

    @keyframes flipOutY {
        from {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }

        30% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
            opacity: 1;
        }

        to {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            opacity: 0;
        }
    }

    .flipOutY {
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
        -webkit-animation-name: flipOutY;
        animation-name: flipOutY;
    }

    @-webkit-keyframes lightSpeedIn {
        from {
            -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
            transform: translate3d(100%, 0, 0) skewX(-30deg);
            opacity: 0;
        }

        60% {
            -webkit-transform: skewX(20deg);
            transform: skewX(20deg);
            opacity: 1;
        }

        80% {
            -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @keyframes lightSpeedIn {
        from {
            -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
            transform: translate3d(100%, 0, 0) skewX(-30deg);
            opacity: 0;
        }

        60% {
            -webkit-transform: skewX(20deg);
            transform: skewX(20deg);
            opacity: 1;
        }

        80% {
            -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .lightSpeedIn {
        -webkit-animation-name: lightSpeedIn;
        animation-name: lightSpeedIn;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    @-webkit-keyframes lightSpeedOut {
        from {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
            transform: translate3d(100%, 0, 0) skewX(30deg);
            opacity: 0;
        }
    }

    @keyframes lightSpeedOut {
        from {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
            transform: translate3d(100%, 0, 0) skewX(30deg);
            opacity: 0;
        }
    }

    .lightSpeedOut {
        -webkit-animation-name: lightSpeedOut;
        animation-name: lightSpeedOut;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    @-webkit-keyframes rotateIn {
        from {
            -webkit-transform-origin: center;
            transform-origin: center;
            -webkit-transform: rotate3d(0, 0, 1, -200deg);
            transform: rotate3d(0, 0, 1, -200deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: center;
            transform-origin: center;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @keyframes rotateIn {
        from {
            -webkit-transform-origin: center;
            transform-origin: center;
            -webkit-transform: rotate3d(0, 0, 1, -200deg);
            transform: rotate3d(0, 0, 1, -200deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: center;
            transform-origin: center;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .rotateIn {
        -webkit-animation-name: rotateIn;
        animation-name: rotateIn;
    }

    @-webkit-keyframes rotateInDownLeft {
        from {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @keyframes rotateInDownLeft {
        from {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .rotateInDownLeft {
        -webkit-animation-name: rotateInDownLeft;
        animation-name: rotateInDownLeft;
    }

    @-webkit-keyframes rotateInDownRight {
        from {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @keyframes rotateInDownRight {
        from {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .rotateInDownRight {
        -webkit-animation-name: rotateInDownRight;
        animation-name: rotateInDownRight;
    }

    @-webkit-keyframes rotateInUpLeft {
        from {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @keyframes rotateInUpLeft {
        from {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .rotateInUpLeft {
        -webkit-animation-name: rotateInUpLeft;
        animation-name: rotateInUpLeft;
    }

    @-webkit-keyframes rotateInUpRight {
        from {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: rotate3d(0, 0, 1, -90deg);
            transform: rotate3d(0, 0, 1, -90deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    @keyframes rotateInUpRight {
        from {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: rotate3d(0, 0, 1, -90deg);
            transform: rotate3d(0, 0, 1, -90deg);
            opacity: 0;
        }

        to {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .rotateInUpRight {
        -webkit-animation-name: rotateInUpRight;
        animation-name: rotateInUpRight;
    }

    @-webkit-keyframes rotateOut {
        from {
            -webkit-transform-origin: center;
            transform-origin: center;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: center;
            transform-origin: center;
            -webkit-transform: rotate3d(0, 0, 1, 200deg);
            transform: rotate3d(0, 0, 1, 200deg);
            opacity: 0;
        }
    }

    @keyframes rotateOut {
        from {
            -webkit-transform-origin: center;
            transform-origin: center;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: center;
            transform-origin: center;
            -webkit-transform: rotate3d(0, 0, 1, 200deg);
            transform: rotate3d(0, 0, 1, 200deg);
            opacity: 0;
        }
    }

    .rotateOut {
        -webkit-animation-name: rotateOut;
        animation-name: rotateOut;
    }

    @-webkit-keyframes rotateOutDownLeft {
        from {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
            opacity: 0;
        }
    }

    @keyframes rotateOutDownLeft {
        from {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
            opacity: 0;
        }
    }

    .rotateOutDownLeft {
        -webkit-animation-name: rotateOutDownLeft;
        animation-name: rotateOutDownLeft;
    }

    @-webkit-keyframes rotateOutDownRight {
        from {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
            opacity: 0;
        }
    }

    @keyframes rotateOutDownRight {
        from {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
            opacity: 0;
        }
    }

    .rotateOutDownRight {
        -webkit-animation-name: rotateOutDownRight;
        animation-name: rotateOutDownRight;
    }

    @-webkit-keyframes rotateOutUpLeft {
        from {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
            opacity: 0;
        }
    }

    @keyframes rotateOutUpLeft {
        from {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
            -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
            opacity: 0;
        }
    }

    .rotateOutUpLeft {
        -webkit-animation-name: rotateOutUpLeft;
        animation-name: rotateOutUpLeft;
    }

    @-webkit-keyframes rotateOutUpRight {
        from {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: rotate3d(0, 0, 1, 90deg);
            transform: rotate3d(0, 0, 1, 90deg);
            opacity: 0;
        }
    }

    @keyframes rotateOutUpRight {
        from {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            opacity: 1;
        }

        to {
            -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
            -webkit-transform: rotate3d(0, 0, 1, 90deg);
            transform: rotate3d(0, 0, 1, 90deg);
            opacity: 0;
        }
    }

    .rotateOutUpRight {
        -webkit-animation-name: rotateOutUpRight;
        animation-name: rotateOutUpRight;
    }

    @-webkit-keyframes hinge {
        0% {
            -webkit-transform-origin: top left;
            transform-origin: top left;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
        }

        20%,
        60% {
            -webkit-transform: rotate3d(0, 0, 1, 80deg);
            transform: rotate3d(0, 0, 1, 80deg);
            -webkit-transform-origin: top left;
            transform-origin: top left;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
        }

        40%,
        80% {
            -webkit-transform: rotate3d(0, 0, 1, 60deg);
            transform: rotate3d(0, 0, 1, 60deg);
            -webkit-transform-origin: top left;
            transform-origin: top left;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, 700px, 0);
            transform: translate3d(0, 700px, 0);
            opacity: 0;
        }
    }

    @keyframes hinge {
        0% {
            -webkit-transform-origin: top left;
            transform-origin: top left;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
        }

        20%,
        60% {
            -webkit-transform: rotate3d(0, 0, 1, 80deg);
            transform: rotate3d(0, 0, 1, 80deg);
            -webkit-transform-origin: top left;
            transform-origin: top left;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
        }

        40%,
        80% {
            -webkit-transform: rotate3d(0, 0, 1, 60deg);
            transform: rotate3d(0, 0, 1, 60deg);
            -webkit-transform-origin: top left;
            transform-origin: top left;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, 700px, 0);
            transform: translate3d(0, 700px, 0);
            opacity: 0;
        }
    }

    .hinge {
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-name: hinge;
        animation-name: hinge;
    }

    @-webkit-keyframes jackInTheBox {
        from {
            opacity: 0;
            -webkit-transform: scale(0.1) rotate(30deg);
            transform: scale(0.1) rotate(30deg);
            -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
        }

        50% {
            -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
        }

        70% {
            -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
        }

        to {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes jackInTheBox {
        from {
            opacity: 0;
            -webkit-transform: scale(0.1) rotate(30deg);
            transform: scale(0.1) rotate(30deg);
            -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
        }

        50% {
            -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
        }

        70% {
            -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
        }

        to {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    .jackInTheBox {
        -webkit-animation-name: jackInTheBox;
        animation-name: jackInTheBox;
    }

    @-webkit-keyframes rollIn {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes rollIn {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .rollIn {
        -webkit-animation-name: rollIn;
        animation-name: rollIn;
    }

    @-webkit-keyframes rollOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
            transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        }
    }

    @keyframes rollOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
            transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        }
    }

    .rollOut {
        -webkit-animation-name: rollOut;
        animation-name: rollOut;
    }

    @-webkit-keyframes zoomIn {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        50% {
            opacity: 1;
        }
    }

    @keyframes zoomIn {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        50% {
            opacity: 1;
        }
    }

    .zoomIn {
        -webkit-animation-name: zoomIn;
        animation-name: zoomIn;
    }

    @-webkit-keyframes zoomInDown {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    @keyframes zoomInDown {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    .zoomInDown {
        -webkit-animation-name: zoomInDown;
        animation-name: zoomInDown;
    }

    @-webkit-keyframes zoomInLeft {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    @keyframes zoomInLeft {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    .zoomInLeft {
        -webkit-animation-name: zoomInLeft;
        animation-name: zoomInLeft;
    }

    @-webkit-keyframes zoomInRight {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    @keyframes zoomInRight {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    .zoomInRight {
        -webkit-animation-name: zoomInRight;
        animation-name: zoomInRight;
    }

    @-webkit-keyframes zoomInUp {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    @keyframes zoomInUp {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    .zoomInUp {
        -webkit-animation-name: zoomInUp;
        animation-name: zoomInUp;
    }

    @-webkit-keyframes zoomOut {
        from {
            opacity: 1;
        }

        50% {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        to {
            opacity: 0;
        }
    }

    @keyframes zoomOut {
        from {
            opacity: 1;
        }

        50% {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        to {
            opacity: 0;
        }
    }

    .zoomOut {
        -webkit-animation-name: zoomOut;
        animation-name: zoomOut;
    }

    @-webkit-keyframes zoomOutDown {
        40% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        to {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    @keyframes zoomOutDown {
        40% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        to {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    .zoomOutDown {
        -webkit-animation-name: zoomOutDown;
        animation-name: zoomOutDown;
    }

    @-webkit-keyframes zoomOutLeft {
        40% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
            transform: scale(0.1) translate3d(-2000px, 0, 0);
            -webkit-transform-origin: left center;
            transform-origin: left center;
        }
    }

    @keyframes zoomOutLeft {
        40% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
            transform: scale(0.1) translate3d(-2000px, 0, 0);
            -webkit-transform-origin: left center;
            transform-origin: left center;
        }
    }

    .zoomOutLeft {
        -webkit-animation-name: zoomOutLeft;
        animation-name: zoomOutLeft;
    }

    @-webkit-keyframes zoomOutRight {
        40% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
            transform: scale(0.1) translate3d(2000px, 0, 0);
            -webkit-transform-origin: right center;
            transform-origin: right center;
        }
    }

    @keyframes zoomOutRight {
        40% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
        }

        to {
            opacity: 0;
            -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
            transform: scale(0.1) translate3d(2000px, 0, 0);
            -webkit-transform-origin: right center;
            transform-origin: right center;
        }
    }

    .zoomOutRight {
        -webkit-animation-name: zoomOutRight;
        animation-name: zoomOutRight;
    }

    @-webkit-keyframes zoomOutUp {
        40% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        to {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    @keyframes zoomOutUp {
        40% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        to {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    .zoomOutUp {
        -webkit-animation-name: zoomOutUp;
        animation-name: zoomOutUp;
    }

    @-webkit-keyframes slideInDown {
        from {
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes slideInDown {
        from {
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .slideInDown {
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;
    }

    @-webkit-keyframes slideInLeft {
        from {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes slideInLeft {
        from {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .slideInLeft {
        -webkit-animation-name: slideInLeft;
        animation-name: slideInLeft;
    }

    @-webkit-keyframes slideInRight {
        from {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes slideInRight {
        from {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .slideInRight {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
    }

    @-webkit-keyframes slideInUp {
        from {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes slideInUp {
        from {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .slideInUp {
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
    }

    @-webkit-keyframes slideOutDown {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
    }

    @keyframes slideOutDown {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
    }

    .slideOutDown {
        -webkit-animation-name: slideOutDown;
        animation-name: slideOutDown;
    }

    @-webkit-keyframes slideOutLeft {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }

    @keyframes slideOutLeft {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }

    .slideOutLeft {
        -webkit-animation-name: slideOutLeft;
        animation-name: slideOutLeft;
    }

    @-webkit-keyframes slideOutRight {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }
    }

    @keyframes slideOutRight {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }
    }

    .slideOutRight {
        -webkit-animation-name: slideOutRight;
        animation-name: slideOutRight;
    }

    @-webkit-keyframes slideOutUp {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }
    }

    @keyframes slideOutUp {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }
    }

    .slideOutUp {
        -webkit-animation-name: slideOutUp;
        animation-name: slideOutUp;
    }

}
@charset "UTF-8";

:root {
  --btn-background: red;
  --btn-background-hover: #000;
  --h2-font-family: "Great Vibes";
  --h1: "Great Vibes";
  --h2: "Great Vibes";
  --h3: "Great Vibes"
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 75%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format("woff2");
  unicode-range: u+0460-052f,
    u+1c80-1c88,
    u+20b4,
    u+2de0-2dff,
    u+a640-a69f,
    u+fe2e-fe2f
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format("woff2");
  unicode-range: u+0301,
    u+0400-045f,
    u+0490-0491,
    u+04b0-04b1,
    u+2116
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format("woff2");
  unicode-range: u+1f??
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format("woff2");
  unicode-range: u+0370-03ff
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format("woff2");
  unicode-range: u+0590-05ff,
    u+200c-2010,
    u+20aa,
    u+25cc,
    u+fb1d-fb4f
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format("woff2");
  unicode-range: u+0102-0103,
    u+0110-0111,
    u+0128-0129,
    u+0168-0169,
    u+01a0-01a1,
    u+01af-01b0,
    u+1ea0-1ef9,
    u+20ab
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format("woff2");
  unicode-range: u+0100-024f,
    u+0259,
    u+1e??,
    u+2020,
    u+20a0-20ab,
    u+20ad-20cf,
    u+2113,
    u+2c60-2c7f,
    u+a720-a7ff
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format("woff2");
  unicode-range: u+00??,
    u+0131,
    u+0152-0153,
    u+02bb-02bc,
    u+02c6,
    u+02da,
    u+02dc,
    u+2000-206f,
    u+2074,
    u+20ac,
    u+2122,
    u+2191,
    u+2193,
    u+2212,
    u+2215,
    u+feff,
    u+fffd
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format("woff2");
  unicode-range: u+0460-052f,
    u+1c80-1c88,
    u+20b4,
    u+2de0-2dff,
    u+a640-a69f,
    u+fe2e-fe2f
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format("woff2");
  unicode-range: u+0301,
    u+0400-045f,
    u+0490-0491,
    u+04b0-04b1,
    u+2116
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format("woff2");
  unicode-range: u+1f??
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format("woff2");
  unicode-range: u+0370-03ff
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format("woff2");
  unicode-range: u+0590-05ff,
    u+200c-2010,
    u+20aa,
    u+25cc,
    u+fb1d-fb4f
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format("woff2");
  unicode-range: u+0102-0103,
    u+0110-0111,
    u+0128-0129,
    u+0168-0169,
    u+01a0-01a1,
    u+01af-01b0,
    u+1ea0-1ef9,
    u+20ab
}

@font-face {
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format("woff2");
    unicode-range: u+0100-024f,
      u+0259,
      u+1e??,
      u+2020,
      u+20a0-20ab,
      u+20ad-20cf,
      u+2113,
      u+2c60-2c7f,
      u+a720-a7ff
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format("woff2");
    unicode-range: u+00??,
      u+0131,
      u+0152-0153,
      u+02bb-02bc,
      u+02c6,
      u+02da,
      u+02dc,
      u+2000-206f,
      u+2074,
      u+20ac,
      u+2122,
      u+2191,
      u+2193,
      u+2212,
      u+2215,
      u+feff,
      u+fffd
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format("woff2");
    unicode-range: u+0460-052f,
      u+1c80-1c88,
      u+20b4,
      u+2de0-2dff,
      u+a640-a69f,
      u+fe2e-fe2f
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format("woff2");
    unicode-range: u+0301,
      u+0400-045f,
      u+0490-0491,
      u+04b0-04b1,
      u+2116
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format("woff2");
    unicode-range: u+1f??
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format("woff2");
    unicode-range: u+0370-03ff
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format("woff2");
    unicode-range: u+0590-05ff,
      u+200c-2010,
      u+20aa,
      u+25cc,
      u+fb1d-fb4f
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format("woff2");
    unicode-range: u+0102-0103,
      u+0110-0111,
      u+0128-0129,
      u+0168-0169,
      u+01a0-01a1,
      u+01af-01b0,
      u+1ea0-1ef9,
      u+20ab
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format("woff2");
    unicode-range: u+0100-024f,
      u+0259,
      u+1e??,
      u+2020,
      u+20a0-20ab,
      u+20ad-20cf,
      u+2113,
      u+2c60-2c7f,
      u+a720-a7ff
  }
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format("woff2");
  unicode-range: u+00??,
    u+0131,
    u+0152-0153,
    u+02bb-02bc,
    u+02c6,
    u+02da,
    u+02dc,
    u+2000-206f,
    u+2074,
    u+20ac,
    u+2122,
    u+2191,
    u+2193,
    u+2212,
    u+2215,
    u+feff,
    u+fffd
}

@font-face {
  font-family: "orbitronBold";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/static/media/orbitron-bold.09ab259d.otf)
}

@font-face {
  font-family: "orbitronExtraBold";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/static/media/orbitron-extrabold.c46f97bc.ttf)
}

html {
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

main {
  display: block
}

hr {
  box-sizing: initial;
  height: 0;
  overflow: visible
}

pre {
  font-family: monospace, monospace;
  font-size: 1em
}

a {
  background-color: initial
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em
}

small {
  font-size: 80%
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: initial
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

img {
  border-style: none
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText
}

fieldset {
  padding: .35em .75em .625em
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  vertical-align: initial
}

textarea {
  overflow: auto
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

details {
  display: block
}

summary {
  display: list-item
}

[hidden],
template {
  display: none
}

blockquote,
q {
  quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

header {
  background: transparent;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 25px 0;
  z-index: 10;
  box-shadow: none
}

header .right .btn i {
  font-style: normal
}

.calandar .btn {
  margin-left: 23px;
  border: 1px solid red;
  border: 1px solid red;
  border: 1px solid var(--btn-background);
  padding-top: 11px;
  padding-bottom: 11px
}

header .btn:hover {
  border-color: #000;
  border-color: #000;
  border-color: var(--btn-background-hover)
}

header .btn.btnSelect {
  border: 1px solid transparent
}

header .topLogo {
  visibility: hidden;
  opacity: 0
}

header .topLogo img {
  max-height: 44px
}

header.fixedTop {
  background-color: #fff;
  padding: 15px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1)
}

header.fixedTop .topLogo {
  visibility: visible;
  opacity: 1
}

header.fixedTop .btn.btnSelect {
  border-color: #d1d1d1
}

hr {
  margin: 8px 0 21px;
  border: 0;
  border-bottom: 1px solid #d1d1d1
}



.pure-checkbox input[type=checkbox]:focus+label:before,
.pure-checkbox input[type=checkbox]:hover+label:before,
.pure-checkbox input[type=radio]:focus+label:before,
.pure-checkbox input[type=radio]:hover+label:before,
.pure-radiobutton input[type=checkbox]:focus+label:before,
.pure-radiobutton input[type=checkbox]:hover+label:before,
.pure-radiobutton input[type=radio]:focus+label:before,
.pure-radiobutton input[type=radio]:hover+label:before {
  border-color: #131313
}

.pure-checkbox input[type=checkbox]+label,
.pure-checkbox input[type=radio]+label,
.pure-radiobutton input[type=checkbox]+label,
.pure-radiobutton input[type=radio]+label {
  display: inline-block
}

.pure-checkbox input[type=checkbox]+label:before,
.pure-checkbox input[type=radio]+label:before,
.pure-radiobutton input[type=checkbox]+label:before,
.pure-radiobutton input[type=radio]+label:before {
  color: #131313;
  top: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #9b9b9b;
  border-radius: 3px
}

.pure-checkbox input[type=checkbox]+label:after,
.pure-checkbox input[type=radio]+label:after,
.pure-radiobutton input[type=checkbox]+label:after,
.pure-radiobutton input[type=radio]+label:after {
  background-color: #e20026;
  left: 6px;
  width: 10px;
  height: 10px;
  top: 11px
}

.pure-checkbox input[type=checkbox]+label:after,
.pure-radiobutton input[type=checkbox]+label:after {
  top: 11px;
  left: 5px;
  width: 9px;
  height: 4px;
  border-color: #e20026
}

.pure-checkbox input[type=checkbox]:checked+label:before,
.pure-radiobutton input[type=checkbox]:checked+label:before {
  background: transparent
}

.pure-checkbox-toggle input[type=checkbox]+label:before {
  color: #a9c14e
}

.pure-checkbox-toggle input[type=checkbox]:checked+label:before {
  box-shadow: inset 0 0 0 10px #131313
}

.pure-checkbox-toggle input[type=checkbox]:checked+label:after {
  border-color: #131313
}

.pure-checkbox,
.pure-radiobutton {
  margin-bottom: 5px
}

.calandar .btn,
.calandar .button {
  background: red;
  background: red;
  background: var(--btn-background);
  color: #fff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  border: 0;
  padding: 13px 15px;
  font-family: "Open Sans";
  border-radius: 0;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  white-space: nowrap
}

/*.btn:hover,
.button:hover {
  color:#fff;
  background:#000;
  background:var(--btn-background-hover)
}*/
.btn span,
.button span {
  vertical-align: bottom
}

.btn select,
.button select {
  display: inline-block
}

.btn.btnSelect,
.button.btnSelect {
  color: #d1d1d1;
  background: #fff
}

.btn.btnSelect select,
.button.btnSelect select {
  color: #131313
}

.loading {
  background-image: url(/static/media/loading.d02c8f0b.svg) !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  text-indent: 999%;
  overflow: hidden
}

.loading:hover {
  cursor: default;
  background-color: red !important;
  background-color: red !important;
  background-color: var(--btn-background) !important
}

.loadOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  background-size: 60px
}

.loadOverlay,
.loadOverlay:hover {
  background-color: hsla(0, 0%, 100%, .5) !important;
  cursor: default
}

#block-webform-2 .form-submit,
#block-webform-3 .form-submit {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 15px
}

select {
  -webkit-appearance: none;
  appearance: none;
  background: url(/static/media/select.9fc3249b.svg) no-repeat 100%;
  font-family: "Open Sans";
  font-weight: 400;
  border: 0;
  color: #131313;
  padding-right: 15px
}

input[type=email],
input[type=number],
input[type=password],
input[type=tel],
input[type=text],
textarea {
  border-radius: 0;
  border: 0;
  padding: 15px 26px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: "Open Sans";
  color: #131313
}


select.form-control {
  border-radius: 0;
  border: 0;
  padding: 15px 26px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: "Open Sans";
  color: #131313;
  background-image: url(/static/media/select2.5cf5d4b0.svg)
}

textarea {
  height: 135px;
  resize: none;
  padding-top: 20px;
  margin-bottom: 10px
}

.notefield {
  font-size: 14px;
  color: #9b9b9b;
  margin: 5px 0 0
}

.form2Cols {
  display: flex;
  grid-gap: 15px;
  grid-gap: 15px;
  gap: 15px
}

.ui-datepicker-month,
.ui-datepicker-year {
  background: none;
  padding-left: 0 !important;
  -webkit-appearance: auto;
  appearance: auto;
  padding-right: 0
}

@media screen and (max-width:767px) {

  input[type=email],
  input[type=number],
  input[type=tel],
  input[type=text],
  select,
  textarea {
    padding-left: 15px;
    padding-right: 15px
  }

  .consent .checkmark {
    bottom: 6px
  }
}

body,
html {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #131313;
  min-width: 320px;
  font-family: "Open Sans"
}

body,
html {
  overflow-x: hidden
}

body {
  color: #131313;
  min-width: 320px
}

* {
  -ms-box-sizing: border-box;
  box-sizing: border-box
}

br {
  clear: both
}

main {
  position: relative;
  z-index: 1
}

:focus {
  outline: none
}

::-webkit-input-placeholder {
  color: #6d6d6d !important;
  opacity: 1 !important
}

::placeholder {
  color: #6d6d6d !important;
  opacity: 1 !important
}

:-ms-input-placeholder {
  color: #6d6d6d !important
}

::-ms-input-placeholder {
  color: #6d6d6d !important
}

a {
  text-decoration: none
}

b,
strong {
  font-weight: 700
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0
}

ul,
ul li {
  list-style: none
}

ul li {
  margin-bottom: 20px
}

p,
ul li {
  line-height: 22px
}

p {
  margin: 0 0 25px;
}

.clr {
  *zoom: 1
}

.clr:after,
.clr:before {
  content: " ";
  display: table;
  clear: both
}

.space10 {
  padding: 10px 0
}

.space15 {
  padding: 15px 0
}

.space20 {
  padding: 20px 0
}

.space25 {
  padding: 25px 0
}

.space30 {
  padding: 30px 0
}

.center {
  text-align: center
}

.alignLeft {
  text-align: left
}

.alignRight {
  text-align: right
}

.colorPrimary {
  color: #e20026 !important
}

.colorSecondary {
  color: #131313 !important
}

.w20,
.w30,
.w40,
.w50,
.w60 {
  float: left
}

.w20 {
  width: 20%
}

.w30 {
  width: 30%
}

.w33 {
  width: 33.33%
}

.w40 {
  width: 40%
}

.w50 {
  width: 50%
}

.w60 {
  width: 60%
}

.fullWidht,
.w100,
.w-100 {
  width: 100%
}

.w810 {
  max-width: 810px
}

.w1088 {
  max-width: 1088px
}

.w1375 {
  max-width: 1375px
}

.pad15 {
  padding: 15px
}

.pad20 {
  padding: 20px
}

.pad25 {
  padding: 25px
}

.pad30 {
  padding: 30px
}

.pad35 {
  padding: 35px
}

.mxAuto {
  margin: 0 auto
}

.container,
.wrapper {
  max-width: 1728px;
  padding: 0 40px;
  margin: 0 auto;
  width: 100%
}



.filterOption .close span {
  font-size: 15px;
  vertical-align: top;
  margin-top: 2px
}

.filterOption .close:hover {
  color: #e20026;
  border-color: #e20026
}

.filterOption .itemOption {
  position: relative;
  padding: 0 1.5%;
  flex: 0 0 16.66%;
  max-width: 16.66%
}

.filterOption .itemOption:before {
  height: 95px;
  left: 0;
  content: "";
  position: absolute;
  top: 12px;
  border-right: 1px solid #d1d1d1
}

.filterOption .itemOption:first-child:before {
  display: none
}

.filterOption .h4 {
  font-weight: 700;
  margin-bottom: 10px
}

.lstPromo {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: -10px;
  bottom: 0
}

.lstPromo .item {
  margin-bottom: 16px;
  position: relative;
  min-height: 100px;
  padding: 13px 20px 13px 94px
}

.lstPromo .deco {
  width: 63px;
  height: 61px;
  background: url(/static/media/deco.64ca53a0.svg) no-repeat;
  background-size: cover;
  text-align: center;
  line-height: 61px;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  position: absolute;
  left: 20px;
  top: 20px
}

.lstPromo .h3 {
  font-size: 20px;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 15px;
  width: 100%;
  font-family: "Great Vibes";
  font-family: "Great Vibes";
  font-family: var(--h3);
  color: red;
  color: red;
  color: var(--btn-background)
}

.lstPromo span {
  margin-right: 15px;
  color: #9b9b9b;
  vertical-align: bottom;
  position: relative;
  top: -2px
}

.lstPromo a {
  color: #131313
}

.lstPromo .infoTips {
  width: 380px
}

.infoTips {
  background: #f8f8f8;
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  min-width: 280px
}

.infoTips .content {
  max-height: 161px;
  max-height: 170px;
  overflow: auto;
  padding: 21px 27px 25px
}

.infoTips .modal_close {
  position: absolute;
  right: -8px;
  top: -10px;
  background: #fff
}

.infoTips .modal_close:hover {
  border-color: red;
  border-color: red;
  border-color: var(--btn-background)
}

.infoTips .modal_close:hover:after,
.infoTips .modal_close:hover:before {
  background: red !important;
  background: red !important;
  background: var(--btn-background) !important
}

.infoTips h4 {
  margin: 0 0 5px;
  text-transform: uppercase;
  font-size: 16px
}

.infoTips u {
  text-decoration: line-through;
  color: #9b9b9b
}

.infoTips li {
  margin-bottom: 15px;
  background: url(/static/media/lst.560d1bbe.svg) no-repeat 0 7px;
  padding-left: 21px;
  max-width: 100% !important;
  flex: 0 0 100% !important;
  font-size: 16px
}

.infoTips li:last-child {
  margin-bottom: 0
}

.infoTips.show {
  visibility: visible;
  opacity: 1
}

#fixHeightPopupProm {
  max-height: unset
}

.puce ul {
  margin-bottom: 25px
}

.puce li {
  margin-bottom: 15px;
  background: url(/static/media/lst.560d1bbe.svg) no-repeat 0 7px;
  padding-left: 21px;
  max-width: 100% !important;
  flex: 0 0 100% !important;
  font-size: 16px
}

.puce li:last-child {
  margin-bottom: 0
}

.equipment ul {
  display: flex;
  flex-wrap: wrap
}

.equipment li {
  border: 1px solid #d1d1d1;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9b9b9b;
  margin: 0 7px 5px 0
}

.equipment li span {
  font-size: 26px
}

.equipment img {
  width: 26px;
  -webkit-filter: invert(.6);
  filter: invert(.6)
}

.moreTxt {
  padding: 0 0 12px 31px;
  border-bottom: 2px solid red;
  border-bottom: 2px solid red;
  border-bottom: 2px solid var(--btn-background);
  text-transform: uppercase;
  color: #131313;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer
}

.moreTxt:before {
  content: "+";
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  width: 18px;
  height: 18px;
  background: red;
  background: red;
  background: var(--btn-background);
  display: block;
  position: absolute;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  top: 2px;
  left: 0
}

.moreTxt:hover {
  color: red;
  color: red;
  color: var(--btn-background)
}

.leftCol {
  overflow: hidden;
  position: relative
}

.leftCol,
.rightCol {
  max-width: 24%;
  width: 100%
}

.centerCol {
  flex: 1 1;
  margin: 0 16px
}

.roomLst>.item {
  margin-bottom: 48px
}

.roomLst .firstInfo {
  min-height: 286px;
  position: relative;
  padding: 30px 35px 35px 50%
}

.roomLst .contImg {
  height: 100%;
  max-width: 46%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer
}

.roomLst .contImg img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%
}

.roomLst .h4 {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  color: red;
  color: red;
  color: var(--btn-background);
  font-family: "Great Vibes";
  font-family: "Great Vibes";
  font-family: var(--h2);
  font-size: 35px
}

.roomLst .max {
  margin-bottom: 15px
}

.roomLst .max span {
  margin-right: 4px;
  vertical-align: text-bottom;
  color: #9b9b9b
}

.roomLst .desc {
  margin-bottom: 20px
}

.roomLst .desc p {
  text-align: justify
}

.roomLst .equipment {
  max-width: 470px;
  float: left
}

.roomLst .moreTxt {
  float: right;
  margin-top: 16px;
  margin-right: 15px
}

.roomLst .secInfo {
  background: #f2f2f2;
  padding: 25px 34px
}

.contTxtCond {
  color: red;
  color: red;
  color: var(--btn-background);
  text-decoration: underline
}

.contTxtCond span {
  vertical-align: bottom;
  margin-right: 3px;
  color: #9b9b9b
}

.secInfo .head {
  margin-bottom: 20px;
  padding-right: 15px
}

.secInfo .h4 {
  border: 0;
  padding: 0;
  margin: 0;
  color: #131313;
  font-size: 16px;
  font-family: "Great Vibes";
  font-family: "Great Vibes";
  font-family: var(--h2)
}

.lstBook .item {
  padding-right: 190px;
  position: relative;
  margin-bottom: 10px
}

.lstBook .left {
  min-height: 50px;
  background: #fff;
  border: 1px solid #d1d1d1;
  padding: 12px 20px;
  justify-content: left
}

.lstBook .btn {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 174px;
  background: red;
  background: red;
  background: var(--btn-background)
}

.lstBook .btn:hover {
  background: #000;
  background: #000;
  background: var(--btn-background-hover)
}

.lstBook .nb {
  border-right: 1px solid #d1d1d1;
  padding-right: 18px
}

.lstBook .discount,
.lstBook .price {
  padding-left: 18px;
  align-items: center
}

.lstBook .discount {
  font-size: 10px;
  cursor: pointer
}

.lstBook .discount span {
  vertical-align: middle;
  color: #9b9b9b
}

.lstBook .discount .infoTips {
  margin-top: 15px
}

.lstBook .discount:hover span {
  color: #e20026
}

.lstBook .discount:hover .infoTips {
  visibility: visible;
  opacity: 1
}

.rateName:hover span {
  color: red;
  color: red;
  color: var(--btn-background)
}

.rateName:hover .infoTips {
  visibility: visible;
  opacity: 1
}

.price .oldPrice {
  text-decoration: line-through;
  font-size: 14px;
  color: #9b9b9b
}

.price .newPrice {
  font-weight: 700;
  font-size: 18px;
  margin-left: 8px
}

.condition {
  position: relative;
  cursor: pointer
}

.lstTools .item {
  margin-bottom: 53px
}

.lstTools .head {
  background: red;
  background: red;
  background: var(--btn-background);
  color: #131313
}

.lstTools .head strong {
  color: #fff
}

.lstTools .foot,
.lstTools .head,
.lstTools .lstItem {
  padding: 20px 27px
}

.lstTools .foot {
  padding-bottom: 33px
}

.lstTools .foot .price {
  margin-bottom: 15px;
  display: block
}

.lstTools .contLstItem .lstItem:nth-last-child(odd) {
  background: #f2f2f2
}

.lstTools .namePrice {
  margin-bottom: 8px
}

.lstTools .footLstItem {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center
}

.option a {
  width: 30px;
  height: 30px;
  background: #d1d1d1;
  display: inline-block;
  text-align: center;
  line-height: 27px;
  color: #757575
}

.option a:hover {
  background: #e20026;
  color: #fff
}

.option.withTxt a {
  white-space: nowrap;
  text-decoration: underline;
  color: #9b9b9b
}

.option.withTxt a:hover {
  color: #131313
}

.option.withTxt a:hover span {
  color: #fff
}

.option.withTxt a span {
  margin: 0 16px 0 3px
}

.result {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 8px
}

.result li {
  max-width: 48%;
  flex: 0 0 48%;
  margin-bottom: 12px
}

.result li span {
  color: #9b9b9b;
  margin-right: 5px
}

.boxWhite {
  background: #fff;
  padding: 20px 27px
}

.totalPrice {
  font-size: 18px;
  margin-bottom: 19px
}

.boxOcc {
  color: #fff;
  position: absolute;
  background: #fff;
  border: 1px solid #fff;
  z-index: 10;
  padding: 14px 20px 24px;
  width: 100%;
  top: 92px;
  max-width: 306px;
  visibility: hidden;
  opacity: 0;
  background: red;
  background: red;
  background: var(--btn-background)
}

.boxOcc .ite,
.boxOcc .nb {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.boxOcc .ite {
  margin-bottom: 12px
}

.boxOcc .ite:last-child {
  margin-bottom: 0
}

.boxOcc .nb {
  width: 60%;
  text-align: center;
  align-items: center
}

.boxOcc .nb span {
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 1px solid #fff;
  cursor: pointer;
  line-height: 21px
}

.boxOcc .nb span:hover {
  background: #fff;
  color: red;
  color: red;
  color: var(--btn-background)
}

.boxOcc .nb input {
  background: red;
  background: red;
  background: var(--btn-background);
  color: #fff;
  text-align: center;
  margin: 0 8px;
  height: 48px;
  -webkit-appearance: none;
  appearance: none;
  border-bottom: 1px solid #fff
}

.boxOcc .nb input::-webkit-inner-spin-button,
.boxOcc .nb input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.boxOcc .nb input[type=number] {
  -moz-appearance: textfield
}

.boxOcc strong {
  font-weight: 400
}

.contBoxOcc {
  width: 100%
}

.contBoxOcc:hover .boxOcc {
  opacity: 1;
  visibility: visible
}

.head_modal {
  background: #f6f6f6;
  padding: 45px 20px 25px;
  font-weight: 700
}

.head_modal img {
  margin-bottom: 15px
}

.cont_modal {
  padding: 40px 20px
}

.cont_modal p {
  color: #131313
}

.leaderInternalPage {
  height: 426px !important
}

.boxCenter {
  margin-top: 40px
}

.boxStandard {
  padding: 25px 69px
}

.boxCenter .btn.w100 {
  width: auto;
  float: right;
  margin: 0 0 30px !important
}

.roomLst.detail>.item {
  margin-bottom: 0;
  box-shadow: none
}

.roomLst.detail .firstInfo {
  padding: 0
}

.roomLst.detail .contImg {
  position: static;
  max-width: inherit;
  height: 389px
}

.roomLst.detail .contImg img {
  object-fit: cover;
  object-position: center
}

.roomLst.detail .roomInfo {
  padding: 35px 37px 37px;
  border: 1px solid #d1d1d1;
  border-top: 0
}

.roomInfo .h4 {
  line-height: 35px
}

.roomLst.detail .equipment {
  max-width: inherit
}

.roomLst.detail .lstBook .price {
  width: 54%;
  justify-content: flex-end;
  padding-right: 7%
}

/*.col2Conex {
  grid-gap:0 10px;
  gap:0 10px;
  align-items:stretch
}
.col2Conex .boxLogin {
  max-width:49.3%
}*/
.center .btn.w100 {
  float: none
}

.noLine {
  text-decoration: none !important
}

.colBigRight .rightCol {
  max-width: 518px;
  width: 100%
}

.stat {
  text-transform: uppercase;
  color: #9b9b9b;
  font-weight: 600;
  padding-left: 44px;
  line-height: 32px;
  background: url(/static/media/statOk.1a5f07c5.svg) no-repeat 0 0
}

.stat.success {
  color: #9bd219
}

.stat.error {
  color: red;
  background-image: url(/static/media/statError.9bc30816.svg)
}

.listConf .item {
  margin-bottom: 18px
}

.listConf .item:last-child {
  margin-bottom: 0
}

.listConf .item strong {
  color: #e20026
}

img.cover {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%
}

.blkSummay .content,
.blkSummay .foot,
.blkSummay .head {
  padding: 50px 70px
}

.blkSummay .head {
  padding-bottom: 0;
  flex: 1 1
}

.blkSummay .head .h3 {
  width: 100%;
  font-family: "Great Vibes";
  font-family: "Great Vibes";
  font-family: var(--h1)
}

.blkSummay .head .contImg {
  max-width: 46%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%
}

.blkSummay .head .right {
  flex: 1 1;
  padding: 15px 10%
}

.blkSummay .head .bordered {
  padding-left: 46%;
  position: relative
}

.blkSummay .head .result {
  margin: 0 0 5px
}

.blkSummay .head .result li {
  max-width: 100%;
  flex: 0 0 100%;
  margin-bottom: 5px
}

.blkSummay .content {
  padding-bottom: 20px
}

.blkSummay .content .resumInfo {
  align-items: flex-start
}

.blkSummay .content .resumInfo p {
  margin: 0 0 15px
}

.blkSummay .content p {
  color: #131313
}

.blkSummay .content p:last-child {
  margin-bottom: 0
}

.blkSummay .foot {
  background: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 30px
}

.blkSummay .foot .infoTips {
  visibility: visible;
  opacity: 1;
  position: static;
  padding: 0
}

.blkSummay .foot .infoTips ul {
  margin-bottom: 25px
}

.blkSummay .foot .infoTips ul:last-child {
  margin-bottom: 0
}

.ref {
  color: #a19789
}

.fex1 {
  flex: 1 1
}

.advanceTools {
  margin-bottom: 21px;
  font-size: 14px
}

.advanceTools a {
  color: #131313
}

.advanceTools a:hover,
.advanceTools a:hover span {
  color: #e20026
}

.advanceTools span {
  vertical-align: middle;
  color: #9b9b9b
}

.addMap iframe {
  width: 100%;
  height: 440px
}

.colBigRight .centerCol {
  margin: 0 35px 0 0
}

.bookingList {
  margin-bottom: 60px;
  grid-gap: 16px 1.6%;
  grid-gap: 16px 1.6%;
  gap: 16px 1.6%
}

.bookingList>.item {
  max-width: 23.8%;
  flex: 0 0 23.8%;
  margin-bottom: 90px
}

.bookingList .h3 {
  margin-bottom: 25px;
  text-transform: uppercase;
  font-size: 16px
}

.bookingList .contConf {
  border: 1px solid #9b9b9b;
  background: #f8f8f8
}

.bookingList .contImg {
  height: 261px
}

.bookingList .listConf .item {
  max-width: inherit;
  flex: 0 0 100%
}

.bookingList .puce {
  padding: 30px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  position: relative
}

.bookingList .puce ul {
  margin-bottom: 0
}

.bookingList .puce li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.bookingList .puce li:last-child {
  margin-bottom: 0
}

.bookingList .btn {
  margin-bottom: -80px;
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0
}

.footBgColor {
  background: #f8f8f8
}

.sizeBase {
  font-size: 16px
}

.largeTextarea {
  height: 200px
}

.contTot {
  padding-top: 30px !important;
  padding-bottom: 15px !important
}

.contTot .left,
.contTot .right {
  flex: 0 0 50%
}

.contTot .left .priceTot {
  font-size: 26px;
  font-weight: 700;
  color: #9bd219
}

.contTot .option {
  margin-bottom: 7px
}

.contTot .option:last-child {
  margin-bottom: 0
}

.timing {
  align-items: center;
  font-size: 20px;
  margin-bottom: 30px
}

.timing .h3 {
  border-right: 1px solid #707070;
  padding: 0 29px 0 0;
  margin: 0 25px 0 0;
  text-transform: uppercase
}

.timing .material-symbols-rounded {
  color: red;
  color: red;
  color: var(--btn-background)
}

.timing .sec {
  color: #e20026
}

.calandar .bodyMonth .itemNumb {
  padding: 8px
}

.calandar .bodyMonth .itemNumb .content {
  align-content: flex-start;
  min-height: 50px
}

.calandar .bodyMonth .itemNumb.lastDate .content {
  opacity: .5
}

.calandar .bodyMonth .itemNumb.active .content {
  background: #000
}

.calandar .bodyMonth .line {
  background: #e20026
}

@media screen and (min-width:768px) {
  .popupPromotion {
    opacity: 1;
    visibility: visible;
    position: fixed;
    top: 371px;
    left: 25%;
    max-height: 100%;
    max-width: 90%
  }
}

@media screen and (max-width:636px) {
  main {
    position: absolute
  }
}

@media screen and (min-width:1530px) {
  .popupPromotion {
    left: 28.57143%
  }
}

@media screen and (min-width:1700px) {
  .popupPromotion {
    left: 33.33333%
  }
}

@media screen and (min-width:2220px) {
  .popupPromotion {
    left: 37.5%
  }
}

@media screen and (min-width:2950px) {
  .popupPromotion {
    left: 40%
  }
}

.removeUpDown::-webkit-inner-spin-button,
.removeUpDown::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.removeUpDown[type=number] {
  -moz-appearance: textfield
}

.contFormControl {
  position: relative
}

.contFormControl .condition li,
.contFormControl .condition p {
  color: #131313;
  font-size: 16px;
  font-weight: 400
}

.consent a {
  text-decoration: underline;
  color: #131313
}

.consent a:hover {
  color: #e20026
}

.lstGateway {
  grid-gap: 10px 15px;
  grid-gap: 10px 15px;
  gap: 10px 15px;
  margin-bottom: 25px
}

.lstGateway label {
  padding: 0 !important;
  display: block;
  width: 120px !important;
  height: 70px !important
}

.lstGateway label:after,
.lstGateway label:before {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  left: 0 !important;
  top: 0 !important;
  background: none !important;
  border: 1px solid #d1d1d1 !important
}

.lstGateway label:after {
  border: 3px solid #e20026 !important;
  position: absolute;
  z-index: 10;
  width: 116px !important;
  height: 66px !important
}

.calandar {
  /* position:absolute;*/
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  -webkit-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  -moz-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  padding: 10px 10px 20px;
  max-width: 725px;
  border-radius: 25px;
  margin-bottom: 20px;
  position: relative;
}

.calandar .contentCalandar {
  display: flex
}

.calandar .itemMonth {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 5px
}

.calandar .contentWrappMoth {
  /*border:1px solid #ebebeb*/
}

.calandar .headItem {
  text-align: center;
  font-size: 16px;
  padding: 10px 0;
  color: #014C55;
  font-weight: 600;
  font-family: Raleway
}

.calandar .wrappMonth {
  padding: 10px
}

.calandar .headMonth {
  display: flex
}

.calandar .headMonth div {
  max-width: 14.2857142857%;
  flex: 0 0 14.2857142857%;
  text-align: center;
  font-weight: normal;
  height: 40px;
  font-size: 12px;
  font-family: Raleway;
  text-transform: uppercase;
}

.calandar .bodyMonth {
  display: flex;
  flex-wrap: wrap
}

.calandar .bodyMonth .itemNumb {
  max-width: 14.2857142857%;
  flex: 0 0 14.2857142857%;
  text-align: center;
  font-size: 14px;
  padding: 2px;
  position: relative
}

.calandar .bodyMonth .itemNumb.sold:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAkJJREFUeNqsVEtIVGEU/rzem87ANFJa2cMiI6hVVquhFiFmge2CgRZRSoQbg5ZiVsuCIGbjQuixFFrZJgLdGEOYTosgqWgWMpGMVJqTozPj/H3nnnu7o6O7fvju/zrn+8/zwhiDTXCRGCUmievePOqdV8nXyKdinCZ6iSakb1xCYQ7Y1QPMPwHs3UDr8EvezRNDxJSvVEnShvxMCtnngMkDPxIBdQ0hYjv6AKuexNeA0LGTPHkv17YnJnMXFsep/EAVNhs/PeK6FiHp4uoDURJLbJi1IhbHgMx9oJBUEv/1SmvgndXFgP13gWg7z2sdIenE0ptX+HJWBWoPA43dwNpvWvVQFaM3uV8AciPriY9OAJEzF8SNJMInyOhQoAjsvQOEjwPb9qngwgvgEMlmOtZbBMqH22SRtPjpd2MhBDJCJMi9pVvflKh5AMg+A1Yn1QrfEpGXEFDfclM6eyu4LGT4CJWtCF3bDuy8rPMpChx5HcRFxmyffHstN+cHHgdmfmd2bCrlP6ol2aea0jTLJ0qXwp0qK2hxszUkFRcxpZwxKceYKSimic9xY1YyupfxKR7Mcj9tG1eP+mJJDMusmXIxiLqY23wbmEvoiznG4+AjxoVxWhrxZErAn5SsYtV1sppUoUg8UPD3y++Y6rRXJ/fo3jk+YjtiSUkKBg3nB9F4JYiNT+D7L/tyWs9ErqFjUAj8it3QO0xneQX4laguf7d3QmzPq1JL/3pn6y7+yi4usouburWLnT1bdjH+x//krwADAKCVXwEZtmV+AAAAAElFTkSuQmCC) no-repeat 50%;
  align-content: center;
  display: none !important;
}

.calandar .bodyMonth .itemNumb.locked:after,
.calandar .bodyMonth .itemNumb.sold:after {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  right: 2px;
  top: 2px;
  z-index: 10;
  display: none !important
}

.calandar .bodyMonth .itemNumb.locked:after {
  background: url(/static/media/cross.372652da.svg) no-repeat 50%;
  background-size: 9px
}

.calandar .bodyMonth .itemNumb.locked * {
  color: #bcbcbc !important;
  cursor: default !important;
  -webkit-user-select: none;
  user-select: none
}

.calandar .bodyMonth .itemNumb .content {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  min-height: 44px;
  position: relative;
  /* background:#f7f7f7;*/
  cursor: pointer;
  align-items: center;
  width: 44px;
  border-radius: 10px;
}

.calandar .bodyMonth .itemNumb .content>div {
  width: 100%;
  margin: 1px 0
}

.calandar .bodyMonth .itemNumb .content .day {
  color: #131313;
  line-height: 16px;
  font-family: Open Sans;
  font-size: 12px
}

.calandar .bodyMonth .itemNumb .content .prs {
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  display: none
}

.calandar .bodyMonth .itemNumb .content .prs.old {
  text-decoration: line-through;
  color: #bcc1d0
}

.calandar .bodyMonth .itemNumb.lastDate .content {
  background: #efefef;
  cursor: default
}

.calandar .bodyMonth .itemNumb.lastDate .content * {
  color: #E8E8E8;
  font-family: Open Sans;
}

.calandar .bodyMonth .itemNumb.active .content {
  background: linear-gradient(to bottom, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);
  color: #fff;
  z-index: 2
}

.calandar .bodyMonth .itemNumb.active .content .day {
  color: #fff
}

.calandar .bodyMonth .itemNumb.notAvalable .content {
  background: url(/static/media/cross.372652da.svg) no-repeat center 21px;
  cursor: default;
  background-size: 18px;
  background-color: #f7f7f7
}

.calandar .bodyMonth .line {
  /*  height:3px;
  background:red;
  background:var(--btn-background);
  max-width:90%*/
}

.calandar .ppPromo {
  position: absolute;
  bottom: 60px;
  width: 205px !important;
  text-align: left !important;
  border: 1px solid #e1e1e1;
  background: #f5f5f5;
  padding: 10px;
  line-height: normal;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease-in-out;
  z-index: 20;
  display: none;
}

.calandar .ppPromo h6 {
  margin: 5px 0;
  font-size: 16px
}

.calandar .ppPromo strong {
  color: #17af17
}

.calandar .ppPromo span {
  color: #000
}

.calandar .content:hover .ppPromo {
  opacity: 1;
  visibility: visible;
  bottom: 50px
}

.calandar .loading {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #efefef !important
}

.calandar .navCalandar {
  position: absolute;
  width: 100%;
  left: 0;
  top: 22px
}

.calandar .navCalandar .nextDate,
.calandar .navCalandar .prevDate {
  width: 12px;
  height: 16px;


  position: absolute;
  left: 32px;
  top: 5px;
  background: #fff url(/static/media/ico-prev.e4ba0e39.svg) no-repeat center;
  background-size: 15px;
  z-index: 10;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer
}

.calandar .navCalandar .nextDate:hover,
.calandar .navCalandar .prevDate:hover {
  opacity: .9
}

.calandar .navCalandar .nextDate {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  left: auto;
  right: 33px
}

.pointer {
  cursor: pointer
}

.contSuggest {
  position: relative;
  padding-left: 200px
}

.contSuggest .contImg {
  position: absolute;
  max-width: 190px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0
}

.contSuggest .contImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center
}

.contSuggest .lstTools .item {
  margin-bottom: 0
}

.contSuggest .condition .infoTips {
  left: auto;
  right: 0;
  max-height: 208px;
  overflow: auto;
  bottom: 23px;
  top: auto
}

.rightCol .condition .infoTips {
  left: auto;
  right: 0
}

.legCal {
  margin: 9px 0 0 28px
}

.legCal li {
  display: inline-block;
  vertical-align: top;
  margin: 0 30px 0 0;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  padding: 0 0 0 12px;
  font-family: Raleway
}

.legCal li:last-child {
  margin-right: 0
}

.legCal li:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 8px;
  background: 50% no-repeat;
  z-index: 10;
  display: block !important
}

.legCal li.sold:before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #383838;
}

.legCal li.locked:before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #E8E8E8;
}

.legCal li.dSelect:before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: linear-gradient(to bottom, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);
}

.priceChecker {
  position: fixed;
  right: 28px;
  bottom: 20px;
  width: 366px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, .12);
  background: #fff;
  z-index: 10
}

.priceChecker .head {
  background: #e20026
}

.priceChecker .head .h5 {
  margin-bottom: 11px
}

.priceChecker .head .result {
  display: block;
  margin-bottom: 0
}

.priceChecker .head .result li {
  max-width: 100%;
  flex: 0 0 100%
}

.priceChecker .head .result li:last-child {
  margin-bottom: 0
}

.priceChecker .head .result li span {
  color: #fff
}

.priceChecker .h5 {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase
}

.priceChecker .content .left,
.priceChecker .content .right {
  width: 60%;
  float: left;
  min-height: 123px
}

.priceChecker .content .left {
  border-right: 1px solid #f8f8f8;
  width: 40%
}

.priceChecker .content .left strong {
  font-size: 18px
}

.priceChecker .content .left img {
  display: block;
  margin-bottom: 10px
}

.priceChecker .content .right {
  padding-left: 18px;
  display: flex;
  align-content: center
}

.priceChecker .content .right .otherPrice {
  display: flex;
  align-content: center;
  align-items: center
}

.priceChecker .content .icon {
  color: #9da9af;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 19px
}

.priceChecker .content li {
  display: flex;
  margin-bottom: 0;
  min-height: 28px;
  align-items: center;
  justify-content: flex-start
}

.priceChecker .content li .icon {
  margin-right: 7px
}

.priceChecker .content li strong {
  font-size: 16px;
  font-weight: 700;
  position: relative;
  top: -1px
}

.priceChecker .content .contLogPrice {
  width: 87px;
  flex: 0 0 87px;
  max-width: 87px
}

.priceChecker .content .contLogPrice img {
  max-width: 80px
}

.priceChecker .foot {
  background: #f8f8f8;
  display: flex;
  padding: 6px 25px;
  align-items: center;
  justify-content: flex-end;
  color: #9b9b9b
}

.priceChecker .foot .contImg {
  background: #fff;
  padding: 3px;
  border-radius: 10px;
  width: 37px;
  height: 37px;
  margin-left: 10px
}

.priceChecker .foot .contImg img {
  max-width: 28px
}

.priceChecker .clip {
  display: none
}

.btncollaps {
  background: url(/static/media/collapse.e61cb2e6.svg);
  width: 33px;
  height: 33px;
  position: absolute;
  top: -46px;
  right: 19px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
  border-radius: 50%;
  background-size: 33px;
  cursor: pointer
}

.btncollaps:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1)
}

.btncollaps.open {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.basketMobile,
.hideBasketMobile {
  display: none
}







@media screen and (max-width:990px) {
  .calandar {
    left: 0;

  }
}

@media screen and (max-width:768px) {




  .timing {
    font-size: 18px;
    text-align: center;
    justify-content: center
  }

  .timing .h3 {
    border: 0;
    padding: 0;
    margin: 0 0 5px;
    font-size: 18px
  }

  .calandar .headItem {
    font-size: 16px;
    padding: 8px 0
  }

  .calandar .navCalandar .nextDate,
  .calandar .navCalandar .prevDate {
    width: 30px
  }

  .calandar .navCalandar .prevDate {
    left: 16px
  }

  .calandar .navCalandar .nextDate {
    right: 16px
  }

  .calandar .headMonth div {
    height: 25px
  }

  .calandar .wrappMonth {
    padding: 5px
  }

  .calandar .contentCalandar {
    flex-wrap: wrap
  }

  .calandar .itemMonth {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 5px
  }

  .calandar .itemMonth:last-child {
    display: none
  }

  .calandar .bodyMonth .itemNumb {
    padding: 4px 2px 0
  }

  .result li {
    max-width: 100%;
    flex: 0 0 100%
  }

  .infoTips {
    width: 280px
  }

  .infoTips .content {
    padding: 15px
  }


  .priceChecker {
    display: none
  }
}





@media screen and (max-width:960px) {


  footer .linkFooter,
  footer li,
  footer nav,
  footer ul {
    display: block
  }

  footer .copyright,
  footer .follow,
  footer .linkFooter,
  footer .partner {
    margin-bottom: 20px
  }

  footer .partner>div {
    text-align: center;
    display: block
  }

  footer .partner .item {
    display: inline-block
  }

  footer nav li {
    margin-bottom: 10px
  }

  footer nav a {
    margin: 0;
    display: inline-block
  }

  footer .follow a {
    vertical-align: middle;
    position: relative;
    top: -3px
  }

  footer .powered a {
    display: inline-block
  }
}



.modal.open {
  opacity: 1;
  visibility: visible
}

.modal-state:checked+.modal .modal_inner {
  top: 0
}

.modal_inner {
  transition: top .25s ease;
  top: 45px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 660px;
  margin: auto;
  position: relative
}

.popupRoomDetail {
  max-width: 90%
}

.modal_content {
  width: 100%;
  background: #fff;
  overflow: auto;
  position: relative;
  border-bottom: 6px solid red;
  border-bottom: 6px solid red;
  border-bottom: 6px solid var(--btn-background)
}

.modal_close:after,
.modal_close:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 11px;
  background: red;
  background: red;
  background: var(--btn-background);
  display: block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  margin: -3px 0 0 -1px;
  top: 6px
}

.modal_close {
  position: absolute;
  right: -18px;
  top: -21px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 2px solid red;
  border: 2px solid red;
  border: 2px solid var(--btn-background);
  border-radius: 50%
}

.modal_close:hover {
  border-color: #fff
}

.modal_close:hover:after,
.modal_close:hover:before {
  background: #fff !important
}

.modal_close:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.roomSeparator {
  background-color: hsla(0, 0%, 50.2%, .5098039215686274);
  width: calc(100% - 65px);
  height: 2px;
  margin: 40px auto
}

.bigModal .modal_content {
  overflow: scroll
}

.bigModal .modal_inner {
  max-width: 1030px
}

.bigModal .head_modal {
  padding: 40px 0 30px 47px
}

.bigModal .h5 {
  margin-bottom: 15px
}

.bigModal p {
  color: #131313;
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px
}

.bigModal .cont_modal {
  padding: 30px 0 20px 47px;
  max-height: 400px;
  overflow-y: auto
}

@media screen and (max-width:1030px) {
  .modal_inner {
    width: 90%;
    box-sizing: border-box
  }

  .bigModal .cont_modal {
    max-height: inherit;
    overflow: visible
  }

  .bigModal .modal_content {
    max-height: 520px;
    overflow-y: auto;
    height: 100%
  }
}

@media screen and (max-width:767px) {
  .modal_close {
    right: -2px;
    top: -25px
  }

  .roomSeparator {
    width: calc(100% - 35px)
  }

  .bigModal .cont_modal,
  .bigModal .head_modal {
    padding: 30px 20px
  }
}

@media screen and (min-width:767px) {
  .modal_inner {
    position: relative;
    top: 120px !important
  }
}

.pure-checkbox *,
.pure-checkbox :after,
.pure-checkbox :before,
.pure-radiobutton *,
.pure-radiobutton :after,
.pure-radiobutton :before {
  box-sizing: border-box
}

.pure-checkbox input[type=checkbox],
.pure-checkbox input[type=radio],
.pure-radiobutton input[type=checkbox],
.pure-radiobutton input[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.pure-checkbox input[type=checkbox]:focus+label:before,
.pure-checkbox input[type=checkbox]:hover+label:before,
.pure-checkbox input[type=radio]:focus+label:before,
.pure-checkbox input[type=radio]:hover+label:before,
.pure-radiobutton input[type=checkbox]:focus+label:before,
.pure-radiobutton input[type=checkbox]:hover+label:before,
.pure-radiobutton input[type=radio]:focus+label:before,
.pure-radiobutton input[type=radio]:hover+label:before {
  border-color: #e20026;
  background-color: #f2f2f2
}

.pure-checkbox input[type=checkbox]:active+label:before,
.pure-checkbox input[type=radio]:active+label:before,
.pure-radiobutton input[type=checkbox]:active+label:before,
.pure-radiobutton input[type=radio]:active+label:before {
  transition-duration: 0s
}

.pure-checkbox input[type=checkbox]+label,
.pure-checkbox input[type=radio]+label,
.pure-radiobutton input[type=checkbox]+label,
.pure-radiobutton input[type=radio]+label {
  position: relative;
  padding: 5px 5px 5px 2em;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer
}

.pure-checkbox input[type=checkbox]+label:before,
.pure-checkbox input[type=radio]+label:before,
.pure-radiobutton input[type=checkbox]+label:before,
.pure-radiobutton input[type=radio]+label:before {
  box-sizing: initial;
  content: "";
  color: #000;
  position: absolute;
  top: 50%;
  left: 0;
  width: 12px;
  height: 12px;
  margin-top: -9px;
  border: 2px solid #e20026;
  text-align: center;
  transition: all .4s ease
}

.pure-checkbox input[type=checkbox]+label:after,
.pure-checkbox input[type=radio]+label:after,
.pure-radiobutton input[type=checkbox]+label:after,
.pure-radiobutton input[type=radio]+label:after {
  box-sizing: initial;
  content: "";
  background-color: #112f41;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 8px;
  margin-top: -5px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out
}

.pure-checkbox input[type=checkbox]:disabled+label:before,
.pure-checkbox input[type=radio]:disabled+label:before,
.pure-radiobutton input[type=checkbox]:disabled+label:before,
.pure-radiobutton input[type=radio]:disabled+label:before {
  border-color: #ccc
}

.pure-checkbox input[type=checkbox]:disabled:focus+label:before,
.pure-checkbox input[type=checkbox]:disabled:hover+label:before,
.pure-checkbox input[type=radio]:disabled:focus+label:before,
.pure-checkbox input[type=radio]:disabled:hover+label:before,
.pure-radiobutton input[type=checkbox]:disabled:focus+label:before,
.pure-radiobutton input[type=checkbox]:disabled:hover+label:before,
.pure-radiobutton input[type=radio]:disabled:focus+label:before,
.pure-radiobutton input[type=radio]:disabled:hover+label:before {
  background-color: inherit
}

.pure-checkbox input[type=checkbox]:disabled:checked+label:before,
.pure-checkbox input[type=radio]:disabled:checked+label:before,
.pure-radiobutton input[type=checkbox]:disabled:checked+label:before,
.pure-radiobutton input[type=radio]:disabled:checked+label:before {
  background-color: #ccc
}

.pure-checkbox input[type=checkbox]+label:after,
.pure-radiobutton input[type=checkbox]+label:after {
  background-color: initial;
  top: 50%;
  left: 4px;
  width: 6px;
  height: 3px;
  margin-top: -5px;
  border-color: #fff;
  border-style: solid;
  border-width: 0 0 3px 3px;
  border-image: none;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0)
}

.pure-checkbox input[type=checkbox]:checked+label:after,
.pure-radiobutton input[type=checkbox]:checked+label:after {
  content: "";
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out
}

.pure-checkbox input[type=radio]:checked+label:before,
.pure-radiobutton input[type=radio]:checked+label:before {
  -webkit-animation: borderscale .3s ease-in;
  animation: borderscale .3s ease-in;
  background-color: #fff
}

.pure-checkbox input[type=radio]:checked+label:after,
.pure-radiobutton input[type=radio]:checked+label:after {
  -webkit-transform: scale(1);
  transform: scale(1)
}

.pure-checkbox input[type=radio]+label:after,
.pure-checkbox input[type=radio]+label:before,
.pure-radiobutton input[type=radio]+label:after,
.pure-radiobutton input[type=radio]+label:before {
  border-radius: 50%
}

.pure-checkbox input[type=checkbox]:checked+label:before,
.pure-radiobutton input[type=checkbox]:checked+label:before {
  -webkit-animation: borderscale .2s ease-in;
  animation: borderscale .2s ease-in;
  background: #000
}

.pure-checkbox input[type=checkbox]:checked+label:after,
.pure-radiobutton input[type=checkbox]:checked+label:after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1)
}

.pure-checkbox.pure-checkbox-inline,
.pure-checkbox.pure-radiobutton-inline,
.pure-radiobutton.pure-checkbox-inline,
.pure-radiobutton.pure-radiobutton-inline {
  display: inline-block
}

.pure-checkbox-toggle input[type=checkbox] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.pure-checkbox-toggle input[type=checkbox]+label {
  position: relative;
  padding: 5px 5px 5px 40px;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block
}

.pure-checkbox-toggle input[type=checkbox]+label:before {
  color: #e20026;
  width: 35px;
  border-radius: 50px;
  text-align: center;
  transition: all .4s;
  background-color: #d6d6d6
}

.pure-checkbox-toggle input[type=checkbox]+label:after,
.pure-checkbox-toggle input[type=checkbox]+label:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 0;
  content: "";
  height: 20px
}

.pure-checkbox-toggle input[type=checkbox]+label:after {
  background-color: #fff;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #d6d6d6;
  transition: all .2s;
  box-sizing: border-box
}

.pure-checkbox-toggle input[type=checkbox]:checked+label:before {
  box-shadow: inset 0 0 0 10px #e20026
}

.pure-checkbox-toggle input[type=checkbox]:checked+label:after {
  left: 15px;
  border-color: #e20026;
  background-color: #fff
}

.pure-checkbox-toggle input[type=checkbox]:disabled+label {
  cursor: default
}

.pure-checkbox-toggle input[type=checkbox]:disabled+label:before {
  box-shadow: inset 0 0 0 10px #e3e3e3
}

.pure-checkbox-toggle input[type=checkbox]:disabled+label:after {
  border-color: #e3e3e3
}

.pure-checkbox-toggle input[type=checkbox]:disabled:checked+label:before {
  box-shadow: inset 0 0 0 10px #999
}

.pure-checkbox-toggle input[type=checkbox]:disabled:checked+label:after {
  border-color: #999
}

.pure-checkbox-toggle--right input[type=checkbox]+label {
  padding: 5px 40px 5px 5px
}

.pure-checkbox-toggle--right input[type=checkbox]+label:before {
  left: auto;
  right: 0
}

.pure-checkbox-toggle--right input[type=checkbox]+label:after {
  left: auto;
  right: 15px
}

.pure-checkbox-toggle--right input[type=checkbox]:checked+label:after {
  left: auto;
  right: 0
}

.customCheckSite ul {
  display: flex;
  grid-gap: 20px 23px;
  grid-gap: 20px 23px;
  gap: 20px 23px;
  flex-wrap: wrap
}

.customCheckSite ul li {
  width: 100%;
  max-width: 168px;
  margin-bottom: 0
}

@media screen and (max-width:767px) {
  .customCheckSite ul li {
    max-width: inherit
  }
}

.customCheckSite label {
  position: relative;
  padding: 0 14px !important;
  text-align: left;
  min-height: 57px;
  display: flex !important;
  align-content: center;
  align-items: center;
  flex-wrap: wrap
}

.customCheckSite .pure-checkbox,
.customCheckSite .pure-radiobutton {
  margin-bottom: 0
}

.customCheckSite .pure-checkbox input[type=radio]+label,
.customCheckSite .pure-radiobutton input[type=radio]+label {
  top: 0
}

.customCheckSite .pure-checkbox input[type=radio]+label:after,
.customCheckSite .pure-checkbox input[type=radio]+label:before,
.customCheckSite .pure-radiobutton input[type=radio]+label:after,
.customCheckSite .pure-radiobutton input[type=radio]+label:before {
  width: 100%;
  height: 100%;
  border-radius: 0;
  top: 0;
  margin: 0;
  border: 1px solid #d1d1d1 !important
}

.customCheckSite .pure-checkbox input[type=radio]+label:after,
.customCheckSite .pure-radiobutton input[type=radio]+label:after {
  z-index: 0;
  left: 0;
  top: 0;
  background: #e20026 !important;
  border-color: #e20026 !important
}

.customCheckSite .pure-checkbox span,
.customCheckSite .pure-checkbox strong,
.customCheckSite .pure-radiobutton span,
.customCheckSite .pure-radiobutton strong {
  z-index: 1;
  position: relative
}

.customCheckSite .pure-checkbox strong,
.customCheckSite .pure-radiobutton strong {
  font-size: 14px;
  display: block;
  width: 100%;
  text-transform: uppercase
}

.customCheckSite .pure-checkbox span,
.customCheckSite .pure-radiobutton span {
  font-weight: 400;
  font-size: 14px;
  color: #9b9b9b;
  display: block;
  width: 100%
}

.customCheckSite .pure-checkbox input[type=radio]:checked+label span,
.customCheckSite .pure-radiobutton input[type=radio]:checked+label span {
  color: #fff !important
}

.customLangue .pure-radiobutton {
  position: relative
}

.customLangue .pure-radiobutton img {
  position: absolute;
  left: 11px;
  top: 50%;
  margin-top: -8px;
  z-index: 2
}

.customLangue .pure-radiobutton label {
  padding-left: 35px !important
}

@media screen and (max-width:767px) {
  .bigModal .cont_modal ul {
    padding-bottom: 40px
  }
}

#block-stoll-views-block-actualites-home-home-block img,
.advanceTools span,
.boxLogin,
.boxOcc,
.boxOcc .nb span,
.btn,
.btncollaps,
.form-submit,
.infoTips,
.lstNews img,
.menuMobile div,
.menuMobile div:after,
.menuMobile div:before,
.modal_close,
.modal_close:after,
.modal_close:before,
.navTop ul,
.rs li:after,
.rs li:before,
.tipContact,
.topLogo,
a,
header,
select {
  transition: all .3s
}

.doNotHide {
  display: block !important
}

.modificationTimeline {
  padding-bottom: 30px !important
}

.itinerarySeparator {
  margin-top: 40px
}

.sidebar {
  position: absolute;
  float: left
}

.boxNotification {
  padding: 10px;
  width: 230px;
  z-index: 10;
  background: hsla(0, 0%, 100%, .9)
}

.boxNotification .modal_close {
  right: -10px;
  top: -10px;
  color: red !important;
  color: red !important;
  color: var(--btn-background) !important
}

.notificationMsg li {
  padding: 10px;
  background: #efefef;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, .1)
}

.notificationMsg li:last-child {
  margin-bottom: 0
}

.notificationMsg li p {
  margin-bottom: 15px
}

.notificationMsg li p:last-child {
  margin-bottom: 0
}

.notificationMsg li.textRed {
  background: #ffc1c1;
  border-color: rgba(157, 37, 37, .5);
  color: #a54545
}

.notificationMsg li.textRed p {
  color: #a54545
}

.notificationMsg li.textYellow {
  background: #ffeca5;
  border-color: #edb055;
  color: #8b5f1e
}

.notificationMsg li.textYellow p {
  color: #8b5f1e
}

.notificationMsg li.textGreen {
  background: #b7efb3;
  border-color: #5cb356;
  color: #206c1a
}

.notificationMsg li.textGreen p {
  color: #206c1a
}

.adultIcon {
  height: 33px;
  position: relative;
  top: -4px
}

.childrenIcon {
  height: 24px;
  position: relative;
  top: -2px
}

@media screen and (min-width:768px) {
  .nightsNumInSearchResult {
    float: right;
    position: absolute;
    right: 244px;
    top: 15px
  }
}

@media screen and (max-width:767px) {
  .nightsNumInSearchResult {
    margin-left: 6px
  }
}

.infoGeneralConditionOfSale {
  max-width: 1200px;
  margin: 15px auto;
  text-align: justify
}

@media screen and (max-width:950px) {
  .infoGeneralConditionOfSale {
    margin: 15px
  }
}

.infoGeneralConditionOfSale>div {
  margin: 15px 0
}

.infoGeneralConditionOfSale>div>div:first-child {
  font-weight: 700
}

.hyperlinkAIOLIA {
  text-decoration: none !important
}

.yellowAIOLIA {
  color: #daae10
}

.boldAIOLIA {
  font-weight: 900
}

.boldSocieteGenerale {
  font-size: 18px;
  font-weight: 900
}

.h2LegalNotice {
  color: #10306e;
  font-size: 22px
}

.footerLink {
  cursor: pointer
}

.headerLegalNotice {
  color: #10306e
}

.legalNotice h2 {
  font-family: sans-serif;
  font-size: 22px
}

.listLegalNotice>li {
  margin-left: 18px;
  list-style-type: circle
}

.titleLegalNotice>h1,
.titleLegalNotice>p {
  text-align: center
}

.titleLegalNotice>p {
  position: relative;
  top: -17px
}

.ui .negative .message {
  display: none !important;
  height: 100px;
  margin-top: 100px
}

.greyedOut {
  color: #9d9d9d !important
}

.PhoneInput {
  border: 2px solid #d3d3d3;
  padding-left: 28px
}

.paymentForm {
  margin-top: 50px;
  margin-bottom: 50px
}

.cardNumber {
  border-radius: 0;
  border: 0;
  padding: 15px 26px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: "Open Sans";
  color: #131313
}

#number-container,
#securityCode-container {
  height: 50px;
  padding-left: 20px;
  padding-right: 20px
}

#expiryDate,
#securityCode-container {
  margin-top: 20px
}

#expiryDate {
  display: flex;
  justify-content: space-between
}

#expiryDate>div>select {
  width: 60px;
  margin-left: 20px
}

.leftCol.col {
  z-index: 20;
  overflow: visible;
}

.calandar::before {
  content: "";
  width: calc(100% + 60px);
  /* height: 90%; */
  position: absolute;
  top: 60px;
  bottom: 0;
  background: #fff;
  border-radius: 25px;
  left: -28px;
  overflow: hidden;
  bottom: 60px;
  z-index: -2;
  box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  -webkit-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  -moz-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
}

.calandar .bodyMonth .itemNumb .content .day:hover {
  color: #65baaf;
}

/*# sourceMappingURL=main.32177fdb.chunk.css.map */
/* Fonts */

@font-face {
    font-family: 'Raleway';
    src: url(/static/media/Raleway-Black.7fde2dcf.eot);
    src: url(/static/media/Raleway-Black.7fde2dcf.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Raleway-Black.6b398f18.woff2) format('woff2'),
        url(/static/media/Raleway-Black.6fab13b9.woff) format('woff'),
        url(/static/media/Raleway-Black.b335945e.ttf) format('truetype'),
        url(/static/media/Raleway-Black.5804545f.svg#Raleway-Black) format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url(/static/media/Raleway-Bold.c578a236.eot);
    src: url(/static/media/Raleway-Bold.c578a236.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Raleway-Bold.46f1482e.woff2) format('woff2'),
        url(/static/media/Raleway-Bold.1b2b0b42.woff) format('woff'),
        url(/static/media/Raleway-Bold.a05a3cc2.ttf) format('truetype'),
        url(/static/media/Raleway-Bold.852bbd17.svg#Raleway-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url(/static/media/Raleway-Medium.168fb1de.eot);
    src: url(/static/media/Raleway-Medium.168fb1de.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Raleway-Medium.20555db6.woff2) format('woff2'),
        url(/static/media/Raleway-Medium.7f7f3104.woff) format('woff'),
        url(/static/media/Raleway-Medium.ac624df1.ttf) format('truetype'),
        url(/static/media/Raleway-Medium.d303056b.svg#Raleway-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url(/static/media/Raleway-Light.5eb7aa4b.eot);
    src: url(/static/media/Raleway-Light.5eb7aa4b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Raleway-Light.deb32ef9.woff2) format('woff2'),
        url(/static/media/Raleway-Light.025cf6c7.woff) format('woff'),
        url(/static/media/Raleway-Light.30a541ca.ttf) format('truetype'),
        url(/static/media/Raleway-Light.b49b85ed.svg#Raleway-Light) format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url(/static/media/Raleway-SemiBold.85b1bc77.eot);
    src: url(/static/media/Raleway-SemiBold.85b1bc77.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Raleway-SemiBold.094d2649.woff2) format('woff2'),
        url(/static/media/Raleway-SemiBold.fcf45f8c.woff) format('woff'),
        url(/static/media/Raleway-SemiBold.4e63776d.ttf) format('truetype'),
        url(/static/media/Raleway-SemiBold.10aa6e3d.svg#Raleway-SemiBold) format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url(/static/media/Raleway-Regular.86fc4cf1.eot);
    src: url(/static/media/Raleway-Regular.86fc4cf1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Raleway-Regular.b72b8f98.woff2) format('woff2'),
        url(/static/media/Raleway-Regular.13efee6a.woff) format('woff'),
        url(/static/media/Raleway-Regular.bd4b0f65.ttf) format('truetype'),
        url(/static/media/Raleway-Regular.13f68257.svg#Raleway-Regular) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url(/static/media/OpenSans-Bold.42c5fdb4.eot);
    src: url(/static/media/OpenSans-Bold.42c5fdb4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/OpenSans-Bold.cab393cb.woff2) format('woff2'),
        url(/static/media/OpenSans-Bold.924a3178.woff) format('woff'),
        url(/static/media/OpenSans-Bold.61625bb3.ttf) format('truetype'),
        url(/static/media/OpenSans-Bold.5f694a0a.svg#OpenSans-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url(/static/media/OpenSans-Regular.fafb1635.eot);
    src: url(/static/media/OpenSans-Regular.fafb1635.eot?#iefix) format('embedded-opentype'),
        url(/static/media/OpenSans-Regular.0280a9c4.woff2) format('woff2'),
        url(/static/media/OpenSans-Regular.76e0416f.woff) format('woff'),
        url(/static/media/OpenSans-Regular.5efc7f29.ttf) format('truetype'),
        url(/static/media/OpenSans-Regular.6bd4242c.svg#OpenSans-Regular) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url(/static/media/OpenSans-Medium.b19f4435.eot);
    src: url(/static/media/OpenSans-Medium.b19f4435.eot?#iefix) format('embedded-opentype'),
        url(/static/media/OpenSans-Medium.373e89e1.woff2) format('woff2'),
        url(/static/media/OpenSans-Medium.abb91dab.woff) format('woff'),
        url(/static/media/OpenSans-Medium.6df36c2c.ttf) format('truetype'),
        url(/static/media/OpenSans-Medium.5099293f.svg#OpenSans-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



/* Defaults */
body,
ul,
li,
ol,
form,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
p {
    padding: 0;
    margin: 0;
    border: 0;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

input,
textarea {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    border-radius: 0;
}

label {
    margin: 0;
    padding: 0;
}

* {
    outline: none !important;
}

strong,
b {
    font-weight: normal;
}

ul {
    list-style-type: none;
}

body {
    font: normal 16px/22px 'Raleway';
    background: #ffffff;
    color: #131313
}

body * {
    box-sizing: border-box;
    outline: 0;
    transition: all 400ms ease-in-out;
}

a {
    text-decoration: none !important;
    outline: none;
    transition: all 400ms ease-in-out;
}

img {
    border: none;
}

p {
    padding-bottom: 20px;
}

*,
::before,
::after {
    box-sizing: border-box;
    transition: all ease-in-out 400ms;
}

::-webkit-input-placeholder {
    opacity: 1;
}

:-moz-placeholder {
    opacity: 1;
}

::-moz-placeholder {
    opacity: 1;
}

.flex {
    display: flex;

}

.flexBetween {
    justify-content: space-between;

}

.container,
.wrapper {
    max-width: 1400px;
    padding: 0 40px;
    margin: 0 auto;
    width: 100%;
}

header {
    background: transparent;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding: 38px 0;
    z-index: 10;
    box-shadow: none;
}

header .btn.btnSelect {
    border: 1px solid transparent;
}

main {
    position: relative;
    z-index: 1;
}

.leaderBoard {
    height: 650px;
    background-color: #6d6d6d;
    position: relative;

}

.banner {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.banner img {
    width: 100%;
    height: 100%;
    -o-object-position: center;
    -o-object-fit: cover;
    object-position: center;
    object-fit: cover;
}

.banner:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.infoBrand {
    background: rgba(255, 255, 255, .1);
    height: 100%;
    position: absolute;
    width: 400px;
    left: 0;
    top: 0;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.logo {
    width: 114px;
    height: 149px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 75px auto 0
}

header .wrapper {
    padding-left: 358px;
    max-width: 1728px
}

.blcLeft select {
    background-color: transparent;
    border: none;
    color: #fff;
    font: 600 14px/18px Raleway;
    text-transform: uppercase;
    -webkit-appearance: none;
            appearance: none;
    background: url(/static/media/select.85095aee.svg) no-repeat right center;
    cursor: pointer;
    padding-right: 20px;
    margin-right: 42px
}

header .blcLeft select option {
    color: #131313;
    font-family: Arial
}

header .blcRight .btn {
    color: #fff;
    text-transform: uppercase;
    font: 600 14px/18px Raleway;
    position: relative;
}

header .blcRight .btn i {
    font-style: normal;
}

header .blcRight .btn span {
    background: url(/static/media/search.ddb2a38e.svg);
    width: 53px;
    height: 53px;
    display: inline-block;
    vertical-align: middle;
}

header .blcRight {
    padding-top: 2px;
}

.btn.loginBox {
    margin-left: 46px;
}

.loginBox a {
    color: #fff;
    font: 600 14px/18px Raleway;
    display: block;
}

header .blcRight .btn:hover {
    color: #65BAAF
}

.loginBox a:hover {
    color: #65BAAF
}

header .blcRight .loginBox a span {
    background: url(/static/media/login.0e1e980a.svg);
    width: 53px;
    height: 53px;
    display: inline-block;
    vertical-align: middle;
}

.infoBrand ul {
    margin: 100px 30px 0 66px;
}

.infoBrand ul li a {
    color: #fff;
    font: 600 14px/18px Raleway;
    position: relative;
    padding-left: 40px;
    display: block;

}

.infoBrand ul li a:hover {
    color: #65BAAF
}

.infoBrand ul li {
    position: relative;
    color: #131313;
    margin-bottom: 30px;
    display: inline-block;
}

.infoBrand ul li.adrss span {}

.infoBrand ul li span {
    width: 29px;
    height: 29px;
    background-color: #fff;
    border-radius: 6px;
    display: block;
    position: absolute;
    left: 0;
    top: -3px
}

.infoBrand ul li.adrss span {
    background: url(/static/media/pin.9c9e6c1e.svg) no-repeat center #fff;
}

.infoBrand ul li.tel span {
    background: url(/static/media/tel.8fc841fb.svg) no-repeat center #fff;
}

.infoBrand ul li.mail span {
    background: url(/static/media/mail.e98d5cfe.svg) no-repeat center #fff;
}

.txt-banner {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;

}

.txt-banner h1 {
    font: 900 72px/82px Raleway;
    color: #fff;
    text-align: center;
}

.contentPage {
    padding-top: 100px;
    padding-bottom: 80px;
    /*background-color: #dcdcdc*/

}

.contentPage h2 {
    color: #014C55;
    font: bold 22px/22px Raleway;
    margin-bottom: 38px
}

.contentPage .col {
    max-width: 725px;

}

.contentPage .col .inner {
    padding: 20px 40px 20px;
    border-radius: 20px;
    box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    -webkit-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    -moz-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);

    background-color: #fff
}

.contentPage .col .inner h3 {
    font: bold 16px/22px Raleway;
    color: #014C55;
    margin-bottom: 25px;
}

.contentPage .col .inner h4 {
    color: #65BAAF;
    font: 600 18px/22px Raleway;
    margin-bottom: 20px
}

.ite strong {
    color: #383838;
    font: 500 14px/22px Raleway;
    padding-right: 10px;
    display: inline-block;
    vertical-align: middle;
    min-width: 90px
}

.ite span {
    display: block;
    color: #A8A8A8;
    font-size: 12px;
    line-height: 14px;
}

.ite .prix {
    border: #C8DEE1 solid 1px;
    border-radius: 10px;
    font: bold 16px/22px Open Sans;
    color: #65BAAF;
    padding: 10px 10px;
    width: 117px
}


.numbers-row .button {
    border: 1px solid #014C55;
    position: relative;
    top: -1px;
    text-align: center;
    color: #014C55;
    text-align: center;
    width: 15px;
    height: 15px;
    display: inline-block;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    vertical-align: top;
    margin-top: 8px;
    border-radius: 2px;
    position: absolute;
}

.dec.button {
    left: 0;
}

.inc.button {
    right: 0;
}

.numbers-row .button span {
    color: #014C55;
    font-family: Open sans;
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    margin: auto;
}

.numbers-row .qtt {
    width: 40px;
    height: 26px;
    background-image: none;
    background-color: transparent;
    border: 1px solid #ccc;
    font: 14px/26px dinr;
    color: #292929;
    text-align: center;
    padding: 0;
    margin-right: 6px;
    vertical-align: top;
    margin-top: 2px;

}

.powered {
    color: #131313;
    font: 16px/22px Open Sans;

}

.powered a {
    color: #33647E;
    text-transform: uppercase;
}


.numbers-row .qtt {
    border: none;
    font: bold 16px/22px Open Sans;
    color: #014C55;
}

.ite.flex {
    display: flex;
    width: 100%;
    vertical-align: middle;
    margin-bottom: 15px;
    justify-content: space-around;
}

.ite .prix {
    border: #C8DEE1 solid 1px;
    border-radius: 15px;
    font: bold 16px/27px Open Sans;
    color: #65BAAF;
    padding: 5px 10px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
}

.numbers-row {
    display: inline-block;
    border-bottom: #C8DEE1 solid 1px;
    vertical-align: middle;
    margin: 0 auto;
    width: 170px;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
}

.ite .prix sup {
    font-size: 10px;
}

.s-total {
    background-color: #E8F6F5;
    padding: 10px 20px;
    border-radius: 15px;
    margin-top: 18px;
}

.s-total span {
    display: block;
    font: bold 14px/22px Raleway;
}

.s-total .prix {
    font: bold 16px/22px open Sans;
    color: #014C55
}

.blcReservation .item {
    margin-bottom: 20px;
}

.blcReservation .item:last-child {
    margin-bottom: 18px;
}

.total {
    background-color: #33647E;
    border-radius: 15px;
    padding: 12px 45px;
    margin-bottom: 24px
}

.total span {
    display: block;
    font: bold 14px/22px Raleway;
    color: #fff;
    text-transform: uppercase;
}

.total .prix {
    font: bold 18px/22px open Sans;
    color: #65BAAF
}

.total .prix sup {
    font-size: 10px;
}

.btn-reserver {
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    font: bold 16px/19px Raleway;
    background: linear-gradient(to right, rgba(101, 186, 175, 1) 0%, rgba(51, 100, 126, 1) 100%);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: all 400ms ease-in-out;
}

.myDisable {
    background: rgb(0, 0, 0, 0.5);
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    font: bold 16px/19px Raleway;
    background: linear-gradient(to right, rgb(182, 184, 184) 0%, rgb(42, 42, 42) 100%);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    width: 100%;
    transition: all 400ms ease-in-out;
}

.btn-reserver:hover {
    background: linear-gradient(to right, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);

}

.footer {
    padding: 10px 0;
    box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    -webkit-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    -moz-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    border-bottom: #33647E solid 5px;
}

.menu-ftr li {
    display: inline-block;
    padding: 0 40px
}

.menu-ftr li a {
    color: #131313;
    text-transform: uppercase;
    font-family:
}

.menu-ftr li a:hover {
    color: #65BAAF
}

.logo-ftr a {
    margin: 0 10px;
}

.logo-ftr a img {
    transition: all 400ms ease-in-out;

}

.logo-ftr a:hover img {
    opacity: 0.5
}

.footer .container {
    align-items: center;
}


.contentPage .container {
    flex-flow: row wrap;
    align-items: baseline;
}

.contentPage .container .leftCol,
.contentPage .container .rightCol {
    width: 50%;
    padding: 0 20px
}

.blcReservation {
    max-width: 536px;
    margin: 0 auto;
}

.leftCol.col {
    z-index: 20;
    overflow: visible;
}

/* BOX LOGIN*/
header .boxLogin {
    position: absolute;
    width: 325px;
    right: -1px;
    top: 50px;
    visibility: hidden;
    opacity: 0;
    border-radius: 25px;
    box-shadow: -1px 1px 14px 0px rgba(131, 168, 190, 0.75);
    -webkit-box-shadow: -1px 1px 14px 0px rgba(131, 168, 190, 0.75);
    -moz-box-shadow: -1px 1px 14px 0px rgba(131, 168, 190, 0.75);
}

.loginBox {
    white-space: normal !important
}

header .loginBox.open .boxLogin {
    opacity: 1;
    visibility: visible;

}

.boxLogin {
    padding: 25px;
    text-transform: none;
    text-align: left;
    font-weight: 400;
    background-color: #fff
}

.boxLogin .h3 {
    margin-bottom: 12px;
    color: #014C55;
    font: bold 16px/20px Raleway;
}

.boxLogin .btn {
    margin: 10px 0 25px !important
}

.boxLogin p {
    color: #131313
}

.boxLogin a:not(.btn) {
    text-decoration: underline
}

.boxLogin a:not(.btn):hover {
    color: #131313
}

.boxLogin .linkSignup {
    margin-bottom: 5px;
    display: inline-block
}


header .boxLogin p {
    margin-bottom: 15px
}

.boxLogin .form-control {
    border: none;
    border-bottom: #C8DEE1 solid 1px;
    font-size: 14px;
    font-family: Raleway;
    font-weight: 500
}

.boxLogin .form-control::-webkit-input-placeholder {
    color: #383838 !important;
    opacity: 1 !important
}

.boxLogin .form-control::placeholder {
    color: #383838 !important;
    opacity: 1 !important
}

.boxLogin .form-control:-ms-input-placeholder {
    color: #383838 !important
}

.boxLogin .form-control::-ms-input-placeholder {
    color: #383838 !important
}

.contFormControl {
    position: relative;
    margin-bottom: 22px;
    flex: 1 1
}

.contFormControl.mail .form-control {
    background: url(/static/media/mail2.0a37bbf5.svg) no-repeat left center;
}

.contFormControl.password .form-control {
    background: url(/static/media/pass.622a350a.svg) no-repeat left center;
}

.contFormControl.password .material-symbols-rounded {
    background: url(/static/media/oeil.495265df.svg) no-repeat left center;
    width: 17px;
    height: 11px;
    background-size: cover;
}

.contFormControl .material-symbols-rounded {
    position: absolute;
    right: 8px;
    top: 20px;
}

.contFormControl .condition .material-symbols-rounded {
    position: static;
    vertical-align: top
}

.loginBox .forgotPsw {
    color: #383838;
    font: 500 14px/20px Raleway;
    text-decoration: underline !important;
    cursor: pointer;
    display: block;
    margin-bottom: 24px;
}

.loginBox .forgotPsw:hover {
    color: #65BAAF !important
}

.btn-primary {
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    font: bold 16px/19px Raleway;
    background: linear-gradient(to right, rgba(101, 186, 175, 1) 0%, rgba(51, 100, 126, 1) 100%);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: all 400ms ease-in-out;
    display: inline-block;
}

.btn-primary:hover {
    background: linear-gradient(to right, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);
    color: #fff
}

.loginBox .btn-primary {
    padding: 16px 20px;
    margin-bottom: 29px;
}

.loginBox .btn-primary:hover {
    color: #fff !important
}

.loginBox .pas-de-compte {
    color: #014C55 !important;
    font: bold 14px/20px Raleway;
    margin-bottom: 10px
}

.loginBox .pas-de-compte:hover {
    color: #65BAAF !important
}

.loginBox .inscrit a {
    text-transform: uppercase;
    color: #65BAAF;
    display: inline-block;
    vertical-align: middle;
    text-decoration: underline !important;
}

.loginBox .inscrit p {
    margin-bottom: 0;
    padding-bottom: 0;
}

/* CREATION COMPTE */
.pageInt h2 {
    text-align: center;
}

.contentPage .container .blcFormulaire {
    background-color: #fff;
    border-radius: 25px;
    max-width: 813px;
    margin: 0 auto;
    box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -webkit-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -moz-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    padding: 48px 40px 50px;
    width: 100%
}

.contentPage .container .blcFormulaire h3 {
    color: #014C55;
    font: bold 16px/22px Raleway;
    margin-bottom: 20px
}

.bloc-chp {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -15px;

}

.bloc-chp .blcChp {
    margin-bottom: 4px;
    position: relative;
}

.bloc-chp .col-50 {
    width: 50%;
    padding: 0 15px
}

.bloc-chp .col-100 {
    width: 100%;
    padding: 0 10px
}

.bloc-chp .blcChp label {
    display: block;
    color: #383838;
    font: 500 14px/22px Raleway;
    bottom: -16px;
    z-index: 20;
    position: relative;
}


.bloc-chp .blcChp .form-control {
    border: none;
    border-bottom: #C8DEE1 solid 1px;
    padding: 10px 0;
    font-size: 14px;
    font-family: Raleway
}

.bloc-chp .blcChp select {
    border-bottom: #C8DEE1 solid 1px;
    -webkit-appearance: none;
            appearance: none;
    -mz-appearance: none;
    width: 100%;
    padding: 10px 0;
    height: 50px;
    cursor: pointer;
    background: url(/static/media/select-v.1c00ec1a.svg) no-repeat right center;
    font-size: 14px;

}

.bloc-chp .blcChp .indicatif .form-control {
    padding-left: 22px !important
}

.bloc-chp .blcChp {
    position: relative;
}

.bloc-chp .blcChp span {
    position: absolute;
    bottom: -22px;
    color: #A8A8A8;
    font-size: 12px;
    left: 10px;
}

.bloc-chp .blcChp.show label {
    margin-bottom: 0;
    font-size: 12px;
    bottom: -15px
}

.info {
    margin: 0;
    color: #A8A8A8;
    font-size: 12px;
    position: absolute;
    bottom: -22px;
    color: #A8A8A8;
    font-size: 12px;
    left: 10px;
    padding-bottom: 0
}

.blcChp.tel {
    display: flex;
    flex-flow: row wrap;
}

.blcChp.tel label {
    width: 100%
}

/*.blcChp.tel .indicatif {
    width: 110px;
    position: relative;
    padding-right: 15px;
}*/

.blcChp.tel .indicatif {
    width: 80px;
    position: absolute;
    left: 15px;
    top: 22px;
}

.blcChp.tel .form-control {
    padding-left: 0px;
}

.blcChp.tel .indicatif:before {
    content: "";
    background: url(/static/media/tel2.dfb20f51.svg);
    width: 12px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 17px;
    background-size: cover
}

.mon-compte .blcChp.tel .indicatif::before {
    top: 12px
}

.blcChp.tel label {
    padding-left: 0px;
}

.blcChp.tel .indicatif::after {
    content: "";
    border-left: #C8DEE1 solid 1px;
    height: 20px;
    position: absolute;
    right: -15px;
    top: 18px;
}

.bloc-chp .blcChp .password .material-symbols-rounded {
    background: url(/static/media/oeil.495265df.svg) no-repeat left center;
    width: 17px;
    height: 11px;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 27px;
    left: auto;
}

.bloc-chp .blcChp .password {
    position: relative;
}

.bloc-chp .blcChp .btn-primary {
    margin-top: 30px;

}

.contentPage .container .blcFormulaire.blc-ok {
    text-align: center;
}

.contentPage .container .blcFormulaire.blc-ok .link {
    display: block;
}

.contentPage .container .blcFormulaire.blc-ok form {
    text-align: center;
    display: inline-block;
}

.contentPage .container .blcFormulaire.blc-ok .btn-primary {
    margin-bottom: 20px;
    padding: 16px 20px
}

.contentPage .container .blcFormulaire.blc-ok .link {
    color: #383838;
    text-transform: uppercase;
    font: 500 12px/16px Raleway;
}

.contentPage .container .blcFormulaire.blc-ok .link:hover {
    color: #65BAAF
}

.contentPage .container .blcFormulaire .ico {
    display: block;
    margin-bottom: 15px
}

.edit {
    background: url(/static/media/edit.9c964ffb.svg);
    width: 19px;
    height: 19px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.bloc-chp .blcChp .form-control.selected {
    color: #65BAAF;
    font-weight: 500;
    width: 100%
}

.mon-compte .bloc-chp .blcChp label {
    bottom: -10px
}

/*.mon-compte .bloc-chp .blcChp label.nationalite {
    bottom: -10px;
    font-size: 14px
}*/

.btn-secondary {
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    font: bold 16px/19px Raleway;
    background: #33647E;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: all 400ms ease-in-out;
    display: inline-block;
}

.btn-secondary:hover {
    background: #64b8ae
}

.mon-compte .btn-primary {
    margin-bottom: 20px
}

.mon-compte .bloc-chp .blcChp .password .material-symbols-rounded {
    top: 17px
}

.contentPage .container .blcFormulaire.blc-reservation {
    text-align: center;
}

.contentPage .container .blcFormulaire.blc-reservation p {
    color: #383838;
    margin-bottom: 40px;
    margin-top: 40px
}

/* RESERVATIONS */
.lstReservations {
    margin-top: 10px;
}

.lstReservations .item {
    width: 33.33%;
    padding: 30px 10px
}

.lstReservations .item .inner {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -webkit-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -moz-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    max-width: 396px;
    margin: 0 auto
}

.lstReservations .item .inner .titre {
    padding: 20px 44px;
    height: 84px;
    display: flex;
    align-items: center;
}

.lstReservations .item .inner .titre {
    padding: 36px 44px;
}

.lstReservations .item .inner .titre h3 {
    color: #014C55;
    font: bold 16px/22px Raleway;
}

.lstReservations .item .inner .img {
    height: 181px;
    display: block;
    overflow: hidden;
    width: 100%
}

.lstReservations .item .inner .img img {
    width: 100%;
    height: 181px;
    object-fit: cover
}

.lstReservations .item .inner .txt {
    padding: 30px 40px
}

.lstReservations .item .inner .txt h4 {
    color: #65BAAF;
    font: 600 18px/22px Raleway;
    margin-bottom: 20px;
}

.lstReservations .item .inner .txt ul {
    margin-bottom: 29px
}

.lstReservations .item .inner .txt ul li {
    padding-left: 20px;
    font-size: 14px;
    margin-bottom: 14px
}



.lstReservations .item .inner .txt ul li.adulte {
    background: url(/static/media/ico-adulte.847d9b30.svg) no-repeat left center;
}

.lstReservations .item .inner .txt ul li.enfant {
    background: url(/static/media/ico-enfant.2e08a6d5.svg) no-repeat left center;
}

.lstReservations .item .inner .txt ul li.date {
    background: url(/static/media/ico-date.c21f30cc.svg) no-repeat left center;
}

.lstReservations .item .inner .txt .total {
    background-color: #E8F6F5;
    padding: 12px 20px
}

.lstReservations .item .inner .txt .total span {
    color: #014C55
}

.lstReservations .item .inner .txt .total .prix {
    color: #014C55
}

.lstReservations .item .inner .txt ul li.date strong {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 500;
}

.slick-dots {
    text-align: center;
}

.slick-dots li {
    display: inline-block;
    margin: 0 10px;
}

.slick-dots li button {
    font-size: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #014C55;
    border: none;
    padding: 0;
    cursor: pointer
}

.slick-dots li.slick-active button {
    background-color: #72c0b5
}

/* Breadcrumb*/
.sec-breadcrumb {
    padding: 20px 0;
}

.breadcrumb nav,
.breadcrumb ul {
    display: flex;
    flex-wrap: wrap
}

.breadcrumb nav li,
.breadcrumb ul li {
    margin-bottom: 5px
}

.breadcrumb nav li:after,
.breadcrumb ul li:after {
    content: "|";
    margin: 0 20px
}

.breadcrumb nav li a,
.breadcrumb ul li a {
    color: #A8A8A8;
}

.breadcrumb nav li:last-child,
.breadcrumb nav li a:hover,
.breadcrumb ul li:last-child,
.breadcrumb ul li a:hover {
    color: #65BAAF
}

.breadcrumb nav li:last-child:after,
.breadcrumb ul li:last-child:after {
    display: none
}

/* RECHERCHE RESERVATION */
.contentPage.recherche-reservation {
    padding-top: 50px;
}

.contentBox {
    border-radius: 25px;
    box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -webkit-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -moz-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    padding: 40px;
    background-color: #fff;
    width: 50%
}

.contentBox .h3 {
    margin-bottom: 12px;
    color: #014C55;
    font: bold 16px/20px Raleway;
}

.contentBox .btn-primary {
    margin: 20px 0 !important;
    display: block;
}

.contentBox .forgotPsw {
    color: #383838;
    font: 500 14px/20px Raleway;
    text-decoration: underline !important;
    cursor: pointer;
    display: block;
    margin: 20px 0 0 10px
}

.contentBox .forgotPsw:hover {
    color: #65BAAF !important;
}

.contentBox .pas-de-compte {
    color: #014C55 !important;
    font: bold 14px/20px Raleway;
    margin: 0 0 20px;
    display: block;

}

.contentBox .pas-de-compte:hover {
    color: #65BAAF !important
}

.contentBox .inscrit a {
    text-transform: uppercase;
    color: #65BAAF;
    display: inline-block;
    vertical-align: middle;
    text-decoration: underline !important;
    font-weight: 600
}

.contentBox .inscrit p {
    margin-bottom: 0;
    padding-bottom: 0;
    color: #131313;
    font-size: 14px
}

.col2Conex {
    justify-content: space-between;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
}

.contentBox .bloc-chp {
    margin-bottom: 0
}

.contentBox .bloc-chp p {
    color: #131313;
    font-size: 14px;
}

.contentBox .bloc-chp p strong {
    font-weight: 700
}

.contentBox.numero .btn-primary {
    margin-top: 61px !important;
}

.reinit-mdp p {
    text-align: center;
    color: #131313;
    font-size: 14px
}

.contentPage .container .blcFormulaire .link {
    color: #383838;
    text-transform: uppercase;
    font: 500 12px/16px Raleway;
    text-align: center;
}

.breadcrumb nav>span {
    margin-right: 10px;
}

/* Création des réservation */
.creation-reservation .bloc-chp {
    justify-content: start;
    margin-bottom: 0
}

.blcChp.blccheck {
    padding-top: 20px;
}

.blcChp.blccheck p strong {
    color: #014C55;
    font-weight: 700;

}

.contact-form .authentication-account-access-item {
    margin-bottom: 30px;
}



/* radio */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    font-size: 14px
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #C8DEE1;
    border-radius: 3px;
    background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 18px;
    height: 13px;
    background: url(/static/media/check2.94ca4b46.svg);
    position: absolute;
    top: 2px;
    left: 1px;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


/* checkbox */

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="checkbox"]:checked+label,
[type="checkbox"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    font-size: 14px
}

[type="checkbox"]:checked+label:before,
[type="checkbox"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #C8DEE1;
    border-radius: 3px;
    background: #fff;
}

[type="checkbox"]:checked+label:after,
[type="checkbox"]:not(:checked)+label:after {
    content: '';
    width: 18px;
    height: 13px;
    background: url(/static/media/check2.94ca4b46.svg);
    position: absolute;
    top: 2px;
    left: 1px;
    transition: all 0.2s ease;
}

[type="checkbox"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="checkbox"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.input-checkbox label {
    bottom: 0 !important;
}

.blcChp.blccheck.radio .input-checkbox {
    margin-bottom: 20px
}

.contentPage.creation-reservation .container .blcFormulaire {
    margin-bottom: 40px;
}

.blcFormulaire .blc-txt h4 {
    color: #65BAAF;
    font: 600 18px/24px Raleway;
    margin-bottom: 20px
}

.blcFormulaire .blc-txt p {
    font-size: 14px;
    font-weight: 500;
    color: #383838
}

.blcFormulaire .blc-txt ul.lst-activite li {
    font-size: 14px;
    color: #383838;
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
}

.blcFormulaire .blc-txt ul li {
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
    font-size: 14px;
    color: #383838;
}

.lst-resrvation li {
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
    font-size: 14px;
    color: #383838;
}

.lst-resrvation li strong {
    font-weight: 700
}

.blcFormulaire .blc-txt ul.lst-activite li:before {
    content: "";
    background-color: #65BAAF;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 10px;
}

.lst-resrvation {
    margin-top: 20px
}

.lst-resrvation li:before {
    display: none;
}

.lst-resrvation li.adulte {
    background: url(/static/media/ico-adulte.847d9b30.svg) no-repeat left center;
}

.lst-resrvation li.enfant {
    background: url(/static/media/ico-enfant.2e08a6d5.svg) no-repeat left center;
}

.lst-resrvation li.date {
    background: url(/static/media/ico-date.c21f30cc.svg) no-repeat left center;
}

.blcFormulaire .total {
    background-color: #E8F6F5;
    padding: 15px 20px;
    width: 100%;
    margin: 20px 0;

}

.blcFormulaire .total span {
    color: #014C55;
}

.blcFormulaire .total .prix {
    color: #014C55;
}

.blcChp.blcBtn {
    text-align: center;
}

.blcChp.blcBtn .link {
    margin-top: 20px;
    color: #A8A8A8;
    text-transform: uppercase;
    display: block;
}

.blcChp.blcBtn .link:hover {
    color: #64b8ae
}

.OptionPaiement {
    display: flex;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    margin-bottom: 20px;
}

.OptionPaiement label {
    display: flex;
    justify-content: space-between;
    padding-left: 0 !important;
    font-size: 0;
    width: 120px;
    height: 70px;

}

.OptionPaiement label:after {
    background-image: none !important
}

.OptionPaiement [type="radio"]:not(:checked)+label::before {
    width: 120px;
    height: 70px;
    border: #D1D1D1 solid 1px;
    border-radius: 15px
}

.OptionPaiement [type="radio"]:checked+label::after {
    width: 120px;
    height: 70px;
    border: #65BAAF solid 2px;
    border-radius: 15px;
    background-image: none;
    top: 0
}

.OptionPaiement [type="radio"]:not(:checked)+label::before,
.OptionPaiement [type="radio"]:not(:checked)+label::before {
    border-radius: 15px
}

.OptionPaiement [type="radio"]:checked+label {
    padding-left: 0
}

.OptionPaiement [type="radio"]:checked+label::before {
    width: 120px;
    height: 70px;
    border-radius: 15px;
    left: 1px;
}

.OptionPaiement label {
    display: flex !important;
    justify-content: space-between;
    padding-left: 0 !important;
    font-size: 0;
    width: 120px;
    height: 70px;
    align-items: center;
    justify-content: center;
}

.OptionPaiement label img {
    display: block;
    z-index: 2;
}

.option-check label a {
    color: #65BAAF
}

.option-check label {
    bottom: 0 !important;
}

.txt-center {
    text-align: center;
}


.det-resa {
    border: #C8DEE1 solid 1px;
    border-radius: 15px;
    padding: 40px;
    margin-top: 20px;
    width: 100%;
    position: relative;
}

.logo-int {
    display: block;
    margin-bottom: 20px;
}

.DetLstReservation {
    margin-bottom: 20px
}

.DetLstReservation li {
    font-size: 14px;
    margin-bottom: 10px
}

.DetLstReservation li strong {
    color: #014C55;
    font-weight: 700
}

.det-resa h4 {
    color: #65BAAF;
    font-weight: 600;
    margin-bottom: 20px
}

.contTxt {
    max-width: 385px
}

.contTxt p {
    font-size: 14px;
    font-weight: 500
}

.det-resa .total {
    margin-bottom: 0;
}

.det-resa .lst-resrvation {
    margin-top: 0;
}

.creation-reservation.ok .bloc-chp {
    margin: 0;
}

.btn-primary.reverse {
    background: linear-gradient(to right, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);
}


.btn-primary.reverse:hover {
    background: linear-gradient(to right, rgba(101, 186, 175, 1) 0%, rgba(51, 100, 126, 1) 100%);
}

.blcCodeQr .ref {
    font-weight: 700;
    color: #014C55;
    display: block;
    text-align: right;
    margin-bottom: 20px
}

.blcCodeQr .date {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px
}

.det-resa .blcRight {
    position: absolute;
    right: 40px;
    top: 40px;
}

.codeQr {
    border-radius: 15px;
    border: #C8DEE1 solid 1px;
    padding: 10px;
    width: 175px;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blcCodeQr {
    display: flex;
    flex-direction: column;
    align-items: end;
}

#ppCodeConfirm {
    max-width: 550px;
    min-width: 300px;
    width: 25%;
    border-radius: 25px;
    box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -webkit-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -moz-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    padding: 2%;
    background: #fff;
}


#ppCodeConfirm h3 {
    color: #014C55;
    font-weight: 700;
    margin-bottom: 20px
}

#ppCodeConfirm p {
    font-size: 14px;
    font-weight: 500
}

#ppCodeConfirm .bloc-chp {
    /* max-width: 325px; */
    margin: 0 auto
}

.fancybox-bg {
    background: #33647E !important;

}

.bloc-chp .blcChp.date .form-control {
    background: url(/static/media/date.2150f464.svg) no-repeat right center;
    -webkit-appearance: none;
            appearance: none;
    -moz-appearance: none;
    padding: 10px 0;
    height: 50px;
    width: 100%
}

.numbers-row .button {
    border: 1px solid #014c55;
    position: relative;
    top: -8px;
    color: #014c55;
    text-align: center;
    width: 30px;
    height: 30px;
    display: inline-block;
    cursor: pointer;
    outline: none;
    background-color: initial;
    padding: 0;
    vertical-align: top;
    margin: 8px 0 0;
    border-radius: 2px;
    position: absolute
}

.numbers-row .button span {
    color: #014c55;
    font-family: Open sans;
    position: absolute;
    left: 0;
    right: 0;
    top: 7px;
    margin: auto;
    font-size: large;
}

.dec.button {
    left: 0;
    border-radius: 25%;
}

.inc.button {
    right: 0;
    border-radius: 25%;
}

.button:focus {
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #014c55;
    color: #fff
}

.button:focus span {
    color: #fff
}

/* .button:active {
    transform: scale(1.5);
} */

.contentPage {
    position: relative;
}

.ico-para {
    position: absolute;
}

.ico1 {
    left: 60px;
    top: 5%
}

.ico2 {
    left: 150px;
    top: 20%
}

.ico3 {
    right: 200px;
    top: 10%
}

.ico4 {
    right: 80px;
    top: 30%
}

.ico5 {
    left: 180px;
    top: 50%
}

.ico6 {
    right: 180px;
    top: 60%
}

.ico7 {
    left: 60px;
    top: 80%
}

.ico8 {
    right: 150px;
    top: 90%
}












@media screen and (max-width:1800px) {
    header .wrapper {
        padding-left: 440px;
    }

}

@media screen and (max-width:1280px) {
    .infoBrand ul {
        margin: 100px 30px 0 30px;
    }

    .infoBrand {
        width: 350px
    }

    header .wrapper {
        padding-left: 370px;
    }

    .txt-banner h1 {
        padding-left: 360px
    }

    .blcReservation {
        max-width: 536px;
        margin: 0 0 0 auto;
    }

    .contentPage {
        padding-top: 60px;
        padding-bottom: 60px;

    }

    .numbers-row {
        margin-right: 40px
    }

    .contentPage .container {
        padding: 0 25px
    }

}

@media screen and (max-width:1200px) {
    .numbers-row {
        width: 110px;
    }

    .calandar::before {
        width: calc(100% + 40px);
        left: -20px;

    }

    .contentPage .col .inner {
        padding: 20px
    }

    .numbers-row {
        margin-right: 35px
    }

    .menu-ftr li {
        padding: 0 10px
    }


    .ico1 {
        left: 40px;

    }

    .ico2 {
        left: 60px;

    }

    .ico3 {
        right: 60px;

    }

    .ico4 {
        right: 40px;

    }

    .ico5 {
        left: 60px;

    }

    .ico6 {
        right: 60px;

    }

    .ico7 {
        left: 20px;

    }

    .ico8 {
        right: 80px;

    }




}


@media screen and (max-width:1024px) {

    .contentPage .container .leftCol,
    .contentPage .container .rightCol {
        width: 100%;
        padding: 0;
        max-width: 100%;
    }

    .blcCalandar {
        max-width: 725px;
        margin: 0 auto 40px
    }

    .blcReservation {
        margin: 0 auto
    }

    .btn.loginBox {
        margin-left: 20px;
    }

    .blcChp.tel label {
        padding-left: 0;
    }


}

@media screen and (max-width:991px) {
    .contentPage h2 {
        margin-bottom: 20px
    }

    .calandar::before {
        display: none;

    }

    header .wrapper {
        padding-left: 0;
    }

    header {
        padding: 0
    }

    .footer .container {
        padding: 0 20px;
        justify-content: center;
        flex-direction: column;
    }

    .menu-ftr li a {
        color: #131313;
        text-transform: uppercase;
        text-align: center;
        display: block;
    }

    footer ul {
        text-align: center;
        margin-bottom: 10px
    }

    .powered {
        margin-bottom: 10px
    }

    .blcReservation {
        max-width: 725px
    }

    header .wrapper {
        padding-left: 0;
        justify-content: space-between;
        display: flex;
    }

    header .blcLeft {
        padding-left: 30px;
    }

    .leaderBoard {
        height: 500px
    }

    .txt-banner h1 {
        font-size: 50px;
        line-height: normal;
    }

    .infoBrand ul {
        margin: 60px 30px 0 30px;
    }

    .btn.loginBox {
        margin-left: 20px;
    }

    .lstReservations .item {
        padding: 30px;
    }

    .contentBox.numero .btn-primary {
        margin-top: 20px !important;
    }

    .ico1 {
        left: 20px;
    }

    .ico4 {
        right: 10px;
    }

    .ico5 {
        left: 10px;
    }




}

@media screen and (max-width:768px) {
    .infoBrand {
        width: 100%;
    }

    .infoBrand ul {
        margin: 145px 0 0 0;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        width: 100%;

    }

    .infoBrand ul li {
        text-align: center;
    }

    .infoBrand ul li {
        position: relative;
        color: #131313;
        margin-bottom: 20px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .txt-banner {
        z-index: 2
    }

    .txt-banner h1 {
        padding-left: 0;
    }

    .logo {
        margin-top: 50px
    }

    .infoBrand {
        background-color: transparent;
        backdrop-filter: inherit;
        -webkit-backdrop-filter: inherit;
    }

    header .boxLogin .h3 {
        margin-bottom: 5px;
    }

    .contFormControl.mail .form-control {
        padding-left: 25px
    }

    .lstReservations .item .inner .txt {
        padding: 30px 20px;
    }

    .lstReservations .item .inner .txt {
        padding: 30px 20px;
    }

    .col2Conex {
        flex-direction: column;
    }

    .contentBox {
        width: 100%
    }

    .OptionPaiement {
        display: flex;
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
        margin-bottom: 20px;
        flex-flow: row wrap;
        grid-row-gap: 10px;
        row-gap: 10px;
    }

    .contentPage .container .blcFormulaire {
        padding: 40px 20px 40px
    }

    .det-resa .blcRight {
        position: static;
        padding-top: 40px
    }

    .blcCodeQr {
        align-items: start;
    }

    .deco {
        display: none;
    }


}

@media screen and (max-width:600px) {
    .legCal li {
        padding-right: 20px
    }

    .contentPage .container {
        padding: 0 20px;
    }

    .ite.flex {
        flex-direction: column;
        justify-content: inherit;
    }

    .ite .prix {
        margin-top: 20px
    }

    .numbers-row {
        width: auto;
        margin: 0
    }

    .contentPage {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .footer .container {
        padding: 0 20px
    }

    header .blcRight .btn i {
        display: none;
    }

    .btn.loginBox {
        margin-left: 0;
    }

    header .blcLeft {
        padding-left: 10px;
    }

    .txt-banner h1 {
        font-size: 35px;
        line-height: normal;
    }

    .txt-banner {
        margin-top: 20px
    }

    .infoBrand ul {
        margin-top: 130px
    }

    header .blcRight .btn {
        padding: 0
    }

    header .boxLogin {
        width: 290px
    }

    .boxLogin {
        padding: 20px
    }

    .contFormControl {
        margin-bottom: 8px
    }

    .loginBox .forgotPsw {
        margin-top: 20px;
        margin-bottom: 15px
    }

    .bloc-chp .col-50 {
        width: 100%;

    }

    .contentPage .container .blcFormulaire {
        padding: 30px 20px 30px
    }

    .contentPage .container .blcFormulaire.blc-reservation {
        text-align: center;
        padding-bottom: 40px;
    }

    .contentPage .container .blcFormulaire.blc-reservation p {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .col2Conex {
        justify-content: space-between;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    .contentBox {
        padding: 20px
    }

    .contentPage.recherche-reservation {
        padding-top: 40px;
    }

    .sec-breadcrumb .container {
        padding: 0 20px;
    }

    .breadcrumb nav li::after,
    .breadcrumb ul li::after {
        margin: 0 10px
    }

    .det-resa {
        padding: 20px
    }

    #ppCodeConfirm {
        padding: 20px
    }

}

@media screen and (max-width:480px) {
    .info {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 90%;
    }


}
/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}


.view {
    width: 190px;
    height: 245px;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
}

.view .mask,
.view .content {
    width: 190px;
    height: 245px;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    box-sizing: content-box;
    padding: 15px 0 0 0;
}

.view img {
    display: block;
    position: relative;
}

.view h2 {
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0;
    color: #FFF;
}

.view p {
    position: relative;
    color: #fff;
    padding: 10px 20px 20px;
    text-align: center;
}

.view a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #343434;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0 0 1px #000;
}

.view a.info:hover {
    box-shadow: 0 0 1px #000;
    background: #000;
    border-radius: 5px;
}

.view a.info {
    background: #343434;
    transition: all 200ms;
}

.view-tenth img {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    transition: all 0.7s ease-in-out;
}

.view-tenth .mask {
    background-color: rgba(98, 35, 120, 0.8);
    transition: all 0.5s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
}

.view-tenth h2 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    margin: 20px 40px 0px 40px;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 0.5s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
}

.view-tenth p {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 0.5s linear;
}

.view-tenth a.info {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 0.5s linear;
}

.view-tenth:hover img {
    -webkit-transform: scale(10);
    transform: scale(10);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
}

.view-tenth:hover .mask {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

.view-tenth:hover h2,
.view-tenth:hover p,
.view-tenth:hover a.info {
    -webkit-transform: scale(1);
    transform: scale(1);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}
/* [Object] Modal
 * =============================== */
.modal {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: left;
    background: rgba(51, 100, 126, 0.75);
    transition: opacity 0.25s ease;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
}

.modal-state {
    display: none;
}

.modal.open {
    opacity: 1;
    visibility: visible;
}

.modal-state:checked+.modal .modal_inner {
    top: 0;
}

.modal_inner {
    transition: top 0.25s ease;
    top: 45px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    /* max-width: 660px; */
    max-width: 400px;
    margin: auto;
    position: relative;
}

.modal_inner.Three_secure_element {
    top:20px !important;
}

.popupRoomDetail {
    max-width: 90%;
}

.modal_content {
    width: 100%;
    background: #fff;
    overflow: auto;
    position: relative;
    border-bottom: 6px solid var(--btn-background);
}

.modal_close:after,
.modal_close:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 11px;
    background: var(--btn-background);
    display: block;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    left: 50%;
    margin: -3px 0 0 -1px;
    top: 6px;
}

.modal_close {
    position: absolute;
    right: -18px;
    top: -21px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 2px solid var(--btn-background);
    border-radius: 50%;
}

.modal_close:hover {
    border-color: #ffffff;
}

.modal_close:hover:after,
.modal_close:hover:before {
    background: #ffffff !important;
}

.modal_close:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
