.textError {
  font-size: 12px;
  color: #ff0000 !important;
  margin: 5px 0;
}

.textError {
  font-size: 12px;
  color: #ff0000 !important;
  margin: 5px 0;
}

.textHint {
  font-size: 12px;
  margin: 5px 0;
}

.loaderL {
  background-image: url(../src/assets/img/loading.svg) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  text-indent: 999%;
  overflow: hidden;
}

.loaderL:hover {
  cursor: default;
  background-color: var(--btn-background) !important;
}

.loaderLOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5) !important;
  background-size: 60px;
  cursor: default;
}
.loaderLOverlay:hover {
  cursor: default;
  background-color: rgba(255, 255, 255, 0.5) !important;
}