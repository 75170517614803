/* Fonts */

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Black.eot');
    src: url('../fonts/Raleway-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Black.woff2') format('woff2'),
        url('../fonts/Raleway-Black.woff') format('woff'),
        url('../fonts/Raleway-Black.ttf') format('truetype'),
        url('../fonts/Raleway-Black.svg#Raleway-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Bold.eot');
    src: url('../fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Bold.woff2') format('woff2'),
        url('../fonts/Raleway-Bold.woff') format('woff'),
        url('../fonts/Raleway-Bold.ttf') format('truetype'),
        url('../fonts/Raleway-Bold.svg#Raleway-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Medium.eot');
    src: url('../fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Medium.woff2') format('woff2'),
        url('../fonts/Raleway-Medium.woff') format('woff'),
        url('../fonts/Raleway-Medium.ttf') format('truetype'),
        url('../fonts/Raleway-Medium.svg#Raleway-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Light.eot');
    src: url('../fonts/Raleway-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Light.woff2') format('woff2'),
        url('../fonts/Raleway-Light.woff') format('woff'),
        url('../fonts/Raleway-Light.ttf') format('truetype'),
        url('../fonts/Raleway-Light.svg#Raleway-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-SemiBold.eot');
    src: url('../fonts/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-SemiBold.woff2') format('woff2'),
        url('../fonts/Raleway-SemiBold.woff') format('woff'),
        url('../fonts/Raleway-SemiBold.ttf') format('truetype'),
        url('../fonts/Raleway-SemiBold.svg#Raleway-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Regular.eot');
    src: url('../fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Regular.woff2') format('woff2'),
        url('../fonts/Raleway-Regular.woff') format('woff'),
        url('../fonts/Raleway-Regular.ttf') format('truetype'),
        url('../fonts/Raleway-Regular.svg#Raleway-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff'),
        url('../fonts/OpenSans-Bold.ttf') format('truetype'),
        url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular.woff') format('woff'),
        url('../fonts/OpenSans-Regular.ttf') format('truetype'),
        url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Medium.eot');
    src: url('../fonts/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Medium.woff2') format('woff2'),
        url('../fonts/OpenSans-Medium.woff') format('woff'),
        url('../fonts/OpenSans-Medium.ttf') format('truetype'),
        url('../fonts/OpenSans-Medium.svg#OpenSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



/* Defaults */
body,
ul,
li,
ol,
form,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
p {
    padding: 0;
    margin: 0;
    border: 0;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

input,
textarea {
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    border-radius: 0;
}

label {
    margin: 0;
    padding: 0;
}

* {
    outline: none !important;
}

strong,
b {
    font-weight: normal;
}

ul {
    list-style-type: none;
}

body {
    font: normal 16px/22px 'Raleway';
    background: #ffffff;
    color: #131313
}

body * {
    box-sizing: border-box;
    outline: 0;
    transition: all 400ms ease-in-out;
}

a {
    text-decoration: none !important;
    outline: none;
    transition: all 400ms ease-in-out;
}

img {
    border: none;
}

p {
    padding-bottom: 20px;
}

*,
::before,
::after {
    box-sizing: border-box;
    transition: all ease-in-out 400ms;
}

::-webkit-input-placeholder {
    opacity: 1;
}

:-moz-placeholder {
    opacity: 1;
}

::-moz-placeholder {
    opacity: 1;
}

.flex {
    display: flex;

}

.flexBetween {
    justify-content: space-between;

}

.container,
.wrapper {
    max-width: 1400px;
    padding: 0 40px;
    margin: 0 auto;
    width: 100%;
}

header {
    background: transparent;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding: 38px 0;
    z-index: 10;
    box-shadow: none;
}

header .btn.btnSelect {
    border: 1px solid transparent;
}

main {
    position: relative;
    z-index: 1;
}

.leaderBoard {
    height: 650px;
    background-color: #6d6d6d;
    position: relative;

}

.banner {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.banner img {
    width: 100%;
    height: 100%;
    -o-object-position: center;
    -o-object-fit: cover;
    object-position: center;
    object-fit: cover;
}

.banner:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.infoBrand {
    background: rgba(255, 255, 255, .1);
    height: 100%;
    position: absolute;
    width: 400px;
    left: 0;
    top: 0;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.logo {
    width: 114px;
    height: 149px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 75px auto 0
}

header .wrapper {
    padding-left: 358px;
    max-width: 1728px
}

.blcLeft select {
    background-color: transparent;
    border: none;
    color: #fff;
    font: 600 14px/18px Raleway;
    text-transform: uppercase;
    appearance: none;
    background: url(../images/select.svg) no-repeat right center;
    cursor: pointer;
    padding-right: 20px;
    margin-right: 42px
}

header .blcLeft select option {
    color: #131313;
    font-family: Arial
}

header .blcRight .btn {
    color: #fff;
    text-transform: uppercase;
    font: 600 14px/18px Raleway;
    position: relative;
}

header .blcRight .btn i {
    font-style: normal;
}

header .blcRight .btn span {
    background: url(../images/search.svg);
    width: 53px;
    height: 53px;
    display: inline-block;
    vertical-align: middle;
}

header .blcRight {
    padding-top: 2px;
}

.btn.loginBox {
    margin-left: 46px;
}

.loginBox a {
    color: #fff;
    font: 600 14px/18px Raleway;
    display: block;
}

header .blcRight .btn:hover {
    color: #65BAAF
}

.loginBox a:hover {
    color: #65BAAF
}

header .blcRight .loginBox a span {
    background: url(../images/login.svg);
    width: 53px;
    height: 53px;
    display: inline-block;
    vertical-align: middle;
}

.infoBrand ul {
    margin: 100px 30px 0 66px;
}

.infoBrand ul li a {
    color: #fff;
    font: 600 14px/18px Raleway;
    position: relative;
    padding-left: 40px;
    display: block;

}

.infoBrand ul li a:hover {
    color: #65BAAF
}

.infoBrand ul li {
    position: relative;
    color: #131313;
    margin-bottom: 30px;
    display: inline-block;
}

.infoBrand ul li.adrss span {}

.infoBrand ul li span {
    width: 29px;
    height: 29px;
    background-color: #fff;
    border-radius: 6px;
    display: block;
    position: absolute;
    left: 0;
    top: -3px
}

.infoBrand ul li.adrss span {
    background: url(../images/pin.svg) no-repeat center #fff;
}

.infoBrand ul li.tel span {
    background: url(../images/tel.svg) no-repeat center #fff;
}

.infoBrand ul li.mail span {
    background: url(../images/mail.svg) no-repeat center #fff;
}

.txt-banner {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;

}

.txt-banner h1 {
    font: 900 72px/82px Raleway;
    color: #fff;
    text-align: center;
}

.contentPage {
    padding-top: 100px;
    padding-bottom: 80px;
    /*background-color: #dcdcdc*/

}

.contentPage h2 {
    color: #014C55;
    font: bold 22px/22px Raleway;
    margin-bottom: 38px
}

.contentPage .col {
    max-width: 725px;

}

.contentPage .col .inner {
    padding: 20px 40px 20px;
    border-radius: 20px;
    box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    -webkit-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    -moz-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);

    background-color: #fff
}

.contentPage .col .inner h3 {
    font: bold 16px/22px Raleway;
    color: #014C55;
    margin-bottom: 25px;
}

.contentPage .col .inner h4 {
    color: #65BAAF;
    font: 600 18px/22px Raleway;
    margin-bottom: 20px
}

.ite strong {
    color: #383838;
    font: 500 14px/22px Raleway;
    padding-right: 10px;
    display: inline-block;
    vertical-align: middle;
    min-width: 90px
}

.ite span {
    display: block;
    color: #A8A8A8;
    font-size: 12px;
    line-height: 14px;
}

.ite .prix {
    border: #C8DEE1 solid 1px;
    border-radius: 10px;
    font: bold 16px/22px Open Sans;
    color: #65BAAF;
    padding: 10px 10px;
    width: 117px
}


.numbers-row .button {
    border: 1px solid #014C55;
    position: relative;
    top: -1px;
    text-align: center;
    color: #014C55;
    text-align: center;
    width: 15px;
    height: 15px;
    display: inline-block;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    vertical-align: top;
    margin-top: 8px;
    border-radius: 2px;
    position: absolute;
}

.dec.button {
    left: 0;
}

.inc.button {
    right: 0;
}

.numbers-row .button span {
    color: #014C55;
    font-family: Open sans;
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    margin: auto;
}

.numbers-row .qtt {
    width: 40px;
    height: 26px;
    background-image: none;
    background-color: transparent;
    border: 1px solid #ccc;
    font: 14px/26px dinr;
    color: #292929;
    text-align: center;
    padding: 0;
    margin-right: 6px;
    vertical-align: top;
    margin-top: 2px;

}

.powered {
    color: #131313;
    font: 16px/22px Open Sans;

}

.powered a {
    color: #33647E;
    text-transform: uppercase;
}


.numbers-row .qtt {
    border: none;
    font: bold 16px/22px Open Sans;
    color: #014C55;
}

.ite.flex {
    display: flex;
    width: 100%;
    vertical-align: middle;
    margin-bottom: 15px;
    justify-content: space-around;
}

.ite .prix {
    border: #C8DEE1 solid 1px;
    border-radius: 15px;
    font: bold 16px/27px Open Sans;
    color: #65BAAF;
    padding: 5px 10px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
}

.numbers-row {
    display: inline-block;
    border-bottom: #C8DEE1 solid 1px;
    vertical-align: middle;
    margin: 0 auto;
    width: 170px;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
}

.ite .prix sup {
    font-size: 10px;
}

.s-total {
    background-color: #E8F6F5;
    padding: 10px 20px;
    border-radius: 15px;
    margin-top: 18px;
}

.s-total span {
    display: block;
    font: bold 14px/22px Raleway;
}

.s-total .prix {
    font: bold 16px/22px open Sans;
    color: #014C55
}

.blcReservation .item {
    margin-bottom: 20px;
}

.blcReservation .item:last-child {
    margin-bottom: 18px;
}

.total {
    background-color: #33647E;
    border-radius: 15px;
    padding: 12px 45px;
    margin-bottom: 24px
}

.total span {
    display: block;
    font: bold 14px/22px Raleway;
    color: #fff;
    text-transform: uppercase;
}

.total .prix {
    font: bold 18px/22px open Sans;
    color: #65BAAF
}

.total .prix sup {
    font-size: 10px;
}

.btn-reserver {
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    font: bold 16px/19px Raleway;
    background: linear-gradient(to right, rgba(101, 186, 175, 1) 0%, rgba(51, 100, 126, 1) 100%);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: all 400ms ease-in-out;
}

.myDisable {
    background: rgb(0, 0, 0, 0.5);
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    font: bold 16px/19px Raleway;
    background: linear-gradient(to right, rgb(182, 184, 184) 0%, rgb(42, 42, 42) 100%);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    width: 100%;
    transition: all 400ms ease-in-out;
}

.btn-reserver:hover {
    background: linear-gradient(to right, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);

}

.footer {
    padding: 10px 0;
    box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    -webkit-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    -moz-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
    border-bottom: #33647E solid 5px;
}

.menu-ftr li {
    display: inline-block;
    padding: 0 40px
}

.menu-ftr li a {
    color: #131313;
    text-transform: uppercase;
    font-family:
}

.menu-ftr li a:hover {
    color: #65BAAF
}

.logo-ftr a {
    margin: 0 10px;
}

.logo-ftr a img {
    transition: all 400ms ease-in-out;

}

.logo-ftr a:hover img {
    opacity: 0.5
}

.footer .container {
    align-items: center;
}


.contentPage .container {
    flex-flow: row wrap;
    align-items: baseline;
}

.contentPage .container .leftCol,
.contentPage .container .rightCol {
    width: 50%;
    padding: 0 20px
}

.blcReservation {
    max-width: 536px;
    margin: 0 auto;
}

.leftCol.col {
    z-index: 20;
    overflow: visible;
}

/* BOX LOGIN*/
header .boxLogin {
    position: absolute;
    width: 325px;
    right: -1px;
    top: 50px;
    visibility: hidden;
    opacity: 0;
    border-radius: 25px;
    box-shadow: -1px 1px 14px 0px rgba(131, 168, 190, 0.75);
    -webkit-box-shadow: -1px 1px 14px 0px rgba(131, 168, 190, 0.75);
    -moz-box-shadow: -1px 1px 14px 0px rgba(131, 168, 190, 0.75);
}

.loginBox {
    white-space: normal !important
}

header .loginBox.open .boxLogin {
    opacity: 1;
    visibility: visible;

}

.boxLogin {
    padding: 25px;
    text-transform: none;
    text-align: left;
    font-weight: 400;
    background-color: #fff
}

.boxLogin .h3 {
    margin-bottom: 12px;
    color: #014C55;
    font: bold 16px/20px Raleway;
}

.boxLogin .btn {
    margin: 10px 0 25px !important
}

.boxLogin p {
    color: #131313
}

.boxLogin a:not(.btn) {
    text-decoration: underline
}

.boxLogin a:not(.btn):hover {
    color: #131313
}

.boxLogin .linkSignup {
    margin-bottom: 5px;
    display: inline-block
}


header .boxLogin p {
    margin-bottom: 15px
}

.boxLogin .form-control {
    border: none;
    border-bottom: #C8DEE1 solid 1px;
    font-size: 14px;
    font-family: Raleway;
    font-weight: 500
}

.boxLogin .form-control::-webkit-input-placeholder {
    color: #383838 !important;
    opacity: 1 !important
}

.boxLogin .form-control::placeholder {
    color: #383838 !important;
    opacity: 1 !important
}

.boxLogin .form-control:-ms-input-placeholder {
    color: #383838 !important
}

.boxLogin .form-control::-ms-input-placeholder {
    color: #383838 !important
}

.contFormControl {
    position: relative;
    margin-bottom: 22px;
    flex: 1 1
}

.contFormControl.mail .form-control {
    background: url(../images/mail2.svg) no-repeat left center;
}

.contFormControl.password .form-control {
    background: url(../images/pass.svg) no-repeat left center;
}

.contFormControl.password .material-symbols-rounded {
    background: url(../images/oeil.svg) no-repeat left center;
    width: 17px;
    height: 11px;
    background-size: cover;
}

.contFormControl .material-symbols-rounded {
    position: absolute;
    right: 8px;
    top: 20px;
}

.contFormControl .condition .material-symbols-rounded {
    position: static;
    vertical-align: top
}

.loginBox .forgotPsw {
    color: #383838;
    font: 500 14px/20px Raleway;
    text-decoration: underline !important;
    cursor: pointer;
    display: block;
    margin-bottom: 24px;
}

.loginBox .forgotPsw:hover {
    color: #65BAAF !important
}

.btn-primary {
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    font: bold 16px/19px Raleway;
    background: linear-gradient(to right, rgba(101, 186, 175, 1) 0%, rgba(51, 100, 126, 1) 100%);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: all 400ms ease-in-out;
    display: inline-block;
}

.btn-primary:hover {
    background: linear-gradient(to right, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);
    color: #fff
}

.loginBox .btn-primary {
    padding: 16px 20px;
    margin-bottom: 29px;
}

.loginBox .btn-primary:hover {
    color: #fff !important
}

.loginBox .pas-de-compte {
    color: #014C55 !important;
    font: bold 14px/20px Raleway;
    margin-bottom: 10px
}

.loginBox .pas-de-compte:hover {
    color: #65BAAF !important
}

.loginBox .inscrit a {
    text-transform: uppercase;
    color: #65BAAF;
    display: inline-block;
    vertical-align: middle;
    text-decoration: underline !important;
}

.loginBox .inscrit p {
    margin-bottom: 0;
    padding-bottom: 0;
}

/* CREATION COMPTE */
.pageInt h2 {
    text-align: center;
}

.contentPage .container .blcFormulaire {
    background-color: #fff;
    border-radius: 25px;
    max-width: 813px;
    margin: 0 auto;
    box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -webkit-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -moz-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    padding: 48px 40px 50px;
    width: 100%
}

.contentPage .container .blcFormulaire h3 {
    color: #014C55;
    font: bold 16px/22px Raleway;
    margin-bottom: 20px
}

.bloc-chp {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -15px;

}

.bloc-chp .blcChp {
    margin-bottom: 4px;
    position: relative;
}

.bloc-chp .col-50 {
    width: 50%;
    padding: 0 15px
}

.bloc-chp .col-100 {
    width: 100%;
    padding: 0 10px
}

.bloc-chp .blcChp label {
    display: block;
    color: #383838;
    font: 500 14px/22px Raleway;
    bottom: -16px;
    z-index: 20;
    position: relative;
}


.bloc-chp .blcChp .form-control {
    border: none;
    border-bottom: #C8DEE1 solid 1px;
    padding: 10px 0;
    font-size: 14px;
    font-family: Raleway
}

.bloc-chp .blcChp select {
    border-bottom: #C8DEE1 solid 1px;
    appearance: none;
    -mz-appearance: none;
    width: 100%;
    padding: 10px 0;
    height: 50px;
    cursor: pointer;
    background: url(../images/select-v.svg) no-repeat right center;
    font-size: 14px;

}

.bloc-chp .blcChp .indicatif .form-control {
    padding-left: 22px !important
}

.bloc-chp .blcChp {
    position: relative;
}

.bloc-chp .blcChp span {
    position: absolute;
    bottom: -22px;
    color: #A8A8A8;
    font-size: 12px;
    left: 10px;
}

.bloc-chp .blcChp.show label {
    margin-bottom: 0;
    font-size: 12px;
    bottom: -15px
}

.info {
    margin: 0;
    color: #A8A8A8;
    font-size: 12px;
    position: absolute;
    bottom: -22px;
    color: #A8A8A8;
    font-size: 12px;
    left: 10px;
    padding-bottom: 0
}

.blcChp.tel {
    display: flex;
    flex-flow: row wrap;
}

.blcChp.tel label {
    width: 100%
}

/*.blcChp.tel .indicatif {
    width: 110px;
    position: relative;
    padding-right: 15px;
}*/

.blcChp.tel .indicatif {
    width: 80px;
    position: absolute;
    left: 15px;
    top: 22px;
}

.blcChp.tel .form-control {
    padding-left: 0px;
}

.blcChp.tel .indicatif:before {
    content: "";
    background: url(../images/tel2.svg);
    width: 12px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 17px;
    background-size: cover
}

.mon-compte .blcChp.tel .indicatif::before {
    top: 12px
}

.blcChp.tel label {
    padding-left: 0px;
}

.blcChp.tel .indicatif::after {
    content: "";
    border-left: #C8DEE1 solid 1px;
    height: 20px;
    position: absolute;
    right: -15px;
    top: 18px;
}

.bloc-chp .blcChp .password .material-symbols-rounded {
    background: url(../images/oeil.svg) no-repeat left center;
    width: 17px;
    height: 11px;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 27px;
    left: auto;
}

.bloc-chp .blcChp .password {
    position: relative;
}

.bloc-chp .blcChp .btn-primary {
    margin-top: 30px;

}

.contentPage .container .blcFormulaire.blc-ok {
    text-align: center;
}

.contentPage .container .blcFormulaire.blc-ok .link {
    display: block;
}

.contentPage .container .blcFormulaire.blc-ok form {
    text-align: center;
    display: inline-block;
}

.contentPage .container .blcFormulaire.blc-ok .btn-primary {
    margin-bottom: 20px;
    padding: 16px 20px
}

.contentPage .container .blcFormulaire.blc-ok .link {
    color: #383838;
    text-transform: uppercase;
    font: 500 12px/16px Raleway;
}

.contentPage .container .blcFormulaire.blc-ok .link:hover {
    color: #65BAAF
}

.contentPage .container .blcFormulaire .ico {
    display: block;
    margin-bottom: 15px
}

.edit {
    background: url(../images/edit.svg);
    width: 19px;
    height: 19px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.bloc-chp .blcChp .form-control.selected {
    color: #65BAAF;
    font-weight: 500;
    width: 100%
}

.mon-compte .bloc-chp .blcChp label {
    bottom: -10px
}

/*.mon-compte .bloc-chp .blcChp label.nationalite {
    bottom: -10px;
    font-size: 14px
}*/

.btn-secondary {
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    font: bold 16px/19px Raleway;
    background: #33647E;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: all 400ms ease-in-out;
    display: inline-block;
}

.btn-secondary:hover {
    background: #64b8ae
}

.mon-compte .btn-primary {
    margin-bottom: 20px
}

.mon-compte .bloc-chp .blcChp .password .material-symbols-rounded {
    top: 17px
}

.contentPage .container .blcFormulaire.blc-reservation {
    text-align: center;
}

.contentPage .container .blcFormulaire.blc-reservation p {
    color: #383838;
    margin-bottom: 40px;
    margin-top: 40px
}

/* RESERVATIONS */
.lstReservations {
    margin-top: 10px;
}

.lstReservations .item {
    width: 33.33%;
    padding: 30px 10px
}

.lstReservations .item .inner {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -webkit-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -moz-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    max-width: 396px;
    margin: 0 auto
}

.lstReservations .item .inner .titre {
    padding: 20px 44px;
    height: 84px;
    display: flex;
    align-items: center;
}

.lstReservations .item .inner .titre {
    padding: 36px 44px;
}

.lstReservations .item .inner .titre h3 {
    color: #014C55;
    font: bold 16px/22px Raleway;
}

.lstReservations .item .inner .img {
    height: 181px;
    display: block;
    overflow: hidden;
    width: 100%
}

.lstReservations .item .inner .img img {
    width: 100%;
    height: 181px;
    object-fit: cover
}

.lstReservations .item .inner .txt {
    padding: 30px 40px
}

.lstReservations .item .inner .txt h4 {
    color: #65BAAF;
    font: 600 18px/22px Raleway;
    margin-bottom: 20px;
}

.lstReservations .item .inner .txt ul {
    margin-bottom: 29px
}

.lstReservations .item .inner .txt ul li {
    padding-left: 20px;
    font-size: 14px;
    margin-bottom: 14px
}



.lstReservations .item .inner .txt ul li.adulte {
    background: url(../images/ico-adulte.svg) no-repeat left center;
}

.lstReservations .item .inner .txt ul li.enfant {
    background: url(../images/ico-enfant.svg) no-repeat left center;
}

.lstReservations .item .inner .txt ul li.date {
    background: url(../images/ico-date.svg) no-repeat left center;
}

.lstReservations .item .inner .txt .total {
    background-color: #E8F6F5;
    padding: 12px 20px
}

.lstReservations .item .inner .txt .total span {
    color: #014C55
}

.lstReservations .item .inner .txt .total .prix {
    color: #014C55
}

.lstReservations .item .inner .txt ul li.date strong {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 500;
}

.slick-dots {
    text-align: center;
}

.slick-dots li {
    display: inline-block;
    margin: 0 10px;
}

.slick-dots li button {
    font-size: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #014C55;
    border: none;
    padding: 0;
    cursor: pointer
}

.slick-dots li.slick-active button {
    background-color: #72c0b5
}

/* Breadcrumb*/
.sec-breadcrumb {
    padding: 20px 0;
}

.breadcrumb nav,
.breadcrumb ul {
    display: flex;
    flex-wrap: wrap
}

.breadcrumb nav li,
.breadcrumb ul li {
    margin-bottom: 5px
}

.breadcrumb nav li:after,
.breadcrumb ul li:after {
    content: "|";
    margin: 0 20px
}

.breadcrumb nav li a,
.breadcrumb ul li a {
    color: #A8A8A8;
}

.breadcrumb nav li:last-child,
.breadcrumb nav li a:hover,
.breadcrumb ul li:last-child,
.breadcrumb ul li a:hover {
    color: #65BAAF
}

.breadcrumb nav li:last-child:after,
.breadcrumb ul li:last-child:after {
    display: none
}

/* RECHERCHE RESERVATION */
.contentPage.recherche-reservation {
    padding-top: 50px;
}

.contentBox {
    border-radius: 25px;
    box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -webkit-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -moz-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    padding: 40px;
    background-color: #fff;
    width: 50%
}

.contentBox .h3 {
    margin-bottom: 12px;
    color: #014C55;
    font: bold 16px/20px Raleway;
}

.contentBox .btn-primary {
    margin: 20px 0 !important;
    display: block;
}

.contentBox .forgotPsw {
    color: #383838;
    font: 500 14px/20px Raleway;
    text-decoration: underline !important;
    cursor: pointer;
    display: block;
    margin: 20px 0 0 10px
}

.contentBox .forgotPsw:hover {
    color: #65BAAF !important;
}

.contentBox .pas-de-compte {
    color: #014C55 !important;
    font: bold 14px/20px Raleway;
    margin: 0 0 20px;
    display: block;

}

.contentBox .pas-de-compte:hover {
    color: #65BAAF !important
}

.contentBox .inscrit a {
    text-transform: uppercase;
    color: #65BAAF;
    display: inline-block;
    vertical-align: middle;
    text-decoration: underline !important;
    font-weight: 600
}

.contentBox .inscrit p {
    margin-bottom: 0;
    padding-bottom: 0;
    color: #131313;
    font-size: 14px
}

.col2Conex {
    justify-content: space-between;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
}

.contentBox .bloc-chp {
    margin-bottom: 0
}

.contentBox .bloc-chp p {
    color: #131313;
    font-size: 14px;
}

.contentBox .bloc-chp p strong {
    font-weight: 700
}

.contentBox.numero .btn-primary {
    margin-top: 61px !important;
}

.reinit-mdp p {
    text-align: center;
    color: #131313;
    font-size: 14px
}

.contentPage .container .blcFormulaire .link {
    color: #383838;
    text-transform: uppercase;
    font: 500 12px/16px Raleway;
    text-align: center;
}

.breadcrumb nav>span {
    margin-right: 10px;
}

/* Création des réservation */
.creation-reservation .bloc-chp {
    justify-content: start;
    margin-bottom: 0
}

.blcChp.blccheck {
    padding-top: 20px;
}

.blcChp.blccheck p strong {
    color: #014C55;
    font-weight: 700;

}

.contact-form .authentication-account-access-item {
    margin-bottom: 30px;
}



/* radio */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    font-size: 14px
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #C8DEE1;
    border-radius: 3px;
    background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 18px;
    height: 13px;
    background: url(../images/check2.svg);
    position: absolute;
    top: 2px;
    left: 1px;

    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


/* checkbox */

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="checkbox"]:checked+label,
[type="checkbox"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    font-size: 14px
}

[type="checkbox"]:checked+label:before,
[type="checkbox"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #C8DEE1;
    border-radius: 3px;
    background: #fff;
}

[type="checkbox"]:checked+label:after,
[type="checkbox"]:not(:checked)+label:after {
    content: '';
    width: 18px;
    height: 13px;
    background: url(../images/check2.svg);
    position: absolute;
    top: 2px;
    left: 1px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="checkbox"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="checkbox"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.input-checkbox label {
    bottom: 0 !important;
}

.blcChp.blccheck.radio .input-checkbox {
    margin-bottom: 20px
}

.contentPage.creation-reservation .container .blcFormulaire {
    margin-bottom: 40px;
}

.blcFormulaire .blc-txt h4 {
    color: #65BAAF;
    font: 600 18px/24px Raleway;
    margin-bottom: 20px
}

.blcFormulaire .blc-txt p {
    font-size: 14px;
    font-weight: 500;
    color: #383838
}

.blcFormulaire .blc-txt ul.lst-activite li {
    font-size: 14px;
    color: #383838;
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
}

.blcFormulaire .blc-txt ul li {
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
    font-size: 14px;
    color: #383838;
}

.lst-resrvation li {
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
    font-size: 14px;
    color: #383838;
}

.lst-resrvation li strong {
    font-weight: 700
}

.blcFormulaire .blc-txt ul.lst-activite li:before {
    content: "";
    background-color: #65BAAF;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 10px;
}

.lst-resrvation {
    margin-top: 20px
}

.lst-resrvation li:before {
    display: none;
}

.lst-resrvation li.adulte {
    background: url(../images/ico-adulte.svg) no-repeat left center;
}

.lst-resrvation li.enfant {
    background: url(../images/ico-enfant.svg) no-repeat left center;
}

.lst-resrvation li.date {
    background: url(../images/ico-date.svg) no-repeat left center;
}

.blcFormulaire .total {
    background-color: #E8F6F5;
    padding: 15px 20px;
    width: 100%;
    margin: 20px 0;

}

.blcFormulaire .total span {
    color: #014C55;
}

.blcFormulaire .total .prix {
    color: #014C55;
}

.blcChp.blcBtn {
    text-align: center;
}

.blcChp.blcBtn .link {
    margin-top: 20px;
    color: #A8A8A8;
    text-transform: uppercase;
    display: block;
}

.blcChp.blcBtn .link:hover {
    color: #64b8ae
}

.OptionPaiement {
    display: flex;
    column-gap: 20px;
    margin-bottom: 20px;
}

.OptionPaiement label {
    display: flex;
    justify-content: space-between;
    padding-left: 0 !important;
    font-size: 0;
    width: 120px;
    height: 70px;

}

.OptionPaiement label:after {
    background-image: none !important
}

.OptionPaiement [type="radio"]:not(:checked)+label::before {
    width: 120px;
    height: 70px;
    border: #D1D1D1 solid 1px;
    border-radius: 15px
}

.OptionPaiement [type="radio"]:checked+label::after {
    width: 120px;
    height: 70px;
    border: #65BAAF solid 2px;
    border-radius: 15px;
    background-image: none;
    top: 0
}

.OptionPaiement [type="radio"]:not(:checked)+label::before,
.OptionPaiement [type="radio"]:not(:checked)+label::before {
    border-radius: 15px
}

.OptionPaiement [type="radio"]:checked+label {
    padding-left: 0
}

.OptionPaiement [type="radio"]:checked+label::before {
    width: 120px;
    height: 70px;
    border-radius: 15px;
    left: 1px;
}

.OptionPaiement label {
    display: flex !important;
    justify-content: space-between;
    padding-left: 0 !important;
    font-size: 0;
    width: 120px;
    height: 70px;
    align-items: center;
    justify-content: center;
}

.OptionPaiement label img {
    display: block;
    z-index: 2;
}

.option-check label a {
    color: #65BAAF
}

.option-check label {
    bottom: 0 !important;
}

.txt-center {
    text-align: center;
}


.det-resa {
    border: #C8DEE1 solid 1px;
    border-radius: 15px;
    padding: 40px;
    margin-top: 20px;
    width: 100%;
    position: relative;
}

.logo-int {
    display: block;
    margin-bottom: 20px;
}

.DetLstReservation {
    margin-bottom: 20px
}

.DetLstReservation li {
    font-size: 14px;
    margin-bottom: 10px
}

.DetLstReservation li strong {
    color: #014C55;
    font-weight: 700
}

.det-resa h4 {
    color: #65BAAF;
    font-weight: 600;
    margin-bottom: 20px
}

.contTxt {
    max-width: 385px
}

.contTxt p {
    font-size: 14px;
    font-weight: 500
}

.det-resa .total {
    margin-bottom: 0;
}

.det-resa .lst-resrvation {
    margin-top: 0;
}

.creation-reservation.ok .bloc-chp {
    margin: 0;
}

.btn-primary.reverse {
    background: linear-gradient(to right, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);
}


.btn-primary.reverse:hover {
    background: linear-gradient(to right, rgba(101, 186, 175, 1) 0%, rgba(51, 100, 126, 1) 100%);
}

.blcCodeQr .ref {
    font-weight: 700;
    color: #014C55;
    display: block;
    text-align: right;
    margin-bottom: 20px
}

.blcCodeQr .date {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px
}

.det-resa .blcRight {
    position: absolute;
    right: 40px;
    top: 40px;
}

.codeQr {
    border-radius: 15px;
    border: #C8DEE1 solid 1px;
    padding: 10px;
    width: 175px;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blcCodeQr {
    display: flex;
    flex-direction: column;
    align-items: end;
}

#ppCodeConfirm {
    max-width: 550px;
    min-width: 300px;
    width: 25%;
    border-radius: 25px;
    box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -webkit-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    -moz-box-shadow: -1px 1px 32px 5px rgba(221, 240, 251, 0.81);
    padding: 2%;
    background: #fff;
}


#ppCodeConfirm h3 {
    color: #014C55;
    font-weight: 700;
    margin-bottom: 20px
}

#ppCodeConfirm p {
    font-size: 14px;
    font-weight: 500
}

#ppCodeConfirm .bloc-chp {
    /* max-width: 325px; */
    margin: 0 auto
}

.fancybox-bg {
    background: #33647E !important;

}

.bloc-chp .blcChp.date .form-control {
    background: url(../images/date.svg) no-repeat right center;
    appearance: none;
    -moz-appearance: none;
    padding: 10px 0;
    height: 50px;
    width: 100%
}

.numbers-row .button {
    border: 1px solid #014c55;
    position: relative;
    top: -8px;
    color: #014c55;
    text-align: center;
    width: 30px;
    height: 30px;
    display: inline-block;
    cursor: pointer;
    outline: none;
    background-color: initial;
    padding: 0;
    vertical-align: top;
    margin: 8px 0 0;
    border-radius: 2px;
    position: absolute
}

.numbers-row .button span {
    color: #014c55;
    font-family: Open sans;
    position: absolute;
    left: 0;
    right: 0;
    top: 7px;
    margin: auto;
    font-size: large;
}

.dec.button {
    left: 0;
    border-radius: 25%;
}

.inc.button {
    right: 0;
    border-radius: 25%;
}

.button:focus {
    -webkit-animation-delay: 500ms;
    -moz-animation-delay: 500ms;
    -ms-animation-delay: 500ms;
    -o-animation-delay: 500ms;
    animation-delay: 500ms;
    transform: scale(1.1);
    background-color: #014c55;
    color: #fff
}

.button:focus span {
    color: #fff
}

/* .button:active {
    transform: scale(1.5);
} */

.contentPage {
    position: relative;
}

.ico-para {
    position: absolute;
}

.ico1 {
    left: 60px;
    top: 5%
}

.ico2 {
    left: 150px;
    top: 20%
}

.ico3 {
    right: 200px;
    top: 10%
}

.ico4 {
    right: 80px;
    top: 30%
}

.ico5 {
    left: 180px;
    top: 50%
}

.ico6 {
    right: 180px;
    top: 60%
}

.ico7 {
    left: 60px;
    top: 80%
}

.ico8 {
    right: 150px;
    top: 90%
}












@media screen and (max-width:1800px) {
    header .wrapper {
        padding-left: 440px;
    }

}

@media screen and (max-width:1280px) {
    .infoBrand ul {
        margin: 100px 30px 0 30px;
    }

    .infoBrand {
        width: 350px
    }

    header .wrapper {
        padding-left: 370px;
    }

    .txt-banner h1 {
        padding-left: 360px
    }

    .blcReservation {
        max-width: 536px;
        margin: 0 0 0 auto;
    }

    .contentPage {
        padding-top: 60px;
        padding-bottom: 60px;

    }

    .numbers-row {
        margin-right: 40px
    }

    .contentPage .container {
        padding: 0 25px
    }

}

@media screen and (max-width:1200px) {
    .numbers-row {
        width: 110px;
    }

    .calandar::before {
        width: calc(100% + 40px);
        left: -20px;

    }

    .contentPage .col .inner {
        padding: 20px
    }

    .numbers-row {
        margin-right: 35px
    }

    .menu-ftr li {
        padding: 0 10px
    }


    .ico1 {
        left: 40px;

    }

    .ico2 {
        left: 60px;

    }

    .ico3 {
        right: 60px;

    }

    .ico4 {
        right: 40px;

    }

    .ico5 {
        left: 60px;

    }

    .ico6 {
        right: 60px;

    }

    .ico7 {
        left: 20px;

    }

    .ico8 {
        right: 80px;

    }




}


@media screen and (max-width:1024px) {

    .contentPage .container .leftCol,
    .contentPage .container .rightCol {
        width: 100%;
        padding: 0;
        max-width: 100%;
    }

    .blcCalandar {
        max-width: 725px;
        margin: 0 auto 40px
    }

    .blcReservation {
        margin: 0 auto
    }

    .btn.loginBox {
        margin-left: 20px;
    }

    .blcChp.tel label {
        padding-left: 0;
    }


}

@media screen and (max-width:991px) {
    .contentPage h2 {
        margin-bottom: 20px
    }

    .calandar::before {
        display: none;

    }

    header .wrapper {
        padding-left: 0;
    }

    header {
        padding: 0
    }

    .footer .container {
        padding: 0 20px;
        justify-content: center;
        flex-direction: column;
    }

    .menu-ftr li a {
        color: #131313;
        text-transform: uppercase;
        text-align: center;
        display: block;
    }

    footer ul {
        text-align: center;
        margin-bottom: 10px
    }

    .powered {
        margin-bottom: 10px
    }

    .blcReservation {
        max-width: 725px
    }

    header .wrapper {
        padding-left: 0;
        justify-content: space-between;
        display: flex;
    }

    header .blcLeft {
        padding-left: 30px;
    }

    .leaderBoard {
        height: 500px
    }

    .txt-banner h1 {
        font-size: 50px;
        line-height: normal;
    }

    .infoBrand ul {
        margin: 60px 30px 0 30px;
    }

    .btn.loginBox {
        margin-left: 20px;
    }

    .lstReservations .item {
        padding: 30px;
    }

    .contentBox.numero .btn-primary {
        margin-top: 20px !important;
    }

    .ico1 {
        left: 20px;
    }

    .ico4 {
        right: 10px;
    }

    .ico5 {
        left: 10px;
    }




}

@media screen and (max-width:768px) {
    .infoBrand {
        width: 100%;
    }

    .infoBrand ul {
        margin: 145px 0 0 0;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        width: 100%;

    }

    .infoBrand ul li {
        text-align: center;
    }

    .infoBrand ul li {
        position: relative;
        color: #131313;
        margin-bottom: 20px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .txt-banner {
        z-index: 2
    }

    .txt-banner h1 {
        padding-left: 0;
    }

    .logo {
        margin-top: 50px
    }

    .infoBrand {
        background-color: transparent;
        backdrop-filter: inherit;
        -webkit-backdrop-filter: inherit;
    }

    header .boxLogin .h3 {
        margin-bottom: 5px;
    }

    .contFormControl.mail .form-control {
        padding-left: 25px
    }

    .lstReservations .item .inner .txt {
        padding: 30px 20px;
    }

    .lstReservations .item .inner .txt {
        padding: 30px 20px;
    }

    .col2Conex {
        flex-direction: column;
    }

    .contentBox {
        width: 100%
    }

    .OptionPaiement {
        display: flex;
        column-gap: 10px;
        margin-bottom: 20px;
        flex-flow: row wrap;
        row-gap: 10px;
    }

    .contentPage .container .blcFormulaire {
        padding: 40px 20px 40px
    }

    .det-resa .blcRight {
        position: static;
        padding-top: 40px
    }

    .blcCodeQr {
        align-items: start;
    }

    .deco {
        display: none;
    }


}

@media screen and (max-width:600px) {
    .legCal li {
        padding-right: 20px
    }

    .contentPage .container {
        padding: 0 20px;
    }

    .ite.flex {
        flex-direction: column;
        justify-content: inherit;
    }

    .ite .prix {
        margin-top: 20px
    }

    .numbers-row {
        width: auto;
        margin: 0
    }

    .contentPage {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .footer .container {
        padding: 0 20px
    }

    header .blcRight .btn i {
        display: none;
    }

    .btn.loginBox {
        margin-left: 0;
    }

    header .blcLeft {
        padding-left: 10px;
    }

    .txt-banner h1 {
        font-size: 35px;
        line-height: normal;
    }

    .txt-banner {
        margin-top: 20px
    }

    .infoBrand ul {
        margin-top: 130px
    }

    header .blcRight .btn {
        padding: 0
    }

    header .boxLogin {
        width: 290px
    }

    .boxLogin {
        padding: 20px
    }

    .contFormControl {
        margin-bottom: 8px
    }

    .loginBox .forgotPsw {
        margin-top: 20px;
        margin-bottom: 15px
    }

    .bloc-chp .col-50 {
        width: 100%;

    }

    .contentPage .container .blcFormulaire {
        padding: 30px 20px 30px
    }

    .contentPage .container .blcFormulaire.blc-reservation {
        text-align: center;
        padding-bottom: 40px;
    }

    .contentPage .container .blcFormulaire.blc-reservation p {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .col2Conex {
        justify-content: space-between;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    .contentBox {
        padding: 20px
    }

    .contentPage.recherche-reservation {
        padding-top: 40px;
    }

    .sec-breadcrumb .container {
        padding: 0 20px;
    }

    .breadcrumb nav li::after,
    .breadcrumb ul li::after {
        margin: 0 10px
    }

    .det-resa {
        padding: 20px
    }

    #ppCodeConfirm {
        padding: 20px
    }

}

@media screen and (max-width:480px) {
    .info {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 90%;
    }


}