@charset "UTF-8";

:root {
  --btn-background: red;
  --btn-background-hover: #000;
  --h2-font-family: "Great Vibes";
  --h1: "Great Vibes";
  --h2: "Great Vibes";
  --h3: "Great Vibes"
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 75%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format("woff2");
  unicode-range: u+0460-052f,
    u+1c80-1c88,
    u+20b4,
    u+2de0-2dff,
    u+a640-a69f,
    u+fe2e-fe2f
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format("woff2");
  unicode-range: u+0301,
    u+0400-045f,
    u+0490-0491,
    u+04b0-04b1,
    u+2116
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format("woff2");
  unicode-range: u+1f??
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format("woff2");
  unicode-range: u+0370-03ff
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format("woff2");
  unicode-range: u+0590-05ff,
    u+200c-2010,
    u+20aa,
    u+25cc,
    u+fb1d-fb4f
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format("woff2");
  unicode-range: u+0102-0103,
    u+0110-0111,
    u+0128-0129,
    u+0168-0169,
    u+01a0-01a1,
    u+01af-01b0,
    u+1ea0-1ef9,
    u+20ab
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format("woff2");
  unicode-range: u+0100-024f,
    u+0259,
    u+1e??,
    u+2020,
    u+20a0-20ab,
    u+20ad-20cf,
    u+2113,
    u+2c60-2c7f,
    u+a720-a7ff
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format("woff2");
  unicode-range: u+00??,
    u+0131,
    u+0152-0153,
    u+02bb-02bc,
    u+02c6,
    u+02da,
    u+02dc,
    u+2000-206f,
    u+2074,
    u+20ac,
    u+2122,
    u+2191,
    u+2193,
    u+2212,
    u+2215,
    u+feff,
    u+fffd
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format("woff2");
  unicode-range: u+0460-052f,
    u+1c80-1c88,
    u+20b4,
    u+2de0-2dff,
    u+a640-a69f,
    u+fe2e-fe2f
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format("woff2");
  unicode-range: u+0301,
    u+0400-045f,
    u+0490-0491,
    u+04b0-04b1,
    u+2116
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format("woff2");
  unicode-range: u+1f??
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format("woff2");
  unicode-range: u+0370-03ff
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format("woff2");
  unicode-range: u+0590-05ff,
    u+200c-2010,
    u+20aa,
    u+25cc,
    u+fb1d-fb4f
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format("woff2");
  unicode-range: u+0102-0103,
    u+0110-0111,
    u+0128-0129,
    u+0168-0169,
    u+01a0-01a1,
    u+01af-01b0,
    u+1ea0-1ef9,
    u+20ab
}

@font-face {
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format("woff2");
    unicode-range: u+0100-024f,
      u+0259,
      u+1e??,
      u+2020,
      u+20a0-20ab,
      u+20ad-20cf,
      u+2113,
      u+2c60-2c7f,
      u+a720-a7ff
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format("woff2");
    unicode-range: u+00??,
      u+0131,
      u+0152-0153,
      u+02bb-02bc,
      u+02c6,
      u+02da,
      u+02dc,
      u+2000-206f,
      u+2074,
      u+20ac,
      u+2122,
      u+2191,
      u+2193,
      u+2212,
      u+2215,
      u+feff,
      u+fffd
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format("woff2");
    unicode-range: u+0460-052f,
      u+1c80-1c88,
      u+20b4,
      u+2de0-2dff,
      u+a640-a69f,
      u+fe2e-fe2f
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format("woff2");
    unicode-range: u+0301,
      u+0400-045f,
      u+0490-0491,
      u+04b0-04b1,
      u+2116
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format("woff2");
    unicode-range: u+1f??
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format("woff2");
    unicode-range: u+0370-03ff
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format("woff2");
    unicode-range: u+0590-05ff,
      u+200c-2010,
      u+20aa,
      u+25cc,
      u+fb1d-fb4f
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format("woff2");
    unicode-range: u+0102-0103,
      u+0110-0111,
      u+0128-0129,
      u+0168-0169,
      u+01a0-01a1,
      u+01af-01b0,
      u+1ea0-1ef9,
      u+20ab
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format("woff2");
    unicode-range: u+0100-024f,
      u+0259,
      u+1e??,
      u+2020,
      u+20a0-20ab,
      u+20ad-20cf,
      u+2113,
      u+2c60-2c7f,
      u+a720-a7ff
  }
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format("woff2");
  unicode-range: u+00??,
    u+0131,
    u+0152-0153,
    u+02bb-02bc,
    u+02c6,
    u+02da,
    u+02dc,
    u+2000-206f,
    u+2074,
    u+20ac,
    u+2122,
    u+2191,
    u+2193,
    u+2212,
    u+2215,
    u+feff,
    u+fffd
}

@font-face {
  font-family: "orbitronBold";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/static/media/orbitron-bold.09ab259d.otf)
}

@font-face {
  font-family: "orbitronExtraBold";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/static/media/orbitron-extrabold.c46f97bc.ttf)
}

html {
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

main {
  display: block
}

hr {
  box-sizing: initial;
  height: 0;
  overflow: visible
}

pre {
  font-family: monospace, monospace;
  font-size: 1em
}

a {
  background-color: initial
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em
}

small {
  font-size: 80%
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: initial
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

img {
  border-style: none
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText
}

fieldset {
  padding: .35em .75em .625em
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  vertical-align: initial
}

textarea {
  overflow: auto
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

details {
  display: block
}

summary {
  display: list-item
}

[hidden],
template {
  display: none
}

blockquote,
q {
  quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

header {
  background: transparent;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 25px 0;
  z-index: 10;
  box-shadow: none
}

header .right .btn i {
  font-style: normal
}

.calandar .btn {
  margin-left: 23px;
  border: 1px solid red;
  border: 1px solid var(--btn-background);
  padding-top: 11px;
  padding-bottom: 11px
}

header .btn:hover {
  border-color: #000;
  border-color: var(--btn-background-hover)
}

header .btn.btnSelect {
  border: 1px solid transparent
}

header .topLogo {
  visibility: hidden;
  opacity: 0
}

header .topLogo img {
  max-height: 44px
}

header.fixedTop {
  background-color: #fff;
  padding: 15px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1)
}

header.fixedTop .topLogo {
  visibility: visible;
  opacity: 1
}

header.fixedTop .btn.btnSelect {
  border-color: #d1d1d1
}

hr {
  margin: 8px 0 21px;
  border: 0;
  border-bottom: 1px solid #d1d1d1
}



.pure-checkbox input[type=checkbox]:focus+label:before,
.pure-checkbox input[type=checkbox]:hover+label:before,
.pure-checkbox input[type=radio]:focus+label:before,
.pure-checkbox input[type=radio]:hover+label:before,
.pure-radiobutton input[type=checkbox]:focus+label:before,
.pure-radiobutton input[type=checkbox]:hover+label:before,
.pure-radiobutton input[type=radio]:focus+label:before,
.pure-radiobutton input[type=radio]:hover+label:before {
  border-color: #131313
}

.pure-checkbox input[type=checkbox]+label,
.pure-checkbox input[type=radio]+label,
.pure-radiobutton input[type=checkbox]+label,
.pure-radiobutton input[type=radio]+label {
  display: inline-block
}

.pure-checkbox input[type=checkbox]+label:before,
.pure-checkbox input[type=radio]+label:before,
.pure-radiobutton input[type=checkbox]+label:before,
.pure-radiobutton input[type=radio]+label:before {
  color: #131313;
  top: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #9b9b9b;
  border-radius: 3px
}

.pure-checkbox input[type=checkbox]+label:after,
.pure-checkbox input[type=radio]+label:after,
.pure-radiobutton input[type=checkbox]+label:after,
.pure-radiobutton input[type=radio]+label:after {
  background-color: #e20026;
  left: 6px;
  width: 10px;
  height: 10px;
  top: 11px
}

.pure-checkbox input[type=checkbox]+label:after,
.pure-radiobutton input[type=checkbox]+label:after {
  top: 11px;
  left: 5px;
  width: 9px;
  height: 4px;
  border-color: #e20026
}

.pure-checkbox input[type=checkbox]:checked+label:before,
.pure-radiobutton input[type=checkbox]:checked+label:before {
  background: transparent
}

.pure-checkbox-toggle input[type=checkbox]+label:before {
  color: #a9c14e
}

.pure-checkbox-toggle input[type=checkbox]:checked+label:before {
  box-shadow: inset 0 0 0 10px #131313
}

.pure-checkbox-toggle input[type=checkbox]:checked+label:after {
  border-color: #131313
}

.pure-checkbox,
.pure-radiobutton {
  margin-bottom: 5px
}

.calandar .btn,
.calandar .button {
  background: red;
  background: var(--btn-background);
  color: #fff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  border: 0;
  padding: 13px 15px;
  font-family: "Open Sans";
  border-radius: 0;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  white-space: nowrap
}

/*.btn:hover,
.button:hover {
  color:#fff;
  background:#000;
  background:var(--btn-background-hover)
}*/
.btn span,
.button span {
  vertical-align: bottom
}

.btn select,
.button select {
  display: inline-block
}

.btn.btnSelect,
.button.btnSelect {
  color: #d1d1d1;
  background: #fff
}

.btn.btnSelect select,
.button.btnSelect select {
  color: #131313
}

.loading {
  background-image: url(/static/media/loading.d02c8f0b.svg) !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  text-indent: 999%;
  overflow: hidden
}

.loading:hover {
  cursor: default;
  background-color: red !important;
  background-color: var(--btn-background) !important
}

.loadOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  background-size: 60px
}

.loadOverlay,
.loadOverlay:hover {
  background-color: hsla(0, 0%, 100%, .5) !important;
  cursor: default
}

#block-webform-2 .form-submit,
#block-webform-3 .form-submit {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 15px
}

select {
  -webkit-appearance: none;
  appearance: none;
  background: url(/static/media/select.9fc3249b.svg) no-repeat 100%;
  font-family: "Open Sans";
  font-weight: 400;
  border: 0;
  color: #131313;
  padding-right: 15px
}

input[type=email],
input[type=number],
input[type=password],
input[type=tel],
input[type=text],
textarea {
  border-radius: 0;
  border: 0;
  padding: 15px 26px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: "Open Sans";
  color: #131313
}


select.form-control {
  border-radius: 0;
  border: 0;
  padding: 15px 26px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: "Open Sans";
  color: #131313;
  background-image: url(/static/media/select2.5cf5d4b0.svg)
}

textarea {
  height: 135px;
  resize: none;
  padding-top: 20px;
  margin-bottom: 10px
}

.notefield {
  font-size: 14px;
  color: #9b9b9b;
  margin: 5px 0 0
}

.form2Cols {
  display: flex;
  grid-gap: 15px;
  gap: 15px
}

.ui-datepicker-month,
.ui-datepicker-year {
  background: none;
  padding-left: 0 !important;
  -webkit-appearance: auto;
  appearance: auto;
  padding-right: 0
}

@media screen and (max-width:767px) {

  input[type=email],
  input[type=number],
  input[type=tel],
  input[type=text],
  select,
  textarea {
    padding-left: 15px;
    padding-right: 15px
  }

  .consent .checkmark {
    bottom: 6px
  }
}

body,
html {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #131313;
  min-width: 320px;
  font-family: "Open Sans"
}

body,
html {
  overflow-x: hidden
}

body {
  color: #131313;
  min-width: 320px
}

* {
  -ms-box-sizing: border-box;
  box-sizing: border-box
}

br {
  clear: both
}

main {
  position: relative;
  z-index: 1
}

:focus {
  outline: none
}

::-webkit-input-placeholder {
  color: #6d6d6d !important;
  opacity: 1 !important
}

::placeholder {
  color: #6d6d6d !important;
  opacity: 1 !important
}

:-ms-input-placeholder {
  color: #6d6d6d !important
}

::-ms-input-placeholder {
  color: #6d6d6d !important
}

a {
  text-decoration: none
}

b,
strong {
  font-weight: 700
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0
}

ul,
ul li {
  list-style: none
}

ul li {
  margin-bottom: 20px
}

p,
ul li {
  line-height: 22px
}

p {
  margin: 0 0 25px;
}

.clr {
  *zoom: 1
}

.clr:after,
.clr:before {
  content: " ";
  display: table;
  clear: both
}

.space10 {
  padding: 10px 0
}

.space15 {
  padding: 15px 0
}

.space20 {
  padding: 20px 0
}

.space25 {
  padding: 25px 0
}

.space30 {
  padding: 30px 0
}

.center {
  text-align: center
}

.alignLeft {
  text-align: left
}

.alignRight {
  text-align: right
}

.colorPrimary {
  color: #e20026 !important
}

.colorSecondary {
  color: #131313 !important
}

.w20,
.w30,
.w40,
.w50,
.w60 {
  float: left
}

.w20 {
  width: 20%
}

.w30 {
  width: 30%
}

.w33 {
  width: 33.33%
}

.w40 {
  width: 40%
}

.w50 {
  width: 50%
}

.w60 {
  width: 60%
}

.fullWidht,
.w100,
.w-100 {
  width: 100%
}

.w810 {
  max-width: 810px
}

.w1088 {
  max-width: 1088px
}

.w1375 {
  max-width: 1375px
}

.pad15 {
  padding: 15px
}

.pad20 {
  padding: 20px
}

.pad25 {
  padding: 25px
}

.pad30 {
  padding: 30px
}

.pad35 {
  padding: 35px
}

.mxAuto {
  margin: 0 auto
}

.container,
.wrapper {
  max-width: 1728px;
  padding: 0 40px;
  margin: 0 auto;
  width: 100%
}



.filterOption .close span {
  font-size: 15px;
  vertical-align: top;
  margin-top: 2px
}

.filterOption .close:hover {
  color: #e20026;
  border-color: #e20026
}

.filterOption .itemOption {
  position: relative;
  padding: 0 1.5%;
  flex: 0 0 16.66%;
  max-width: 16.66%
}

.filterOption .itemOption:before {
  height: 95px;
  left: 0;
  content: "";
  position: absolute;
  top: 12px;
  border-right: 1px solid #d1d1d1
}

.filterOption .itemOption:first-child:before {
  display: none
}

.filterOption .h4 {
  font-weight: 700;
  margin-bottom: 10px
}

.lstPromo {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: -10px;
  bottom: 0
}

.lstPromo .item {
  margin-bottom: 16px;
  position: relative;
  min-height: 100px;
  padding: 13px 20px 13px 94px
}

.lstPromo .deco {
  width: 63px;
  height: 61px;
  background: url(/static/media/deco.64ca53a0.svg) no-repeat;
  background-size: cover;
  text-align: center;
  line-height: 61px;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  position: absolute;
  left: 20px;
  top: 20px
}

.lstPromo .h3 {
  font-size: 20px;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 15px;
  width: 100%;
  font-family: "Great Vibes";
  font-family: var(--h3);
  color: red;
  color: var(--btn-background)
}

.lstPromo span {
  margin-right: 15px;
  color: #9b9b9b;
  vertical-align: bottom;
  position: relative;
  top: -2px
}

.lstPromo a {
  color: #131313
}

.lstPromo .infoTips {
  width: 380px
}

.infoTips {
  background: #f8f8f8;
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  min-width: 280px
}

.infoTips .content {
  max-height: 161px;
  max-height: 170px;
  overflow: auto;
  padding: 21px 27px 25px
}

.infoTips .modal_close {
  position: absolute;
  right: -8px;
  top: -10px;
  background: #fff
}

.infoTips .modal_close:hover {
  border-color: red;
  border-color: var(--btn-background)
}

.infoTips .modal_close:hover:after,
.infoTips .modal_close:hover:before {
  background: red !important;
  background: var(--btn-background) !important
}

.infoTips h4 {
  margin: 0 0 5px;
  text-transform: uppercase;
  font-size: 16px
}

.infoTips u {
  text-decoration: line-through;
  color: #9b9b9b
}

.infoTips li {
  margin-bottom: 15px;
  background: url(/static/media/lst.560d1bbe.svg) no-repeat 0 7px;
  padding-left: 21px;
  max-width: 100% !important;
  flex: 0 0 100% !important;
  font-size: 16px
}

.infoTips li:last-child {
  margin-bottom: 0
}

.infoTips.show {
  visibility: visible;
  opacity: 1
}

#fixHeightPopupProm {
  max-height: unset
}

.puce ul {
  margin-bottom: 25px
}

.puce li {
  margin-bottom: 15px;
  background: url(/static/media/lst.560d1bbe.svg) no-repeat 0 7px;
  padding-left: 21px;
  max-width: 100% !important;
  flex: 0 0 100% !important;
  font-size: 16px
}

.puce li:last-child {
  margin-bottom: 0
}

.equipment ul {
  display: flex;
  flex-wrap: wrap
}

.equipment li {
  border: 1px solid #d1d1d1;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9b9b9b;
  margin: 0 7px 5px 0
}

.equipment li span {
  font-size: 26px
}

.equipment img {
  width: 26px;
  -webkit-filter: invert(.6);
  filter: invert(.6)
}

.moreTxt {
  padding: 0 0 12px 31px;
  border-bottom: 2px solid red;
  border-bottom: 2px solid var(--btn-background);
  text-transform: uppercase;
  color: #131313;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer
}

.moreTxt:before {
  content: "+";
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  width: 18px;
  height: 18px;
  background: red;
  background: var(--btn-background);
  display: block;
  position: absolute;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  top: 2px;
  left: 0
}

.moreTxt:hover {
  color: red;
  color: var(--btn-background)
}

.leftCol {
  overflow: hidden;
  position: relative
}

.leftCol,
.rightCol {
  max-width: 24%;
  width: 100%
}

.centerCol {
  flex: 1 1;
  margin: 0 16px
}

.roomLst>.item {
  margin-bottom: 48px
}

.roomLst .firstInfo {
  min-height: 286px;
  position: relative;
  padding: 30px 35px 35px 50%
}

.roomLst .contImg {
  height: 100%;
  max-width: 46%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer
}

.roomLst .contImg img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%
}

.roomLst .h4 {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  color: red;
  color: var(--btn-background);
  font-family: "Great Vibes";
  font-family: var(--h2);
  font-size: 35px
}

.roomLst .max {
  margin-bottom: 15px
}

.roomLst .max span {
  margin-right: 4px;
  vertical-align: text-bottom;
  color: #9b9b9b
}

.roomLst .desc {
  margin-bottom: 20px
}

.roomLst .desc p {
  text-align: justify
}

.roomLst .equipment {
  max-width: 470px;
  float: left
}

.roomLst .moreTxt {
  float: right;
  margin-top: 16px;
  margin-right: 15px
}

.roomLst .secInfo {
  background: #f2f2f2;
  padding: 25px 34px
}

.contTxtCond {
  color: red;
  color: var(--btn-background);
  text-decoration: underline
}

.contTxtCond span {
  vertical-align: bottom;
  margin-right: 3px;
  color: #9b9b9b
}

.secInfo .head {
  margin-bottom: 20px;
  padding-right: 15px
}

.secInfo .h4 {
  border: 0;
  padding: 0;
  margin: 0;
  color: #131313;
  font-size: 16px;
  font-family: "Great Vibes";
  font-family: var(--h2)
}

.lstBook .item {
  padding-right: 190px;
  position: relative;
  margin-bottom: 10px
}

.lstBook .left {
  min-height: 50px;
  background: #fff;
  border: 1px solid #d1d1d1;
  padding: 12px 20px;
  justify-content: left
}

.lstBook .btn {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 174px;
  background: red;
  background: var(--btn-background)
}

.lstBook .btn:hover {
  background: #000;
  background: var(--btn-background-hover)
}

.lstBook .nb {
  border-right: 1px solid #d1d1d1;
  padding-right: 18px
}

.lstBook .discount,
.lstBook .price {
  padding-left: 18px;
  align-items: center
}

.lstBook .discount {
  font-size: 10px;
  cursor: pointer
}

.lstBook .discount span {
  vertical-align: middle;
  color: #9b9b9b
}

.lstBook .discount .infoTips {
  margin-top: 15px
}

.lstBook .discount:hover span {
  color: #e20026
}

.lstBook .discount:hover .infoTips {
  visibility: visible;
  opacity: 1
}

.rateName:hover span {
  color: red;
  color: var(--btn-background)
}

.rateName:hover .infoTips {
  visibility: visible;
  opacity: 1
}

.price .oldPrice {
  text-decoration: line-through;
  font-size: 14px;
  color: #9b9b9b
}

.price .newPrice {
  font-weight: 700;
  font-size: 18px;
  margin-left: 8px
}

.condition {
  position: relative;
  cursor: pointer
}

.lstTools .item {
  margin-bottom: 53px
}

.lstTools .head {
  background: red;
  background: var(--btn-background);
  color: #131313
}

.lstTools .head strong {
  color: #fff
}

.lstTools .foot,
.lstTools .head,
.lstTools .lstItem {
  padding: 20px 27px
}

.lstTools .foot {
  padding-bottom: 33px
}

.lstTools .foot .price {
  margin-bottom: 15px;
  display: block
}

.lstTools .contLstItem .lstItem:nth-last-child(odd) {
  background: #f2f2f2
}

.lstTools .namePrice {
  margin-bottom: 8px
}

.lstTools .footLstItem {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center
}

.option a {
  width: 30px;
  height: 30px;
  background: #d1d1d1;
  display: inline-block;
  text-align: center;
  line-height: 27px;
  color: #757575
}

.option a:hover {
  background: #e20026;
  color: #fff
}

.option.withTxt a {
  white-space: nowrap;
  text-decoration: underline;
  color: #9b9b9b
}

.option.withTxt a:hover {
  color: #131313
}

.option.withTxt a:hover span {
  color: #fff
}

.option.withTxt a span {
  margin: 0 16px 0 3px
}

.result {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 8px
}

.result li {
  max-width: 48%;
  flex: 0 0 48%;
  margin-bottom: 12px
}

.result li span {
  color: #9b9b9b;
  margin-right: 5px
}

.boxWhite {
  background: #fff;
  padding: 20px 27px
}

.totalPrice {
  font-size: 18px;
  margin-bottom: 19px
}

.boxOcc {
  color: #fff;
  position: absolute;
  background: #fff;
  border: 1px solid #fff;
  z-index: 10;
  padding: 14px 20px 24px;
  width: 100%;
  top: 92px;
  max-width: 306px;
  visibility: hidden;
  opacity: 0;
  background: red;
  background: var(--btn-background)
}

.boxOcc .ite,
.boxOcc .nb {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.boxOcc .ite {
  margin-bottom: 12px
}

.boxOcc .ite:last-child {
  margin-bottom: 0
}

.boxOcc .nb {
  width: 60%;
  text-align: center;
  align-items: center
}

.boxOcc .nb span {
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 1px solid #fff;
  cursor: pointer;
  line-height: 21px
}

.boxOcc .nb span:hover {
  background: #fff;
  color: red;
  color: var(--btn-background)
}

.boxOcc .nb input {
  background: red;
  background: var(--btn-background);
  color: #fff;
  text-align: center;
  margin: 0 8px;
  height: 48px;
  -webkit-appearance: none;
  appearance: none;
  border-bottom: 1px solid #fff
}

.boxOcc .nb input::-webkit-inner-spin-button,
.boxOcc .nb input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.boxOcc .nb input[type=number] {
  -moz-appearance: textfield
}

.boxOcc strong {
  font-weight: 400
}

.contBoxOcc {
  width: 100%
}

.contBoxOcc:hover .boxOcc {
  opacity: 1;
  visibility: visible
}

.head_modal {
  background: #f6f6f6;
  padding: 45px 20px 25px;
  font-weight: 700
}

.head_modal img {
  margin-bottom: 15px
}

.cont_modal {
  padding: 40px 20px
}

.cont_modal p {
  color: #131313
}

.leaderInternalPage {
  height: 426px !important
}

.boxCenter {
  margin-top: 40px
}

.boxStandard {
  padding: 25px 69px
}

.boxCenter .btn.w100 {
  width: auto;
  float: right;
  margin: 0 0 30px !important
}

.roomLst.detail>.item {
  margin-bottom: 0;
  box-shadow: none
}

.roomLst.detail .firstInfo {
  padding: 0
}

.roomLst.detail .contImg {
  position: static;
  max-width: inherit;
  height: 389px
}

.roomLst.detail .contImg img {
  object-fit: cover;
  object-position: center
}

.roomLst.detail .roomInfo {
  padding: 35px 37px 37px;
  border: 1px solid #d1d1d1;
  border-top: 0
}

.roomInfo .h4 {
  line-height: 35px
}

.roomLst.detail .equipment {
  max-width: inherit
}

.roomLst.detail .lstBook .price {
  width: 54%;
  justify-content: flex-end;
  padding-right: 7%
}

/*.col2Conex {
  grid-gap:0 10px;
  gap:0 10px;
  align-items:stretch
}
.col2Conex .boxLogin {
  max-width:49.3%
}*/
.center .btn.w100 {
  float: none
}

.noLine {
  text-decoration: none !important
}

.colBigRight .rightCol {
  max-width: 518px;
  width: 100%
}

.stat {
  text-transform: uppercase;
  color: #9b9b9b;
  font-weight: 600;
  padding-left: 44px;
  line-height: 32px;
  background: url(/static/media/statOk.1a5f07c5.svg) no-repeat 0 0
}

.stat.success {
  color: #9bd219
}

.stat.error {
  color: red;
  background-image: url(/static/media/statError.9bc30816.svg)
}

.listConf .item {
  margin-bottom: 18px
}

.listConf .item:last-child {
  margin-bottom: 0
}

.listConf .item strong {
  color: #e20026
}

img.cover {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%
}

.blkSummay .content,
.blkSummay .foot,
.blkSummay .head {
  padding: 50px 70px
}

.blkSummay .head {
  padding-bottom: 0;
  flex: 1 1
}

.blkSummay .head .h3 {
  width: 100%;
  font-family: "Great Vibes";
  font-family: var(--h1)
}

.blkSummay .head .contImg {
  max-width: 46%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%
}

.blkSummay .head .right {
  flex: 1 1;
  padding: 15px 10%
}

.blkSummay .head .bordered {
  padding-left: 46%;
  position: relative
}

.blkSummay .head .result {
  margin: 0 0 5px
}

.blkSummay .head .result li {
  max-width: 100%;
  flex: 0 0 100%;
  margin-bottom: 5px
}

.blkSummay .content {
  padding-bottom: 20px
}

.blkSummay .content .resumInfo {
  align-items: flex-start
}

.blkSummay .content .resumInfo p {
  margin: 0 0 15px
}

.blkSummay .content p {
  color: #131313
}

.blkSummay .content p:last-child {
  margin-bottom: 0
}

.blkSummay .foot {
  background: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 30px
}

.blkSummay .foot .infoTips {
  visibility: visible;
  opacity: 1;
  position: static;
  padding: 0
}

.blkSummay .foot .infoTips ul {
  margin-bottom: 25px
}

.blkSummay .foot .infoTips ul:last-child {
  margin-bottom: 0
}

.ref {
  color: #a19789
}

.fex1 {
  flex: 1 1
}

.advanceTools {
  margin-bottom: 21px;
  font-size: 14px
}

.advanceTools a {
  color: #131313
}

.advanceTools a:hover,
.advanceTools a:hover span {
  color: #e20026
}

.advanceTools span {
  vertical-align: middle;
  color: #9b9b9b
}

.addMap iframe {
  width: 100%;
  height: 440px
}

.colBigRight .centerCol {
  margin: 0 35px 0 0
}

.bookingList {
  margin-bottom: 60px;
  grid-gap: 16px 1.6%;
  gap: 16px 1.6%
}

.bookingList>.item {
  max-width: 23.8%;
  flex: 0 0 23.8%;
  margin-bottom: 90px
}

.bookingList .h3 {
  margin-bottom: 25px;
  text-transform: uppercase;
  font-size: 16px
}

.bookingList .contConf {
  border: 1px solid #9b9b9b;
  background: #f8f8f8
}

.bookingList .contImg {
  height: 261px
}

.bookingList .listConf .item {
  max-width: inherit;
  flex: 0 0 100%
}

.bookingList .puce {
  padding: 30px;
  border: 1px solid #d1d1d1;
  border-top: 0;
  position: relative
}

.bookingList .puce ul {
  margin-bottom: 0
}

.bookingList .puce li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.bookingList .puce li:last-child {
  margin-bottom: 0
}

.bookingList .btn {
  margin-bottom: -80px;
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0
}

.footBgColor {
  background: #f8f8f8
}

.sizeBase {
  font-size: 16px
}

.largeTextarea {
  height: 200px
}

.contTot {
  padding-top: 30px !important;
  padding-bottom: 15px !important
}

.contTot .left,
.contTot .right {
  flex: 0 0 50%
}

.contTot .left .priceTot {
  font-size: 26px;
  font-weight: 700;
  color: #9bd219
}

.contTot .option {
  margin-bottom: 7px
}

.contTot .option:last-child {
  margin-bottom: 0
}

.timing {
  align-items: center;
  font-size: 20px;
  margin-bottom: 30px
}

.timing .h3 {
  border-right: 1px solid #707070;
  padding: 0 29px 0 0;
  margin: 0 25px 0 0;
  text-transform: uppercase
}

.timing .material-symbols-rounded {
  color: red;
  color: var(--btn-background)
}

.timing .sec {
  color: #e20026
}

.calandar .bodyMonth .itemNumb {
  padding: 8px
}

.calandar .bodyMonth .itemNumb .content {
  align-content: flex-start;
  min-height: 50px
}

.calandar .bodyMonth .itemNumb.lastDate .content {
  opacity: .5
}

.calandar .bodyMonth .itemNumb.active .content {
  background: #000
}

.calandar .bodyMonth .line {
  background: #e20026
}

@media screen and (min-width:768px) {
  .popupPromotion {
    opacity: 1;
    visibility: visible;
    position: fixed;
    top: 371px;
    left: 25%;
    max-height: 100%;
    max-width: 90%
  }
}

@media screen and (max-width:636px) {
  main {
    position: absolute
  }
}

@media screen and (min-width:1530px) {
  .popupPromotion {
    left: 28.57143%
  }
}

@media screen and (min-width:1700px) {
  .popupPromotion {
    left: 33.33333%
  }
}

@media screen and (min-width:2220px) {
  .popupPromotion {
    left: 37.5%
  }
}

@media screen and (min-width:2950px) {
  .popupPromotion {
    left: 40%
  }
}

.removeUpDown::-webkit-inner-spin-button,
.removeUpDown::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.removeUpDown[type=number] {
  -moz-appearance: textfield
}

.contFormControl {
  position: relative
}

.contFormControl .condition li,
.contFormControl .condition p {
  color: #131313;
  font-size: 16px;
  font-weight: 400
}

.consent a {
  text-decoration: underline;
  color: #131313
}

.consent a:hover {
  color: #e20026
}

.lstGateway {
  grid-gap: 10px 15px;
  gap: 10px 15px;
  margin-bottom: 25px
}

.lstGateway label {
  padding: 0 !important;
  display: block;
  width: 120px !important;
  height: 70px !important
}

.lstGateway label:after,
.lstGateway label:before {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  left: 0 !important;
  top: 0 !important;
  background: none !important;
  border: 1px solid #d1d1d1 !important
}

.lstGateway label:after {
  border: 3px solid #e20026 !important;
  position: absolute;
  z-index: 10;
  width: 116px !important;
  height: 66px !important
}

.calandar {
  /* position:absolute;*/
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  -webkit-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  -moz-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  padding: 10px 10px 20px;
  max-width: 725px;
  border-radius: 25px;
  margin-bottom: 20px;
  position: relative;
}

.calandar .contentCalandar {
  display: flex
}

.calandar .itemMonth {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 5px
}

.calandar .contentWrappMoth {
  /*border:1px solid #ebebeb*/
}

.calandar .headItem {
  text-align: center;
  font-size: 16px;
  padding: 10px 0;
  color: #014C55;
  font-weight: 600;
  font-family: Raleway
}

.calandar .wrappMonth {
  padding: 10px
}

.calandar .headMonth {
  display: flex
}

.calandar .headMonth div {
  max-width: 14.2857142857%;
  flex: 0 0 14.2857142857%;
  text-align: center;
  font-weight: normal;
  height: 40px;
  font-size: 12px;
  font-family: Raleway;
  text-transform: uppercase;
}

.calandar .bodyMonth {
  display: flex;
  flex-wrap: wrap
}

.calandar .bodyMonth .itemNumb {
  max-width: 14.2857142857%;
  flex: 0 0 14.2857142857%;
  text-align: center;
  font-size: 14px;
  padding: 2px;
  position: relative
}

.calandar .bodyMonth .itemNumb.sold:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAkJJREFUeNqsVEtIVGEU/rzem87ANFJa2cMiI6hVVquhFiFmge2CgRZRSoQbg5ZiVsuCIGbjQuixFFrZJgLdGEOYTosgqWgWMpGMVJqTozPj/H3nnnu7o6O7fvju/zrn+8/zwhiDTXCRGCUmievePOqdV8nXyKdinCZ6iSakb1xCYQ7Y1QPMPwHs3UDr8EvezRNDxJSvVEnShvxMCtnngMkDPxIBdQ0hYjv6AKuexNeA0LGTPHkv17YnJnMXFsep/EAVNhs/PeK6FiHp4uoDURJLbJi1IhbHgMx9oJBUEv/1SmvgndXFgP13gWg7z2sdIenE0ptX+HJWBWoPA43dwNpvWvVQFaM3uV8AciPriY9OAJEzF8SNJMInyOhQoAjsvQOEjwPb9qngwgvgEMlmOtZbBMqH22SRtPjpd2MhBDJCJMi9pVvflKh5AMg+A1Yn1QrfEpGXEFDfclM6eyu4LGT4CJWtCF3bDuy8rPMpChx5HcRFxmyffHstN+cHHgdmfmd2bCrlP6ol2aea0jTLJ0qXwp0qK2hxszUkFRcxpZwxKceYKSimic9xY1YyupfxKR7Mcj9tG1eP+mJJDMusmXIxiLqY23wbmEvoiznG4+AjxoVxWhrxZErAn5SsYtV1sppUoUg8UPD3y++Y6rRXJ/fo3jk+YjtiSUkKBg3nB9F4JYiNT+D7L/tyWs9ErqFjUAj8it3QO0xneQX4laguf7d3QmzPq1JL/3pn6y7+yi4usouburWLnT1bdjH+x//krwADAKCVXwEZtmV+AAAAAElFTkSuQmCC) no-repeat 50%;
  align-content: center;
  display: none !important;
}

.calandar .bodyMonth .itemNumb.locked:after,
.calandar .bodyMonth .itemNumb.sold:after {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  right: 2px;
  top: 2px;
  z-index: 10;
  display: none !important
}

.calandar .bodyMonth .itemNumb.locked:after {
  background: url(/static/media/cross.372652da.svg) no-repeat 50%;
  background-size: 9px
}

.calandar .bodyMonth .itemNumb.locked * {
  color: #bcbcbc !important;
  cursor: default !important;
  -webkit-user-select: none;
  user-select: none
}

.calandar .bodyMonth .itemNumb .content {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  min-height: 44px;
  position: relative;
  /* background:#f7f7f7;*/
  cursor: pointer;
  align-items: center;
  width: 44px;
  border-radius: 10px;
}

.calandar .bodyMonth .itemNumb .content>div {
  width: 100%;
  margin: 1px 0
}

.calandar .bodyMonth .itemNumb .content .day {
  color: #131313;
  line-height: 16px;
  font-family: Open Sans;
  font-size: 12px
}

.calandar .bodyMonth .itemNumb .content .prs {
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  display: none
}

.calandar .bodyMonth .itemNumb .content .prs.old {
  text-decoration: line-through;
  color: #bcc1d0
}

.calandar .bodyMonth .itemNumb.lastDate .content {
  background: #efefef;
  cursor: default
}

.calandar .bodyMonth .itemNumb.lastDate .content * {
  color: #E8E8E8;
  font-family: Open Sans;
}

.calandar .bodyMonth .itemNumb.active .content {
  background: linear-gradient(to bottom, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);
  color: #fff;
  z-index: 2
}

.calandar .bodyMonth .itemNumb.active .content .day {
  color: #fff
}

.calandar .bodyMonth .itemNumb.notAvalable .content {
  background: url(/static/media/cross.372652da.svg) no-repeat center 21px;
  cursor: default;
  background-size: 18px;
  background-color: #f7f7f7
}

.calandar .bodyMonth .line {
  /*  height:3px;
  background:red;
  background:var(--btn-background);
  max-width:90%*/
}

.calandar .ppPromo {
  position: absolute;
  bottom: 60px;
  width: 205px !important;
  text-align: left !important;
  border: 1px solid #e1e1e1;
  background: #f5f5f5;
  padding: 10px;
  line-height: normal;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease-in-out;
  z-index: 20;
  display: none;
}

.calandar .ppPromo h6 {
  margin: 5px 0;
  font-size: 16px
}

.calandar .ppPromo strong {
  color: #17af17
}

.calandar .ppPromo span {
  color: #000
}

.calandar .content:hover .ppPromo {
  opacity: 1;
  visibility: visible;
  bottom: 50px
}

.calandar .loading {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #efefef !important
}

.calandar .navCalandar {
  position: absolute;
  width: 100%;
  left: 0;
  top: 22px
}

.calandar .navCalandar .nextDate,
.calandar .navCalandar .prevDate {
  width: 12px;
  height: 16px;


  position: absolute;
  left: 32px;
  top: 5px;
  background: #fff url(../images/ico-prev.svg) no-repeat center;
  background-size: 15px;
  z-index: 10;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer
}

.calandar .navCalandar .nextDate:hover,
.calandar .navCalandar .prevDate:hover {
  opacity: .9
}

.calandar .navCalandar .nextDate {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  left: auto;
  right: 33px
}

.pointer {
  cursor: pointer
}

.contSuggest {
  position: relative;
  padding-left: 200px
}

.contSuggest .contImg {
  position: absolute;
  max-width: 190px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0
}

.contSuggest .contImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center
}

.contSuggest .lstTools .item {
  margin-bottom: 0
}

.contSuggest .condition .infoTips {
  left: auto;
  right: 0;
  max-height: 208px;
  overflow: auto;
  bottom: 23px;
  top: auto
}

.rightCol .condition .infoTips {
  left: auto;
  right: 0
}

.legCal {
  margin: 9px 0 0 28px
}

.legCal li {
  display: inline-block;
  vertical-align: top;
  margin: 0 30px 0 0;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  padding: 0 0 0 12px;
  font-family: Raleway
}

.legCal li:last-child {
  margin-right: 0
}

.legCal li:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 8px;
  background: 50% no-repeat;
  z-index: 10;
  display: block !important
}

.legCal li.sold:before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #383838;
}

.legCal li.locked:before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #E8E8E8;
}

.legCal li.dSelect:before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: linear-gradient(to bottom, rgba(51, 100, 126, 1) 0%, rgba(101, 186, 175, 1) 100%);
}

.priceChecker {
  position: fixed;
  right: 28px;
  bottom: 20px;
  width: 366px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, .12);
  background: #fff;
  z-index: 10
}

.priceChecker .head {
  background: #e20026
}

.priceChecker .head .h5 {
  margin-bottom: 11px
}

.priceChecker .head .result {
  display: block;
  margin-bottom: 0
}

.priceChecker .head .result li {
  max-width: 100%;
  flex: 0 0 100%
}

.priceChecker .head .result li:last-child {
  margin-bottom: 0
}

.priceChecker .head .result li span {
  color: #fff
}

.priceChecker .h5 {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase
}

.priceChecker .content .left,
.priceChecker .content .right {
  width: 60%;
  float: left;
  min-height: 123px
}

.priceChecker .content .left {
  border-right: 1px solid #f8f8f8;
  width: 40%
}

.priceChecker .content .left strong {
  font-size: 18px
}

.priceChecker .content .left img {
  display: block;
  margin-bottom: 10px
}

.priceChecker .content .right {
  padding-left: 18px;
  display: flex;
  align-content: center
}

.priceChecker .content .right .otherPrice {
  display: flex;
  align-content: center;
  align-items: center
}

.priceChecker .content .icon {
  color: #9da9af;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 19px
}

.priceChecker .content li {
  display: flex;
  margin-bottom: 0;
  min-height: 28px;
  align-items: center;
  justify-content: flex-start
}

.priceChecker .content li .icon {
  margin-right: 7px
}

.priceChecker .content li strong {
  font-size: 16px;
  font-weight: 700;
  position: relative;
  top: -1px
}

.priceChecker .content .contLogPrice {
  width: 87px;
  flex: 0 0 87px;
  max-width: 87px
}

.priceChecker .content .contLogPrice img {
  max-width: 80px
}

.priceChecker .foot {
  background: #f8f8f8;
  display: flex;
  padding: 6px 25px;
  align-items: center;
  justify-content: flex-end;
  color: #9b9b9b
}

.priceChecker .foot .contImg {
  background: #fff;
  padding: 3px;
  border-radius: 10px;
  width: 37px;
  height: 37px;
  margin-left: 10px
}

.priceChecker .foot .contImg img {
  max-width: 28px
}

.priceChecker .clip {
  display: none
}

.btncollaps {
  background: url(/static/media/collapse.e61cb2e6.svg);
  width: 33px;
  height: 33px;
  position: absolute;
  top: -46px;
  right: 19px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
  border-radius: 50%;
  background-size: 33px;
  cursor: pointer
}

.btncollaps:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1)
}

.btncollaps.open {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.basketMobile,
.hideBasketMobile {
  display: none
}







@media screen and (max-width:990px) {
  .calandar {
    left: 0;

  }
}

@media screen and (max-width:768px) {




  .timing {
    font-size: 18px;
    text-align: center;
    justify-content: center
  }

  .timing .h3 {
    border: 0;
    padding: 0;
    margin: 0 0 5px;
    font-size: 18px
  }

  .calandar .headItem {
    font-size: 16px;
    padding: 8px 0
  }

  .calandar .navCalandar .nextDate,
  .calandar .navCalandar .prevDate {
    width: 30px
  }

  .calandar .navCalandar .prevDate {
    left: 16px
  }

  .calandar .navCalandar .nextDate {
    right: 16px
  }

  .calandar .headMonth div {
    height: 25px
  }

  .calandar .wrappMonth {
    padding: 5px
  }

  .calandar .contentCalandar {
    flex-wrap: wrap
  }

  .calandar .itemMonth {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 5px
  }

  .calandar .itemMonth:last-child {
    display: none
  }

  .calandar .bodyMonth .itemNumb {
    padding: 4px 2px 0
  }

  .result li {
    max-width: 100%;
    flex: 0 0 100%
  }

  .infoTips {
    width: 280px
  }

  .infoTips .content {
    padding: 15px
  }


  .priceChecker {
    display: none
  }
}





@media screen and (max-width:960px) {


  footer .linkFooter,
  footer li,
  footer nav,
  footer ul {
    display: block
  }

  footer .copyright,
  footer .follow,
  footer .linkFooter,
  footer .partner {
    margin-bottom: 20px
  }

  footer .partner>div {
    text-align: center;
    display: block
  }

  footer .partner .item {
    display: inline-block
  }

  footer nav li {
    margin-bottom: 10px
  }

  footer nav a {
    margin: 0;
    display: inline-block
  }

  footer .follow a {
    vertical-align: middle;
    position: relative;
    top: -3px
  }

  footer .powered a {
    display: inline-block
  }
}



.modal.open {
  opacity: 1;
  visibility: visible
}

.modal-state:checked+.modal .modal_inner {
  top: 0
}

.modal_inner {
  transition: top .25s ease;
  top: 45px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 660px;
  margin: auto;
  position: relative
}

.popupRoomDetail {
  max-width: 90%
}

.modal_content {
  width: 100%;
  background: #fff;
  overflow: auto;
  position: relative;
  border-bottom: 6px solid red;
  border-bottom: 6px solid var(--btn-background)
}

.modal_close:after,
.modal_close:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 11px;
  background: red;
  background: var(--btn-background);
  display: block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  margin: -3px 0 0 -1px;
  top: 6px
}

.modal_close {
  position: absolute;
  right: -18px;
  top: -21px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 2px solid red;
  border: 2px solid var(--btn-background);
  border-radius: 50%
}

.modal_close:hover {
  border-color: #fff
}

.modal_close:hover:after,
.modal_close:hover:before {
  background: #fff !important
}

.modal_close:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.roomSeparator {
  background-color: hsla(0, 0%, 50.2%, .5098039215686274);
  width: calc(100% - 65px);
  height: 2px;
  margin: 40px auto
}

.bigModal .modal_content {
  overflow: scroll
}

.bigModal .modal_inner {
  max-width: 1030px
}

.bigModal .head_modal {
  padding: 40px 0 30px 47px
}

.bigModal .h5 {
  margin-bottom: 15px
}

.bigModal p {
  color: #131313;
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px
}

.bigModal .cont_modal {
  padding: 30px 0 20px 47px;
  max-height: 400px;
  overflow-y: auto
}

@media screen and (max-width:1030px) {
  .modal_inner {
    width: 90%;
    box-sizing: border-box
  }

  .bigModal .cont_modal {
    max-height: inherit;
    overflow: visible
  }

  .bigModal .modal_content {
    max-height: 520px;
    overflow-y: auto;
    height: 100%
  }
}

@media screen and (max-width:767px) {
  .modal_close {
    right: -2px;
    top: -25px
  }

  .roomSeparator {
    width: calc(100% - 35px)
  }

  .bigModal .cont_modal,
  .bigModal .head_modal {
    padding: 30px 20px
  }
}

@media screen and (min-width:767px) {
  .modal_inner {
    position: relative;
    top: 120px !important
  }
}

.pure-checkbox *,
.pure-checkbox :after,
.pure-checkbox :before,
.pure-radiobutton *,
.pure-radiobutton :after,
.pure-radiobutton :before {
  box-sizing: border-box
}

.pure-checkbox input[type=checkbox],
.pure-checkbox input[type=radio],
.pure-radiobutton input[type=checkbox],
.pure-radiobutton input[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.pure-checkbox input[type=checkbox]:focus+label:before,
.pure-checkbox input[type=checkbox]:hover+label:before,
.pure-checkbox input[type=radio]:focus+label:before,
.pure-checkbox input[type=radio]:hover+label:before,
.pure-radiobutton input[type=checkbox]:focus+label:before,
.pure-radiobutton input[type=checkbox]:hover+label:before,
.pure-radiobutton input[type=radio]:focus+label:before,
.pure-radiobutton input[type=radio]:hover+label:before {
  border-color: #e20026;
  background-color: #f2f2f2
}

.pure-checkbox input[type=checkbox]:active+label:before,
.pure-checkbox input[type=radio]:active+label:before,
.pure-radiobutton input[type=checkbox]:active+label:before,
.pure-radiobutton input[type=radio]:active+label:before {
  transition-duration: 0s
}

.pure-checkbox input[type=checkbox]+label,
.pure-checkbox input[type=radio]+label,
.pure-radiobutton input[type=checkbox]+label,
.pure-radiobutton input[type=radio]+label {
  position: relative;
  padding: 5px 5px 5px 2em;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer
}

.pure-checkbox input[type=checkbox]+label:before,
.pure-checkbox input[type=radio]+label:before,
.pure-radiobutton input[type=checkbox]+label:before,
.pure-radiobutton input[type=radio]+label:before {
  box-sizing: initial;
  content: "";
  color: #000;
  position: absolute;
  top: 50%;
  left: 0;
  width: 12px;
  height: 12px;
  margin-top: -9px;
  border: 2px solid #e20026;
  text-align: center;
  transition: all .4s ease
}

.pure-checkbox input[type=checkbox]+label:after,
.pure-checkbox input[type=radio]+label:after,
.pure-radiobutton input[type=checkbox]+label:after,
.pure-radiobutton input[type=radio]+label:after {
  box-sizing: initial;
  content: "";
  background-color: #112f41;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 8px;
  margin-top: -5px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out
}

.pure-checkbox input[type=checkbox]:disabled+label:before,
.pure-checkbox input[type=radio]:disabled+label:before,
.pure-radiobutton input[type=checkbox]:disabled+label:before,
.pure-radiobutton input[type=radio]:disabled+label:before {
  border-color: #ccc
}

.pure-checkbox input[type=checkbox]:disabled:focus+label:before,
.pure-checkbox input[type=checkbox]:disabled:hover+label:before,
.pure-checkbox input[type=radio]:disabled:focus+label:before,
.pure-checkbox input[type=radio]:disabled:hover+label:before,
.pure-radiobutton input[type=checkbox]:disabled:focus+label:before,
.pure-radiobutton input[type=checkbox]:disabled:hover+label:before,
.pure-radiobutton input[type=radio]:disabled:focus+label:before,
.pure-radiobutton input[type=radio]:disabled:hover+label:before {
  background-color: inherit
}

.pure-checkbox input[type=checkbox]:disabled:checked+label:before,
.pure-checkbox input[type=radio]:disabled:checked+label:before,
.pure-radiobutton input[type=checkbox]:disabled:checked+label:before,
.pure-radiobutton input[type=radio]:disabled:checked+label:before {
  background-color: #ccc
}

.pure-checkbox input[type=checkbox]+label:after,
.pure-radiobutton input[type=checkbox]+label:after {
  background-color: initial;
  top: 50%;
  left: 4px;
  width: 6px;
  height: 3px;
  margin-top: -5px;
  border-color: #fff;
  border-style: solid;
  border-width: 0 0 3px 3px;
  border-image: none;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0)
}

.pure-checkbox input[type=checkbox]:checked+label:after,
.pure-radiobutton input[type=checkbox]:checked+label:after {
  content: "";
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out
}

.pure-checkbox input[type=radio]:checked+label:before,
.pure-radiobutton input[type=radio]:checked+label:before {
  -webkit-animation: borderscale .3s ease-in;
  animation: borderscale .3s ease-in;
  background-color: #fff
}

.pure-checkbox input[type=radio]:checked+label:after,
.pure-radiobutton input[type=radio]:checked+label:after {
  -webkit-transform: scale(1);
  transform: scale(1)
}

.pure-checkbox input[type=radio]+label:after,
.pure-checkbox input[type=radio]+label:before,
.pure-radiobutton input[type=radio]+label:after,
.pure-radiobutton input[type=radio]+label:before {
  border-radius: 50%
}

.pure-checkbox input[type=checkbox]:checked+label:before,
.pure-radiobutton input[type=checkbox]:checked+label:before {
  -webkit-animation: borderscale .2s ease-in;
  animation: borderscale .2s ease-in;
  background: #000
}

.pure-checkbox input[type=checkbox]:checked+label:after,
.pure-radiobutton input[type=checkbox]:checked+label:after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1)
}

.pure-checkbox.pure-checkbox-inline,
.pure-checkbox.pure-radiobutton-inline,
.pure-radiobutton.pure-checkbox-inline,
.pure-radiobutton.pure-radiobutton-inline {
  display: inline-block
}

.pure-checkbox-toggle input[type=checkbox] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.pure-checkbox-toggle input[type=checkbox]+label {
  position: relative;
  padding: 5px 5px 5px 40px;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block
}

.pure-checkbox-toggle input[type=checkbox]+label:before {
  color: #e20026;
  width: 35px;
  border-radius: 50px;
  text-align: center;
  transition: all .4s;
  background-color: #d6d6d6
}

.pure-checkbox-toggle input[type=checkbox]+label:after,
.pure-checkbox-toggle input[type=checkbox]+label:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 0;
  content: "";
  height: 20px
}

.pure-checkbox-toggle input[type=checkbox]+label:after {
  background-color: #fff;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #d6d6d6;
  transition: all .2s;
  box-sizing: border-box
}

.pure-checkbox-toggle input[type=checkbox]:checked+label:before {
  box-shadow: inset 0 0 0 10px #e20026
}

.pure-checkbox-toggle input[type=checkbox]:checked+label:after {
  left: 15px;
  border-color: #e20026;
  background-color: #fff
}

.pure-checkbox-toggle input[type=checkbox]:disabled+label {
  cursor: default
}

.pure-checkbox-toggle input[type=checkbox]:disabled+label:before {
  box-shadow: inset 0 0 0 10px #e3e3e3
}

.pure-checkbox-toggle input[type=checkbox]:disabled+label:after {
  border-color: #e3e3e3
}

.pure-checkbox-toggle input[type=checkbox]:disabled:checked+label:before {
  box-shadow: inset 0 0 0 10px #999
}

.pure-checkbox-toggle input[type=checkbox]:disabled:checked+label:after {
  border-color: #999
}

.pure-checkbox-toggle--right input[type=checkbox]+label {
  padding: 5px 40px 5px 5px
}

.pure-checkbox-toggle--right input[type=checkbox]+label:before {
  left: auto;
  right: 0
}

.pure-checkbox-toggle--right input[type=checkbox]+label:after {
  left: auto;
  right: 15px
}

.pure-checkbox-toggle--right input[type=checkbox]:checked+label:after {
  left: auto;
  right: 0
}

.customCheckSite ul {
  display: flex;
  grid-gap: 20px 23px;
  gap: 20px 23px;
  flex-wrap: wrap
}

.customCheckSite ul li {
  width: 100%;
  max-width: 168px;
  margin-bottom: 0
}

@media screen and (max-width:767px) {
  .customCheckSite ul li {
    max-width: inherit
  }
}

.customCheckSite label {
  position: relative;
  padding: 0 14px !important;
  text-align: left;
  min-height: 57px;
  display: flex !important;
  align-content: center;
  align-items: center;
  flex-wrap: wrap
}

.customCheckSite .pure-checkbox,
.customCheckSite .pure-radiobutton {
  margin-bottom: 0
}

.customCheckSite .pure-checkbox input[type=radio]+label,
.customCheckSite .pure-radiobutton input[type=radio]+label {
  top: 0
}

.customCheckSite .pure-checkbox input[type=radio]+label:after,
.customCheckSite .pure-checkbox input[type=radio]+label:before,
.customCheckSite .pure-radiobutton input[type=radio]+label:after,
.customCheckSite .pure-radiobutton input[type=radio]+label:before {
  width: 100%;
  height: 100%;
  border-radius: 0;
  top: 0;
  margin: 0;
  border: 1px solid #d1d1d1 !important
}

.customCheckSite .pure-checkbox input[type=radio]+label:after,
.customCheckSite .pure-radiobutton input[type=radio]+label:after {
  z-index: 0;
  left: 0;
  top: 0;
  background: #e20026 !important;
  border-color: #e20026 !important
}

.customCheckSite .pure-checkbox span,
.customCheckSite .pure-checkbox strong,
.customCheckSite .pure-radiobutton span,
.customCheckSite .pure-radiobutton strong {
  z-index: 1;
  position: relative
}

.customCheckSite .pure-checkbox strong,
.customCheckSite .pure-radiobutton strong {
  font-size: 14px;
  display: block;
  width: 100%;
  text-transform: uppercase
}

.customCheckSite .pure-checkbox span,
.customCheckSite .pure-radiobutton span {
  font-weight: 400;
  font-size: 14px;
  color: #9b9b9b;
  display: block;
  width: 100%
}

.customCheckSite .pure-checkbox input[type=radio]:checked+label span,
.customCheckSite .pure-radiobutton input[type=radio]:checked+label span {
  color: #fff !important
}

.customLangue .pure-radiobutton {
  position: relative
}

.customLangue .pure-radiobutton img {
  position: absolute;
  left: 11px;
  top: 50%;
  margin-top: -8px;
  z-index: 2
}

.customLangue .pure-radiobutton label {
  padding-left: 35px !important
}

@media screen and (max-width:767px) {
  .bigModal .cont_modal ul {
    padding-bottom: 40px
  }
}

#block-stoll-views-block-actualites-home-home-block img,
.advanceTools span,
.boxLogin,
.boxOcc,
.boxOcc .nb span,
.btn,
.btncollaps,
.form-submit,
.infoTips,
.lstNews img,
.menuMobile div,
.menuMobile div:after,
.menuMobile div:before,
.modal_close,
.modal_close:after,
.modal_close:before,
.navTop ul,
.rs li:after,
.rs li:before,
.tipContact,
.topLogo,
a,
header,
select {
  transition: all .3s
}

.doNotHide {
  display: block !important
}

.modificationTimeline {
  padding-bottom: 30px !important
}

.itinerarySeparator {
  margin-top: 40px
}

.sidebar {
  position: absolute;
  float: left
}

.boxNotification {
  padding: 10px;
  width: 230px;
  z-index: 10;
  background: hsla(0, 0%, 100%, .9)
}

.boxNotification .modal_close {
  right: -10px;
  top: -10px;
  color: red !important;
  color: var(--btn-background) !important
}

.notificationMsg li {
  padding: 10px;
  background: #efefef;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, .1)
}

.notificationMsg li:last-child {
  margin-bottom: 0
}

.notificationMsg li p {
  margin-bottom: 15px
}

.notificationMsg li p:last-child {
  margin-bottom: 0
}

.notificationMsg li.textRed {
  background: #ffc1c1;
  border-color: rgba(157, 37, 37, .5);
  color: #a54545
}

.notificationMsg li.textRed p {
  color: #a54545
}

.notificationMsg li.textYellow {
  background: #ffeca5;
  border-color: #edb055;
  color: #8b5f1e
}

.notificationMsg li.textYellow p {
  color: #8b5f1e
}

.notificationMsg li.textGreen {
  background: #b7efb3;
  border-color: #5cb356;
  color: #206c1a
}

.notificationMsg li.textGreen p {
  color: #206c1a
}

.adultIcon {
  height: 33px;
  position: relative;
  top: -4px
}

.childrenIcon {
  height: 24px;
  position: relative;
  top: -2px
}

@media screen and (min-width:768px) {
  .nightsNumInSearchResult {
    float: right;
    position: absolute;
    right: 244px;
    top: 15px
  }
}

@media screen and (max-width:767px) {
  .nightsNumInSearchResult {
    margin-left: 6px
  }
}

.infoGeneralConditionOfSale {
  max-width: 1200px;
  margin: 15px auto;
  text-align: justify
}

@media screen and (max-width:950px) {
  .infoGeneralConditionOfSale {
    margin: 15px
  }
}

.infoGeneralConditionOfSale>div {
  margin: 15px 0
}

.infoGeneralConditionOfSale>div>div:first-child {
  font-weight: 700
}

.hyperlinkAIOLIA {
  text-decoration: none !important
}

.yellowAIOLIA {
  color: #daae10
}

.boldAIOLIA {
  font-weight: 900
}

.boldSocieteGenerale {
  font-size: 18px;
  font-weight: 900
}

.h2LegalNotice {
  color: #10306e;
  font-size: 22px
}

.footerLink {
  cursor: pointer
}

.headerLegalNotice {
  color: #10306e
}

.legalNotice h2 {
  font-family: sans-serif;
  font-size: 22px
}

.listLegalNotice>li {
  margin-left: 18px;
  list-style-type: circle
}

.titleLegalNotice>h1,
.titleLegalNotice>p {
  text-align: center
}

.titleLegalNotice>p {
  position: relative;
  top: -17px
}

.ui .negative .message {
  display: none !important;
  height: 100px;
  margin-top: 100px
}

.greyedOut {
  color: #9d9d9d !important
}

.PhoneInput {
  border: 2px solid #d3d3d3;
  padding-left: 28px
}

.paymentForm {
  margin-top: 50px;
  margin-bottom: 50px
}

.cardNumber {
  border-radius: 0;
  border: 0;
  padding: 15px 26px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: "Open Sans";
  color: #131313
}

#number-container,
#securityCode-container {
  height: 50px;
  padding-left: 20px;
  padding-right: 20px
}

#expiryDate,
#securityCode-container {
  margin-top: 20px
}

#expiryDate {
  display: flex;
  justify-content: space-between
}

#expiryDate>div>select {
  width: 60px;
  margin-left: 20px
}

.leftCol.col {
  z-index: 20;
  overflow: visible;
}

.calandar::before {
  content: "";
  width: calc(100% + 60px);
  /* height: 90%; */
  position: absolute;
  top: 60px;
  bottom: 0;
  background: #fff;
  border-radius: 25px;
  left: -28px;
  overflow: hidden;
  bottom: 60px;
  z-index: -2;
  box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  -webkit-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
  -moz-box-shadow: 0px 1px 29px -3px rgba(221, 240, 251, 0.8);
}

.calandar .bodyMonth .itemNumb .content .day:hover {
  color: #65baaf;
}

/*# sourceMappingURL=main.32177fdb.chunk.css.map */